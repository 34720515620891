import React, {useEffect, useState} from 'react';
import {executeQueryUtil} from "../../utils/gqlUtil";
import {getAllScheduleItemsByBrandIdQuery} from "../../gql/scheduleItemGql";
import moment from "moment";
import {repeatMonthly, repeatOnce, repeatWeekly} from "../Schedules/ScheduleDetailsView/calendar/calendar-event-dialog";
import {useWindowHeight} from "@react-hook/window-size";
import {getMediaSequenceQuery} from "../../gql/mediaSequenceGql";
import MediaSequenceDisplay from "./mediaSequenceDisplay";
import {getAllScheduleItemsByBrandIdLocalStorage, getMediaSequenceWithLocalStorage} from "./loadDataLocalStorage";
import ClipLoaderComponent from "../../components/ClipLoaderComponent";

function ScheduleDisplay({schedule, brandId}) {

  const [scheduleItems, setScheduleItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [currentMediaSequence, setCurrentMediaSequence] = useState(null);
  const screenHeight = useWindowHeight()
  function isWeeklyEventToday(wSchedule, day) {
    if (day == 0) {
      return wSchedule.sunday;
    }
    if (day == 1) {
      return wSchedule.monday;
    }
    if (day == 2) {
      return wSchedule.tuesday;
    }
    if (day == 3) {
      return wSchedule.wenesday;
    }
    if (day == 4) {
      return wSchedule.thursday;
    }
    if (day == 5) {
      return wSchedule.friday;
    }
    if (day == 6) {
      return wSchedule.saturday;
    }
    return false;
  }

  async function getAndSetCurrentMediaSequenceId(itemId) {
    if (!itemId) {
      setCurrentMediaSequence(null);
      setLoading(false);
      return;
    }

    const mediaSequence = await getMediaSequenceWithLocalStorage(brandId, itemId)
    setCurrentMediaSequence(mediaSequence)
    setLoading(false);
  }

  useEffect(() => {
    const interval = setInterval(
      () => {
        console.log("CHECK time")
        const now = moment();
        const nowStartOfDay = moment().startOf('day');
        const day = moment(now).day();
        const reapeatWeeklySchedules = scheduleItems.filter(s=> s.repeat === repeatWeekly);
        for (let i = 0; i < reapeatWeeklySchedules.length; i++) {
          const wSchedule = reapeatWeeklySchedules[i];
          if (isWeeklyEventToday(wSchedule, day)) {
            const start = moment(parseFloat(wSchedule.start))
            const end = moment(parseFloat(wSchedule.end))
            let diffDayStart = start.diff(moment(start).startOf('day'), 'milliseconds');
            let diffDayEnd = end.diff(moment(end).startOf('day'), 'milliseconds');
            if (!wSchedule.noEndRepeat && parseFloat(wSchedule.endRepeatDate) > now.valueOf()) {
              continue;
            }

            if (now.valueOf() >= nowStartOfDay.valueOf() + diffDayStart && now.valueOf() <= nowStartOfDay.valueOf() + diffDayEnd) {
              getAndSetCurrentMediaSequenceId(wSchedule.itemId);
              return;
            }
          }
        }
        const repeatMonthlySchedules = scheduleItems.filter(s=> s.repeat === repeatMonthly);
        for (let i = 0; i < repeatMonthlySchedules.length; i++) {
          const mSchedule = repeatMonthlySchedules[i];
          const dayOfMonth = moment(now).date();
          if (!mSchedule.noEndRepeat && parseFloat(mSchedule.endRepeatDate) > now.valueOf()) {
            continue;
          }
          const start = moment(parseFloat(mSchedule.start))
          const end = moment(parseFloat(mSchedule.end))
          if (dayOfMonth == start.date() || moment(now).date() == now.daysInMonth() && now.daysInMonth() < start.date()) {
            let diffDayStart = start.diff(moment(start).startOf('day'), 'milliseconds');
            let diffDayEnd = end.diff(moment(end).startOf('day'), 'milliseconds');
            if (now.valueOf() >= nowStartOfDay.valueOf() + diffDayStart && now.valueOf() <= nowStartOfDay.valueOf() + diffDayEnd) {
              //
              // if (now.valueOf() >= nowStartOfDay.add(diffDayStart, 'milliseconds') && now.valueOf() <= nowStartOfDay.add(diffDayEnd, 'milliseconds')) {
              getAndSetCurrentMediaSequenceId(mSchedule.itemId);
              return;
            }
          }
        }

        const singleSchedules = scheduleItems.filter(s=> s.repeat === repeatOnce);
        for (let j = 0; j < singleSchedules.length; j++) {
          const singleSchedule = singleSchedules[j];
          const start = moment(parseFloat(singleSchedule.start))
          const end = moment(parseFloat(singleSchedule.end))

          if (now.valueOf() >= start.valueOf() && now.valueOf() <= end.valueOf()) {
            getAndSetCurrentMediaSequenceId(singleSchedule.itemId);
            return;
          }
        }
        getAndSetCurrentMediaSequenceId(null);

      },
      1000,
    );

    return () => clearInterval(interval);
  }, [scheduleItems]);

  useEffect(() => {
    const loadData = async () => {
      const scheduleItemsRes = await
        getAllScheduleItemsByBrandIdLocalStorage(brandId, schedule.id);
      setScheduleItems(scheduleItemsRes)
    }
    loadData();

  }, [])
  return (
    <>
      {currentMediaSequence ?
        <MediaSequenceDisplay
          mediaSequence={currentMediaSequence}
          setMediaSequence={setCurrentMediaSequence}
          brandId={brandId}
        />
      :
        <>
        {loading ?
          <ClipLoaderComponent/>
          :
          <div  style={{backgroundColor: 'black', height: screenHeight}}>
          </div>
        }

        </>
      }
    </>
  );
}

export default ScheduleDisplay;
