import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {SvgIcon} from '@mui/material';
import {paths} from 'src/paths';
import {
  boardsRoute,
  dashboardBaseRoute, getCanvasRoute,
  getStartedRoute,
  layoutsRoute,
  medialListRoute,
  mediaSequencesRoute,
  schedulesRoute,
  screensRoute,
  widgetsRoute
} from '../../routes/dashboard';
import localStrings from "../../localStrings";
import MediaSequence from "../../icons/untitled-ui/duocolor/mediaSequence";
import Screen from "../../icons/untitled-ui/duocolor/screen";
import Board from "../../icons/untitled-ui/duocolor/board";
import Schedule from "../../icons/untitled-ui/duocolor/schedule";
import Layout from "../../icons/untitled-ui/duocolor/layout";
import useAuth from "../../hooks/useAuth";
import MediaList from "../../icons/untitled-ui/duocolor/mediaList";
import Widget from "../../icons/untitled-ui/duocolor/widget";
import Account from "../../icons/untitled-ui/duocolor/account";
import GetStarted from "../../icons/untitled-ui/duocolor/getStarted";
import HomeSmile from '../../icons/untitled-ui/duocolor/home-smile';

export const useSections = () => {
  const { t } = useTranslation();
  const {currentBrand} = useAuth();
  const [appItems, setAppItems] = useState([]);

  useEffect(() => {
    setAppItems(getAppItems());
  }, [currentBrand])

  function getAppItems() {
    //const items = [];
    if (currentBrand()) {
      return ([
        {
          title: localStrings.mediaSequences,
          path: "/" + dashboardBaseRoute + "/" + mediaSequencesRoute,
          partialMatch: true,
          icon: (
            <SvgIcon fontSize="small">
              <MediaSequence/>
            </SvgIcon>
          )
        },
        {
          title: localStrings.devices,
          path: "/" + dashboardBaseRoute + "/" + screensRoute,
          partialMatch: true,
          icon: (
            <SvgIcon fontSize="small">
              <Screen/>
            </SvgIcon>
          )
        },
        {
          title: localStrings.schedules,
          path: "/" + dashboardBaseRoute + "/" + schedulesRoute,
          partialMatch: true,
          icon: (
            <SvgIcon fontSize="small">
              <Schedule/>
            </SvgIcon>
          )
        },
        {
          title: localStrings.boards,
          path: "/" + dashboardBaseRoute + "/" + boardsRoute,
          partialMatch: true,
          icon: (
            <SvgIcon fontSize="small">
              <Board/>
            </SvgIcon>
          )
        },
        {
          title: localStrings.layouts,
          path: "/" + dashboardBaseRoute + "/" + layoutsRoute,
          partialMatch: true,
          icon: (
            <SvgIcon fontSize="small">
              <Layout/>
            </SvgIcon>
          )
        },
        {
          title: localStrings.widgets,
          path: "/" + dashboardBaseRoute + "/" + widgetsRoute,
          partialMatch: true,
          icon: (
            <SvgIcon fontSize="small">
              <Widget/>
            </SvgIcon>
          )
        },
        {
          title: localStrings.mediasList,
          path: "/" + dashboardBaseRoute + "/" + medialListRoute,
          partialMatch: true,
          icon: (
            <SvgIcon fontSize="small">
              <MediaList/>
            </SvgIcon>
          )
        },

        // {
        //   title: localStrings.canvas,
        //   path: "/" + dashboardBaseRoute + "/" + getCanvasRoute,
        //   partialMatch: true,
        //   icon: (
        //     <SvgIcon fontSize="small">
        //       <GetStarted/>
        //     </SvgIcon>
        //   )
        // },

      ]);
    }
    return [];
  }

  return useMemo(() => {
    return [
      {
        items: [
          {
            title: localStrings.dashboard,
            path: "/" + dashboardBaseRoute,
            partialMatch: false,
            icon: (
              <SvgIcon fontSize="small">
                <HomeSmile/>
              </SvgIcon>
            )
          },
          {
            title: localStrings.getStarded,
            path: "/" + dashboardBaseRoute + "/" + getStartedRoute,
            partialMatch: true,
            icon: (
              <SvgIcon fontSize="small">
                <GetStarted/>
              </SvgIcon>
            )
          },
          {
            title: localStrings.account,
            path: paths.dashboard.account,
            icon: (
              <SvgIcon fontSize="small">
                <Account />
              </SvgIcon>
            )
          },

        ]
      },
      {
        subheader: localStrings.managment,
        items: appItems
      },
      {
        items: [
          {
            title: localStrings.termsOfService,
            path: "https://www.castreactor.com/termsAndConditions.html",
            external: true,
          },
          {
            title: localStrings.privacyPolicy,
            path: "https://www.castreactor.com/privacyPolicy.html",
            external: true,
          },
        ]
      }
    ];
  }, [t, appItems]);
};
