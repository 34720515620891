import React, {useState} from 'react';
import {DragDropContext, Draggable, Droppable} from "react-beautiful-dnd";
import SequenceCard from "./SequenceCard";
import {Box, CircularProgress} from "@mui/material";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import cloneDeep from "clone-deep";

function DragNDropPresenter({mediaSequences, onDropUpdate,
                              addSequenceMain,
                              editMainSequence,
                              editSecondSequence,
                              addSequenceSecond,
                              setSplitPercent,
                              onView,
                              duplicate,
                              onClickMediaSequence, onDelete, onHide, undoSplit, splitVertically, splitHorizontally}) {

  const [updating, setUpdating] = useState(false)


  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = async (result) => {
    // dropped outside the list
    setUpdating(true);
    if (!mediaSequences) {
      return;
    }
    if (!result.destination) {
      return;
    }

    const mediaSequencesReordered = reorder(
      mediaSequences,
      result.source.index,
      result.destination.index
    );

    //const mediaSequencesReorderedClone = cloneDeep(mediaSequencesReordered)

    // for (let i = 0; i < mediaSequencesReorderedClone.length; i++) {
    //   const mediaSequencesReorderedElement = mediaSequencesReorderedClone[i];
    //   mediaSequencesReorderedElement.index = i;
    // }

    if (onDropUpdate) {
      await onDropUpdate(mediaSequencesReordered)
    }
    setUpdating(false);
    //setItems(mediaSequencesReordered)
  }

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {mediaSequences.map((mediaSequence, index) => (
                <Draggable key={mediaSequence.id} draggableId={mediaSequence.id} index={index}>
                  {(draggableProvided, snapshot) => (
                    <Box
                      ref={draggableProvided.innerRef}
                      style={{...draggableProvided.draggableProps.style}}
                      sx={{
                        outline: 'none',
                        py: 1.5
                      }}
                      {...draggableProvided.draggableProps}
                      {...draggableProvided.dragHandleProps}>
                      <SequenceCard
                        duplicate={duplicate}
                        addSequenceMain={addSequenceMain}
                        editMainSequence={editMainSequence}
                        editSecondSequence={editSecondSequence}
                        addSequenceSecond={addSequenceSecond}
                        splitHorizontally={splitHorizontally}
                        splitVertically={splitVertically}
                        setSplitPercent={setSplitPercent}
                        onView={onView}
                        onHide={onHide}
                        undoSplit={undoSplit}
                        mediaSequence={mediaSequence}
                        dragging={snapshot.isDragging}
                        onOpen={() => onClickMediaSequence(mediaSequence)}
                        onDelete={onDelete}
                      />
                    </Box>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

export default DragNDropPresenter;
