import React, {useEffect, useState} from 'react';
// import { createStore } from 'polotno/model/store';
// import Workspace from 'polotno/canvas/workspace';
// import '@blueprintjs/core/lib/css/blueprint.css';
function Canvas() {

  // const store = createStore("GKYixE9mII9yeYC8cCXr");
  // const page = store.addPage();
  //
  // page.addElement({
  //   x: 50,
  //   y: 50,
  //   type: 'text',
  //   fill: 'black',
  //   text: 'hello',
  // });


  return (
    <>
      <div style={{height: '100%'}}>
        {/*<Workspace store={store} />*/}
        {/*<h1>Canvas</h1>*/}
      </div>
      {/*<p>{JSON.stringify(store.pages)}</p>*/}
    </>


  )
}

export default Canvas
