import React from 'react';
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import localStrings from "../localStrings";
import {Box, Button, CardActions} from "@mui/material";

function CardItemSequence({image, title, callBackSelect, type, icon}) {
  return (
    <>
      {/*<FontAwesomeIcon icon={["fal", "coffee"]} />*/}
      {/*<FaBeer/>*/}
      <Card style={{
        borderRadius: 16,
        borderColor: 'black',
        border: 'solid',
        boxShadow: 'none',
        width: "200px",
        //minHeight: "300px",
      }}>

        <CardMedia
          image={image}
          component="img"
          width="160px"
          sx={{
            padding: '5px'
          }}
        >
        </CardMedia>
        {/*<CardMedia image={image} />*/}
        {/*<p>{image}</p>*/}
        <CardContent>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              borderRadius: 1,
              margin: 0,
            }}
          >
            <Typography variant={'h6'} style={{textAlign: "center"}}>
              {title}
            </Typography>
          </Box>
        </CardContent>

        <CardActions disableSpacing>
          <div style={{width: '100%'}}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'right',
              borderRadius: 1,
            }}
          >
            <Box>
              <Button
                onClick={() => callBackSelect(type)}
                color="primary"
                variant="contained"
                style={{marginLeft: "2px"}}
              >
                {localStrings.addItem}
              </Button>
            </Box>
          </Box>
          </div>
        </CardActions>
      </Card>
    </>


  );
}

export default CardItemSequence;
