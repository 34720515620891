const publishOnTheWeb = `
<h1>File is not shared</h1>

<p>To be able to display your file on the screen, we need the published url
</p>
<br/>
<p>Connect to you google drive and follow the instructions:</p>
<br/>

<strong>1. In Google Drive, open your file.</strong>
<br/>
<strong>2. From Docs, Sheets, Slides, select File. Publish to the web. For spreadsheets, select the entire spreadsheet or individual sheets. ...</strong>
<br/>
<strong>3. Click Publish.</strong>
<br/>
<strong>4. Copy the link and paste it below</strong>
<br/>
<br/>
<p>Then you should be able to import the file !!</p>
`
module.exports = {
  publishOnTheWeb: publishOnTheWeb,
}
