const getStartedAddSceduleContent = `

<p>Use schedule to plan what will be displayed on your screen</p>
<br/>

<strong>1. Create a new schedule</strong>
<br/>
<strong>2. define the media sequences to be displayed using a calendar</strong>
<br/>

`
module.exports = {
  getStartedAddSceduleContent: getStartedAddSceduleContent,
}
