import React from 'react';
import {Box, Card, CardContent, CardHeader, Typography} from "@mui/material";
import {animated, useSpring} from "react-spring";

export function addLeadingZeros(num, totalLength) {
  return String(num).padStart(totalLength, '0');
}

function EventForBoard({event, keyp}) {

  const springOpacityProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 1000 },
  })

  function getContent() {
    return (
      <Card variant="outlined"
            sx={{marginBottom: 0, marginTop: '10px', border: 'solid 3px'}} className={keyp + "-item"}>
        <CardContent sx={{marginTop: '0px', paddingBottom: '0 !important', paddingTop: '0 !important'}}>
          <Typography variant="h3" sx={{marginTop: '0px', paddingBottom: '0px', paddingTop: '0px'}}>
            {event.idDisplay}
          </Typography>
          {event.contentDisplay && event.contentDisplay != "" &&
            <Typography variant="h5" sx={{marginTop: '0px', paddingBottom: '0px', paddingTop: '0px'}}>
              {event.contentDisplay}
            </Typography>
          }
        </CardContent>
      </Card>
    );
  }

  return (
    <animated.div style={springOpacityProps}>
      {getContent()}
    </animated.div>
  );
}

export default EventForBoard;
