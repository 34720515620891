import React, {Component} from 'react';
import {loadScript} from './loadScript';
import pkg from '../../../../../package.json';

//This file is used to load Unlayer script so it could be edited

let lastEditorId = 0;

export default class extends Component {
    constructor(props) {
        super(props);

        this.editorId = `editor-${++lastEditorId}`;
    }

    componentDidMount() {
        loadScript(this.loadEditor);
    }

    render() {
        // let {
        //     props: {minHeight = 500, style = {}},
        // } = this;

        return (
          <div id={this.editorId} style={{height: '100%'}}/>
            // <div
            //     style={{
            //         flex: 1,
            //         display: 'flex',
            //         minHeight: minHeight,
            //     }}
            // >
            //
            // </div>
        );
    }

    loadEditor = () => {
        const options = this.props.options || {};
        const tools = this.props.tools || {};

        if (this.props.projectId) {
            options.projectId = this.props.projectId;
        }

        if (this.props.tools) {
            options.tools = this.props.tools;
        }

        if (this.props.appearance) {
            options.appearance = this.props.appearance;
        }

        if (this.props.locale) {
            options.locale = this.props.locale;
        }

        this.editor = window.unlayer.createEditor({
            id: this.editorId,
            displayMode: 'email',
            source: {
                name: pkg.name,
                version: pkg.version,
            },
            ...options,
            tools: {...tools},
            projectId: this.props.projectId,
            templateId: this.props.templateId
        });

        // All properties starting with on[Name] are registered as event listeners.
        for (const [key, value] of Object.entries(this.props)) {
            if (/^on/.test(key) && key !== 'onLoad') {
                this.addEventListener(key, value);
            }
        }

      const {onReady} = this.props;
      this.addEventListener("editor:ready", () => onReady && onReady());
      const {onLoad} = this.props;
      //const {onLoad} = this.props;
      onLoad && onLoad();

      // this.editor.addEventListener(type, callback);
    };

    registerCallback = (type, callback) => {
        this.editor.registerCallback(type, callback);
    };

    addEventListener = (type, callback) => {
        this.editor.addEventListener(type, callback);
    };

    loadDesign = (design) => {
        this.editor.loadDesign(design);
    };

    saveDesign = (callback) => {
        this.editor.saveDesign(callback);
    };

    exportHtml = (callback) => {
        this.editor.exportHtml(callback);
    };

    setMergeTags = (mergeTags) => {
        this.editor.setMergeTags(mergeTags);
    };

}
