import React, {useCallback, useEffect, useState} from 'react';
import useIsMountedRef from '../../hooks/useIsMountedRef';
import {useSnackbar} from 'notistack';
import useAppUtil from '../../hooks/useAppUtil';
import {css} from '@emotion/react';
import localStrings from '../../localStrings';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
//import CancelPresentationIcon from "@mui/icons-material/CancelPresentation";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import {
  Box,
  Button,
  Card,
  CardHeader,
  Checkbox,
  Collapse,
  colors,
  Divider,
  IconButton,
  InputAdornment, Paper,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  TextField
} from "@mui/material";
import clsx from 'clsx';
import {Edit as EditIcon, Search as SearchIcon, XCircle as XCircleIcon} from 'react-feather';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {Link as RouterLink} from 'react-router-dom';
import Tooltip from "@mui/material/Tooltip";
import getValueByPath from '../../utils/getValueByPath';
import useSound from 'use-sound';
import useSettings from '../../hooks/useSettings';
import {getUriSetting, NB_ITEMS, saveUriSetting, SEARCH_QUERY, SORT_FIELD} from '../../utils/optionsUriLocalStorage';
import Typography from "@mui/material/Typography";
import ClipLoaderComponent from '../ClipLoaderComponent';
import {DragDropContext, Draggable, Droppable} from 'react-beautiful-dnd';
import {cloneDeep} from '@apollo/client/utilities';
import {applyReorder, sortChainList} from '../../utils/chainUtil';
import {makeStyles} from "@mui/styles";
import { styled } from '@mui/material/styles';

const DISPLAY_ORDER = "displayOrder"
const config=require("../../conf/config.json");

const applyFilters = (items, query, filters, searchProperties, matchFunction, searchFunctions) => {
  if (!items) {
    return;
  }
  return items.filter((item) => {
    let matches = true;

    if (query) {
      const properties = searchProperties || [];
      const search = searchFunctions || [];
      let containsQuery = false;
      if (matchFunction) {
        return matchFunction(item, query.toLowerCase());
      }
      else {
        properties.forEach((propertyPath) => {
          if (getValueByPath(item, propertyPath).toLowerCase().includes(query.toLowerCase())) {
            containsQuery = true;
          }
        });
        search.forEach(searchFunction => {
          if (searchFunction(item).toLowerCase().includes(query.toLowerCase())) {
            containsQuery = true;
          }
        })
      }

      if (!containsQuery) {
        matches = false;
      }
    }

    if (filters.status && item.status !== filters.status) {
      matches = false;
    }

    return matches;
  });
};

const applyPagination = (items, page, limit) => {
  if (!items) {
    return null;
  }
  return items.slice(page * limit, page * limit + limit);
};

const descendingComparator = (a, b, orderBy) => {
  if (getValueByPath(b,orderBy) < getValueByPath(a,orderBy)) {
    return -1;
  }

  if (getValueByPath(b,orderBy) > getValueByPath(a,orderBy)) {
    return 1;
  }

  return 0;
};

const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

const applySort = (items, sort) => {
  if (!sort || sort === DISPLAY_ORDER) {
    return items;
  }
  if (sort.function) {
    const stabilizedThis = items.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      if (a.length === 0 || b.length === 0) {
        return 0;
      }

      return sort.function(a[0]) < sort.function(b[0]) ? 1 : -1;
    });
    return stabilizedThis.map((el) => el[0]);
  }

  const [orderBy, order] = sort.value.split('|');
  const comparator = getComparator(order, orderBy);
  const stabilizedThis = items.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);

    if (order !== 0) return order;

    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

const GeneralResultRoot = styled('div')(({ theme, ownerState }) => {
  return {
    marginBottom: 50,
    root: {
      marginBottom: 50,
    },
    queryField: {
      width: 500
    },
    statusField: {
      flexBasis: 200
    },
    bulkOperations: {
      //position: 'relative'
    },
    bulkActions: {
      paddingLeft: 4,
      paddingRight: 4,
      marginTop: 6,
      marginBottom: 4,
      //position: 'absolute',
      width: '100%',
      zIndex: 2,
      //backgroundColor: theme.palette.background.default
    },
    bulkAction: {
      marginLeft: theme.spacing(2)
    },
    avatar: {
      backgroundColor: colors.red[500],
      color: colors.common.white
    }
  }
});


const GeneralResult = ({
                         editable,
                         sortable,
                         applyReorderOnDelete,
                         setItemSource,
                         dragEndFunc,
                         dragGetNameFunc,
                         updateFunc,
                         triggerReorderFunc,
                         getItemsFunction,
                         deleteItemsFunction,
                         hideBulkDelete,
                         getPathToData,
                         className,
                         getTableCell,
                         getTableCellColumn,
                         getEditLink,
                         editFunc,
                         deleteDialogText,
                         deleteItemNotifMessage,
                         sortOptions,
                         searchProperties,
                         searchFunctions,
                         matchFunction,
                         filerData,
                         titleForm,
                         actionButtons,
                         externalLoading,
                         itemsSource,
                         searchModeButton,
                         bulkOperations,
                         bulkOperationsDirect,
                         displayNoItem,
                         disableBulkEdit,
                         disableSearch,
                         canSelectItemFunction,
                         canDeleteItemFunction,
                         allowSingleEdit,
                         nbColumnSingleEdit,
                         children
                       }) => {
  const isMountedRef = useIsMountedRef();

  const [openItemId, setOpenItemId] = useState(null);
  const [loadingItemsIds, setLoadingItemsIds] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [sortMode, setSortMode] = useState(false);
  const [deletedItem, setDeletedItem] = useState({});
  const [items, setItems] = useState(null);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(getUriSetting(NB_ITEMS) || 10);
  const [query, setQuery] = useState(getUriSetting(SEARCH_QUERY) || '');
  const [queryForSearch, setQueryQueryForSearch] = useState('');
  const [dialogOpen, setDialogOpen] = useState(false);
  const {enqueueSnackbar} = useSnackbar();
  const { playSoundIfActive } = useSettings();
  const [submittingForm, setSubmittingForm] = useState(false);
  const [bulkOperationEditorDisplayKey, setBulkOperationEditorDisplayKey] = useState(null);

  const [changingOrder, setChangingOrder] = useState(false);

  const [playDelete] = useSound(
    config.sound.delete,
    { volume: 0.5 }
  );

  const [sort, setSort] = useState(getUriSetting(SORT_FIELD) || (sortOptions && sortOptions[0]));
  const {addError} = useAppUtil();

  const [filters, setFilters] = useState({
    status: null
  });

  const deleteItem = async (item) => {
    setDialogOpen(true);
    setDeletedItem(item);
  }

  const deleteItemBulk = async () => {
    setDialogOpen(true);
    setDeletedItem(null);
  }

  const updateItems = async (getItemsFunction) => {
    try {
      const result = itemsSource ? itemsSource : await getItemsFunction();
      if (isMountedRef.current && result && Object.keys(result).length > 0 || Array.isArray(result)) {
        if (result) {
          let data = getPathToData(result);
          if (filerData) {
            data = data.filter(filerData)
          }
          setItems(data);
        }
        else {
          setItems([]);
        }
      }
    } catch (err) {
      console.log(err);
      addError(err.message)
      setItems([]);
    }
  }

  const getItems = useCallback(async () => {
    await updateItems(getItemsFunction);
  }, [isMountedRef, itemsSource]);

  useEffect(() => {
    getItems();
  }, [itemsSource]);

  const handleQueryChange = (event) => {
    event.persist();
    //saveUriSetting(SEARCH_QUERY, event.target.value)
    setQuery(event.target.value);
  };

  const handleQueryForSearchChange = (event) => {
    //event.persist();
    setQueryQueryForSearch(event.target.value);
    if (event.target.value === "") {
      //saveUriSetting(SEARCH_QUERY, "")
      setQuery("");
    }

  };

  const triggerSearch = (event) => {
    //event.persist();
    setQuery(queryForSearch);
  };

  const handleSortChange = (event) => {
    event.persist();
    let sortOption = sortOptions.find(sortOption => sortOption.value === event.target.value);

    //aler("location " + window.location)
    saveUriSetting(SORT_FIELD, sortOption)
    if (!sortOption) {
      setSort(DISPLAY_ORDER);
    }
    else {
      setSort(sortOption);
    }
  };

  const handleSelectAllItems = (event) => {
    setSelectedItems(event.target.checked
      ? getPaginatedItems().map((invoice) => invoice.id)
      : []);
  };

  const handleSelectOneItem = (event, itemId) => {
    if (!selectedItems.includes(itemId)) {
      setSelectedItems((prevSelected) => [...prevSelected, itemId]);
    } else {
      setSelectedItems((prevSelected) => prevSelected.filter((id) => id !== itemId));
    }
  };

  const handleSelectOneItemOnly = (itemId) => {
    setSelectedItems([itemId]);
    setTimeout(function () {
      window.scrollTo(0, 300);
    },2);
    //document.querySelector('body').scrollTo(0,0)
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (event) => {
    setLimit(parseInt(event.target.value));
    saveUriSetting(NB_ITEMS, event.target.value)
  };

  const filteredItems = applyFilters(items, query, filters, searchProperties, matchFunction, searchFunctions) || [];
  const sortedItems = applySort(filteredItems, sort);
  const paginatedItems = applyPagination(sortedItems, page, limit) || [];
  const enableBulkOperations = selectedItems.length > 0;
  const selectedSomeItems = selectedItems.length > 0 && selectedItems.length < items.length;

  const getPaginatedItems = () => {
    let itemsToFilter = itemsSource ? getPathToData(itemsSource) : items;
    let sortedItems;
    if (!sortMode || sortMode === DISPLAY_ORDER) {
      const filteredItems = applyFilters(itemsToFilter, query, filters, searchProperties, matchFunction, searchFunctions) || [];
      sortedItems = applySort(filteredItems, sort);

      const paginatedItems = applyPagination(sortedItems || itemsToFilter, page, limit) || [];
      return paginatedItems;
    }
    //else {
    return sortChainList(itemsToFilter) || [];
  }
  const selectedAllItems = items && selectedItems && selectedItems.length === getPaginatedItems().length;


  const override = css`
    display: block;
    margin: 0 auto;
  `;

  function supportBulk() {
    return deleteItemsFunction || bulkOperations || bulkOperationsDirect;
  }

  function supportDelete() {
    return deleteItemsFunction;
  }

  function handleClose() {
    setDialogOpen(false);
  }

  async function deleteItemsAndRefresh(listItem) {
    setSubmittingForm(true);
    playSoundIfActive(playDelete);
    var deleted = await deleteItemsFunction(listItem, items.length);
    if (deleted) {
      const filteredItems = items.filter(value =>
        !listItem.includes(value.id)
      );

      setItems(filteredItems);
      if (applyReorderOnDelete && updateFunc) {
        let filteredItemsClone = cloneDeep(filteredItems);
        let itemsClone = cloneDeep(items);
        await applyReorder(filteredItemsClone, itemsClone , updateFunc);

        if (setItemSource) {
          setItemSource(filteredItemsClone);
        }
      }
    }
    setSelectedItems([]);
    setSubmittingForm(false);
    return deleted;
  }

  async function handleConfirm() {
    try {
      var deleted = false;
      if (deletedItem) {
        deleted = await deleteItemsAndRefresh([deletedItem.id]);
      }
      else if (selectedItems){
        deleted = await deleteItemsAndRefresh(getSelectedInPagination());
      }
      if (deleted) {
        enqueueSnackbar(deleteItemNotifMessage, {
          variant: 'success'
        } );
      }
    }
    catch(err) {

      setSubmittingForm(false);
      if (err.message === "IntegrityError") {
        addError(localStrings.warningMessage.integrityError);
      }
      else {
        addError(err.message);
      }
      console.log(err);
    }

    setDialogOpen(false);
  }
  const getSelectedInPagination = () => {
    return selectedItems.filter(item => paginatedItems.map(item => item.id).includes(item));
  }


  function getBulkOperationComponents(ids, listMode) {
    return(
      <>
        {bulkOperationsDirect && ((!listMode && bulkOperationsDirect()) || [])
          .map((bulkOperationDirect, key) =>
            <Button
              variant="outlined"
              style={{marginLeft: 2}}
              disabled={bulkOperationDirect.disable || (bulkOperationDirect.disableFunc && bulkOperationDirect.disableFunc(getSelectedInPagination()))}
              onClick={() => {
                if (bulkOperationDirect.callBackClicked) {
                  bulkOperationDirect.callBackClicked(getSelectedInPagination())
                }
              }}
            >
              {bulkOperationDirect.buttonTitle}
            </Button>
          )}

        {bulkOperations && !listMode && bulkOperations()
          .filter(bulkOperation => !bulkOperation.disabled)
          .map((bulkOperation, key) => {
            return (
              <>
                <Button
                  variant="outlined"
                  onClick={() => setBulkOperationEditorDisplayKey(bulkOperation.key)}
                >
                  {bulkOperation.buttonTitle}
                </Button>

              </>
            )
          })}

        {bulkOperations && bulkOperations().map((bulkOperation, key) => {
          if (listMode || bulkOperationEditorDisplayKey === bulkOperation.key) {
            return (
              <div style={{margin:"16px"}}>
                {bulkOperation.buttonTitle && listMode &&
                  <>
                    <Typography variant="h3">
                      {bulkOperation.buttonTitle}
                    </Typography>
                    <Divider/>
                  </>
                }

                {bulkOperation.getActionComponent(ids || getSelectedInPagination())}


                <div style={{ width: '100%' }}>
                  <Box display="flex" flexDirection="row-reverse">
                    {!listMode &&
                      <Box p={1}>
                        <Button onClick={() =>setBulkOperationEditorDisplayKey(null)}
                        >
                          {localStrings.cancel}
                        </Button>
                      </Box>
                    }
                    <Box p={1}>
                      <Button
                        disabled={bulkOperation && bulkOperation.canApply && !bulkOperation.canApply()}
                        //disabled={true}
                        onClick={async () => {
                          if (bulkOperation.applyCallBack) {
                            ids && setLoadingItemsIds([...loadingItemsIds, ...ids]);
                            await bulkOperation.applyCallBack(ids || getSelectedInPagination());
                            ids && setLoadingItemsIds(loadingItemsIds.filter(id => ids.include(id)));

                            setBulkOperationEditorDisplayKey(null)
                          }
                          //setBulkOperationEditorDisplayKey(null)
                        }}
                      >
                        {localStrings.apply}


                      </Button>
                    </Box>
                    {/*<p>{JSON.stringify(bulkOperation.additionalButtons || {})}</p>*/}

                    {(bulkOperation.additionalActions || []).map((item, key) => {

                        let selectedIds = ids || getSelectedInPagination();
                        let shouldDisplay = item.shouldDisplay ? item.shouldDisplay(ids) : true;
                        if (shouldDisplay) {

                          return (
                            <Box p={1}>
                              <Button
                                onClick={async () => {
                                  if (item.action) {
                                    ids && setLoadingItemsIds([...loadingItemsIds, ...ids]);
                                    await item.action(selectedIds);
                                    ids && setLoadingItemsIds(loadingItemsIds.filter(id => ids.include(id)));

                                    setBulkOperationEditorDisplayKey(null)
                                  }
                                  //setBulkOperationEditorDisplayKey(null)
                                }}
                              >
                                {item.title}
                              </Button>
                            </Box>);
                        }
                      }
                    )}

                  </Box>

                </div>
              </div>
            );
          }
        })}
      </>
    )
  }

  function canDeleteAllPaginatedItems() {
    if (!canDeleteItemFunction) {
      return true;
    }
    return getPaginatedItems().every(item => canDeleteItemFunction(item))
  }

  const onDragEnd = async (result) => {
    if (dragEndFunc) {
      dragEndFunc(result);
      return
    }
    if (!updateFunc && !triggerReorderFunc || !result.destination) {
      return;
    }

    const sourceId = result.draggableId;
    const destinationIndex = result.destination.index;
    let itemsClone = cloneDeep(getPaginatedItems());

    const movedItem = cloneDeep(getPaginatedItems().find(item => item.id === sourceId));
    const oldIndex = getPaginatedItems().findIndex(item => item.id == movedItem.id);
    //const destinationItem = cloneDeep(getPaginatedItems()[destinationIndex]);
    //const movedItem = getPaginatedItems()
    if (destinationIndex === oldIndex) {
      return;
    }

    setChangingOrder(true)

    //const moveIndex = getPaginatedItems().findIndex(item => item.id == movedItem.id);
    itemsClone.splice(oldIndex, 1);
    itemsClone.splice(Math.max(destinationIndex, 0), 0, movedItem);

    const initialList = getPaginatedItems();
    if (updateFunc) {
      await applyReorder(itemsClone, initialList, updateFunc);
    }
    if (triggerReorderFunc) {
      await triggerReorderFunc(itemsClone)
    }
    setChangingOrder(false);
    if (setItemSource) {
      setItemSource(itemsClone);
    }
    //setSortedOrderItems(itemsClone);

  }

  const grid = 8;

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: grid * 2,
    margin: `0 0 ${grid}px 0`,
    border: 'solid 2px',
    borderRadius: '5px',
    // change background colour if dragging
    background: isDragging ? "lightBlue" : null,

    // styles we need to apply on draggables
    ...draggableStyle
  });

  return (
    <GeneralResultRoot>
      {
        sortMode ?
          <>
            {changingOrder ?
              <ClipLoaderComponent/>
              :
              <Card>

                {titleForm && <CardHeader title={titleForm}/>}

                <Box
                  p={2}
                  minHeight={56}
                  display="flex"
                  alignItems="center"
                >

                  <Button variant="contained" color="primary" onClick={() => setSortMode(!sortMode)}
                          disabled={getPaginatedItems().length < 2}>
                    {sortMode ? localStrings.backToNormalList : localStrings.reorderList}
                  </Button>
                </Box>
                <PerfectScrollbar>
                  <Box minWidth={1200}>

                      <DragDropContext onDragEnd={onDragEnd}>
                        <Droppable droppableId="sortist">
                          { provided => (
                            <div
                              {...provided.droppableProps}
                              ref={provided.innerRef}
                              style={{marginBottom: '100px'}}
                            >
                              <Box minWidth={1200}>
                                  {getPaginatedItems().map((item, key) => {
                                    return (
                                      <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={key}>
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            style={getItemStyle(
                                              snapshot.isDragging,
                                              provided.draggableProps.style
                                            )}
                                          >
                                            <Typography variant="h6">
                                              {dragGetNameFunc ? dragGetNameFunc(item) : item.id}
                                            </Typography>
                                          </div>

                                          // <TableRow
                                          //   sx={{border: '3px solid'}}
                                          //   ref={provided.innerRef}
                                          //   {...provided.draggableProps}
                                          //   {...provided.dragHandleProps}
                                          //   hover
                                          //   key={item.id}
                                          // >
                                          //   {getTableCell(item)}
                                          // </TableRow>
                                          // </div>

                                        )}
                                      </Draggable>

                                    )})}

                              </Box>
                            </div>
                          )}

                        </Droppable>
                      </DragDropContext>
                  </Box>
                </PerfectScrollbar>
                {children &&
                  <>
                    <Divider/>
                    <Box
                      p={2}
                      display="flex"
                      justifyContent="flex-end"
                      alignContent="space-around"
                    >
                      {children}
                    </Box>
                  </>
                }
              </Card>
            }
          </>

          :
          <div>
            <Dialog
              open={dialogOpen}
              onClose={handleClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{localStrings.confirmMessage.confirmDelete}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  {deleteDialogText}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose} color="primary">
                  {localStrings.cancel}
                </Button>
                <Button onClick={handleConfirm} color="primary">
                  {localStrings.confirm}
                </Button>
              </DialogActions>
            </Dialog>

            {
              (!displayNoItem && (!items || submittingForm || externalLoading)) ?
                <div style={{ width: "100%" }}>
                  <ClipLoaderComponent/>
                </div>
                :

                <Card>

                  {titleForm && <CardHeader title={titleForm}/>}

                  <Box
                    p={2}
                    minHeight={56}
                    display="flex"
                    alignItems="center"
                  >
                    {!disableSearch &&
                      <>
                        {searchModeButton && !disableSearch ?
                          <TextField
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton onClick={triggerSearch}>
                                    <SearchIcon />
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            value={queryForSearch}
                            onChange={handleQueryForSearchChange}
                            placeholder={localStrings.search}
                            value={queryForSearch}
                            //onEnter={triggerSearch}
                            onKeyPress={(ev) => {
                              //console.log(`Pressed keyCode ${ev.key}`);
                              if (ev.key === 'Enter') {
                                // Do code here
                                triggerSearch();
                              }
                            }}
                            variant="outlined"
                          />

                          :
                          <TextField
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="end" style={{marginRight: 10}}>
                                  <SvgIcon
                                    fontSize="small"
                                    color="action"
                                  >
                                    <SearchIcon />
                                  </SvgIcon>
                                </InputAdornment>
                              ),


                              endAdornment: (
                                <InputAdornment position="end" style={{marginLeft: 10}}>
                                  <IconButton onClick={() => setQuery("")}>
                                    <SvgIcon
                                      fontSize="small"
                                      color="action"

                                    >
                                      <CancelPresentationIcon />
                                    </SvgIcon>
                                  </IconButton>

                                </InputAdornment>
                              )
                            }}

                            onChange={handleQueryChange}
                            placeholder={localStrings.search}
                            value={query}
                            variant="outlined"
                          />
                        }
                      </>
                    }
                    <Box flexGrow={1} />
                    {actionButtons ? actionButtons() : null}

                    {sortable &&
                      // <Button variant="Contained" onClick={setSortMode(!sortMode)}>
                      <Button variant="contained" color="primary" onClick={() => setSortMode(!sortMode)}
                              disabled={getPaginatedItems().length < 2}>
                        {sortMode ? localStrings.backToNormalList : localStrings.reorderList}
                      </Button>
                    }
                    {sortOptions &&
                      <>
                        <Box flexGrow={1} />
                        <TextField
                          label={localStrings.sortBy}
                          name="sort"
                          onChange={handleSortChange}
                          select
                          SelectProps={{ native: true }}
                          value={sort.value}
                          variant="outlined"
                        >
                          {sortOptions.map((option) => (
                            <option
                              key={option.value}
                              value={option.value}
                            >
                              {option.label}
                            </option>
                          ))}
                          {sortOptions &&
                            <option
                              value={DISPLAY_ORDER}
                            >
                              {localStrings.displayOrder}
                            </option>
                          }
                          ))}
                        </TextField>
                      </>
                    }
                  </Box>

                  {enableBulkOperations && (
                    <div>
                      <div>
                        <Checkbox
                          checked={selectedAllItems}
                          indeterminate={selectedSomeItems}
                          onChange={handleSelectAllItems}
                        />
                        {deleteItemsFunction && !hideBulkDelete &&
                          <Button
                            disabled={!canDeleteAllPaginatedItems()}
                            variant="outlined"
                            asdasdas
                            onClick={deleteItemBulk}
                          >
                            {localStrings.delete}
                          </Button>
                        }

                        {getBulkOperationComponents()}



                        {/*}*/}
                      </div>

                    </div>
                  )}
                  <PerfectScrollbar>
                    <Box>
                      <Paper sx={{overflowX: "auto"}}>
                        <Table>
                        <TableHead>
                          <TableRow>
                            {
                              supportBulk() && !disableBulkEdit && (
                                <TableCell padding="checkbox">
                                  <Checkbox
                                    checked={selectedAllItems}
                                    indeterminate={selectedSomeItems}
                                    onChange={handleSelectAllItems}
                                  />
                                </TableCell>)
                            }


                            {allowSingleEdit &&
                              <TableCell>
                                {localStrings.detail}
                              </TableCell>
                            }
                            {getTableCellColumn()}


                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {getPaginatedItems().map((item) => {
                            const isEstablishmentSelected = selectedItems.includes(item.id);

                            return (
                              <>
                                <TableRow
                                  hover
                                  key={item.id}
                                  selected={isEstablishmentSelected}
                                >
                                  {supportBulk() && !disableBulkEdit && (
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        disabled={canSelectItemFunction && !canSelectItemFunction(item)}
                                        checked={isEstablishmentSelected}
                                        onChange={(event) => handleSelectOneItem(event, item.id)}
                                        value={isEstablishmentSelected}
                                      />
                                    </TableCell>
                                  )}


                                  {allowSingleEdit &&
                                    <TableCell >
                                      <IconButton
                                        style={{marginLeft:2}}
                                        aria-label="expand row"
                                        size="small"
                                        onClick={() => {
                                          if (openItemId === item.id) {
                                            setOpenItemId(null);
                                          }
                                          else {
                                            setOpenItemId(item.id);
                                          }

                                        }}
                                      >
                                        {openItemId === item.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                                      </IconButton>
                                    </TableCell>

                                  }


                                  {getTableCell(item)}

                                  <TableCell align="right">
                                    {
                                      ((getEditLink && getEditLink(item)) || editFunc) ?
                                        <Tooltip title={localStrings.edit} aria-label={localStrings.edit}>
                                          {editFunc ?

                                            <IconButton
                                              onClick={() => editFunc(item)}
                                            >
                                              <SvgIcon fontSize="small">
                                                <EditIcon/>
                                              </SvgIcon>
                                            </IconButton>
                                            :
                                            <IconButton
                                              //onClick={() => editFunc(item)}
                                              component={RouterLink}
                                              to={getEditLink(item)}
                                            >
                                              <SvgIcon fontSize="small">
                                                <EditIcon/>
                                              </SvgIcon>
                                            </IconButton>
                                          }
                                        </Tooltip>
                                        :
                                        null
                                    }
                                    {
                                      deleteItem && supportDelete() && (!canDeleteItemFunction || canDeleteItemFunction(item) ) && (
                                        <Tooltip title={localStrings.delete} aria-label={localStrings.delete}>
                                          <IconButton onClick={() => deleteItem(item)}>
                                            <SvgIcon fontSize="small">
                                              <XCircleIcon/>
                                            </SvgIcon>
                                          </IconButton>
                                        </Tooltip>
                                      )
                                    }
                                    {/*{allowSingleEdit &&*/}
                                    {/*<Tooltip title={localStrings.delete} aria-label={localStrings.delete}>*/}
                                    {/*  <IconButton onClick={() => {*/}
                                    {/*    handleSelectOneItemOnly(item.id)*/}
                                    {/*  }}>*/}
                                    {/*    <SvgIcon fontSize="small">*/}
                                    {/*      <EditIcon />*/}
                                    {/*    </SvgIcon>*/}
                                    {/*  </IconButton>*/}
                                    {/*</Tooltip>*/}
                                    {/*}*/}

                                  </TableCell>
                                </TableRow>


                                <TableRow>
                                  <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={nbColumnSingleEdit}>
                                    <Collapse in={openItemId === item.id} timeout="auto" unmountOnExit >
                                      <Box mt={2} mb={2}>
                                        {loadingItemsIds.includes(item.id) ?
                                          <ClipLoaderComponent/>
                                          :
                                          getBulkOperationComponents([item.id], true)
                                        }
                                      </Box>
                                    </Collapse>
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}
                        </TableBody>
                      </Table>
                      </Paper>
                    </Box>
                  </PerfectScrollbar>
                  <TablePagination
                    component="div"
                    count={filteredItems.length}
                    onPageChange={handlePageChange}
                    onRowsPerPageChange={handleLimitChange}
                    page={page}
                    rowsPerPage={limit}
                    rowsPerPageOptions={[5, 10, 25, 50, 100, 200]}
                  />
                  {children &&
                    <>
                      <Divider/>
                      <Box
                        p={2}
                        display="flex"
                        justifyContent="flex-end"
                        alignContent="space-around"
                      >
                        {children}
                      </Box>
                    </>
                  }
                </Card>

            }
          </div>
      }
    </GeneralResultRoot>
  );
}

export default GeneralResult;
