import Markdown from 'react-markdown';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import PropTypes from 'prop-types';
import { styled } from '@mui/material/styles';
import { codeStyle } from 'src/utils/code-style';
import {useState} from "react";
import {Box, Button} from "@mui/material";
import localStrings from "../../../localStrings";
import {EXPLAIN_SHOW, getUriSetting, saveUriSetting, SELECTED_TAB} from "../../../utils/optionsUriLocalStorage";

export const Code = (props) => {
  const { node, inline, className, children, ...other } = props;

  const language = /language-(\w+)/.exec(className || '');

  return !inline && language
    ? (
      <SyntaxHighlighter
        children={String(children).replace(/\n$/, '')}
        language={language[1]}
        PreTag="div"
        style={codeStyle}
        {...other} />
    )
    : (
      <code
        className={className}
        {...other}>
        {children}
      </code>
    );
};

const components = {
  code: Code
};

export const CourseLessonRoot = styled('div')(({ theme }) => ({
  color: theme.palette.text.primary,
  fontFamily: theme.typography.fontFamily,
  '& blockquote': {
    borderLeft: `4px solid ${theme.palette.text.secondary}`,
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    '& > p': {
      color: theme.palette.text.secondary,
      marginBottom: 0
    }
  },
  '& code': {
    color: theme.palette.primary.main,
    fontFamily: 'Inconsolata, Monaco, Consolas, \'Courier New\', Courier, monospace',
    fontSize: 14,
    paddingLeft: 2,
    paddingRight: 2
  },
  '& h1': {
    fontSize: 35,
    fontWeight: 500,
    letterSpacing: '-0.24px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6)
  },
  '& h2': {
    fontSize: 29,
    fontWeight: 500,
    letterSpacing: '-0.24px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6)
  },
  '& h3': {
    fontSize: 24,
    fontWeight: 500,
    letterSpacing: '-0.06px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(6)
  },
  '& h4': {
    fontSize: 20,
    fontWeight: 500,
    letterSpacing: '-0.06px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(4)
  },
  '& h5': {
    fontSize: 16,
    fontWeight: 500,
    letterSpacing: '-0.05px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  '& h6': {
    fontSize: 14,
    fontWeight: 500,
    letterSpacing: '-0.05px',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2)
  },
  '& li': {
    fontSize: 14,
    lineHeight: 1.5,
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4)
  },
  '& p': {
    fontSize: 16,
    lineHeight: 1.5,
    marginBottom: theme.spacing(2),
    '& > a': {
      color: theme.palette.primary.main
    }
  }
}));

const hide = "hide";
const view = "view";
// const fullDisplay = "fullDisplay";


export const CourseLesson = (props) => {
  const { content, name } = props;
  const [stateDisplay, setStateDisplay] = useState(name ? (getUriSetting(EXPLAIN_SHOW + name) || hide) : hide)

  function changeDisplayState() {
    let newState = stateDisplay;
    if (stateDisplay === view) {
      newState = hide;
    }
    else if (stateDisplay === hide) {
      newState = view;
    }
    setStateDisplay(newState)
    if (name) {
      saveUriSetting(EXPLAIN_SHOW + name, newState)
    }
  }

  return (
    <>
      {stateDisplay === view &&
        <CourseLessonRoot>
          <Markdown
            children={content}
            components={components}
          />
        </CourseLessonRoot>
      }
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row-reverse',
        }}
      >
       <Button onClick={changeDisplayState}>
          {stateDisplay === hide &&
            <>
              {localStrings.showExplanations}
            </>
          }
          {stateDisplay === view &&
            <>
              {localStrings.hideExplanations}
            </>
          }

        </Button>
      </Box>
    </>
  );
};

CourseLesson.propTypes = {
  content: PropTypes.string.isRequired
};
