import React, {useEffect, useState} from 'react';
import {useWindowHeight, useWindowWidth} from "@react-hook/window-size";
import {
  googlePresentationType,
  split_type_horizontal
} from "../MediaSequences/MediaSequenceAddView/MediaSequenceCreateUpdateForm";
import SequenceDiv from "./sequenceDiv";
import {split_type_vertical} from "../MediaSequences/MediaSequenceDetailsView/mediaList";
import ClipLoaderComponent from "../../components/ClipLoaderComponent";
import moment from "moment/moment";
import {default_sequence_duration} from "./screenDisplay";
import config from "../../conf/config.json";
import axios from "axios";
import cloneDeep from "clone-deep";

let startTime = moment();

function MediaSequenceDisplay({mediaSequence, setMediaSequence, brandId, mediaId}) {
  const screenWidth = useWindowWidth()
  const screenHeight = useWindowHeight()
  const [indexSequence, setIndexSequence] = useState(0);
  const [mediaSequenceInternal, setMediaSequenceInternal] = useState(null);

  useEffect(() => {
    const flagTimers = async () => {
      const mediaSequenceFlagged = await flagSequenceTimers(mediaSequence);
      setMediaSequenceInternal(mediaSequenceFlagged);
    }
    flagTimers();

  }, [mediaSequence])

  useEffect(() => {
    if (!mediaSequenceInternal) {
      return;
    }
    if (mediaId) {
      let sequence = mediaSequenceInternal.sequences.findIndex(s=>s.id===mediaId);
      setIndexSequence(sequence);
      return;
    }
    const interval = setInterval(
      () => {
        let ellapsedTimeValue = moment().diff(startTime, 'milliseconds');
        if (ellapsedTimeValue > mediaSequenceInternal.sequences[indexSequence].timerEnabler) {
          if (indexSequence >= mediaSequenceInternal.sequences.length - 1) {
            setIndexSequence(0);
            startTime = moment();
          }
          else {
            let newIndexSequence = Math.min(mediaSequenceInternal.sequences.length - 1, indexSequence+1);
            if (newIndexSequence != indexSequence) {
              setIndexSequence(newIndexSequence);
            }
          }
        }
      },
      100,
    );

    return () => clearInterval(interval);
  }, [mediaSequenceInternal, indexSequence]);

  useEffect(() => {
    document.getElementsByTagName("body")[0].style.overflow = 'hidden';
  }, [])

  async function flagSequenceTimers(mediaSequence) {

    if (!mediaSequence?.sequences) {
      return null;
    }

    const mediaSequenceClone = cloneDeep(mediaSequence)

    let timerEnabledMs = 0;
    for (let i = 0; i < mediaSequenceClone.sequences.length; i++) {
      const sequence = mediaSequenceClone.sequences[i];
        timerEnabledMs += (sequence.duration || default_sequence_duration) * 1000;
        sequence.timerEnabler = timerEnabledMs;
    }
    return mediaSequenceClone;
  }

  async function getSlideInfo(sequence) {
    const url = config.googleDocServiceUrl + "/slideIfo/" + sequence.googleDocId;
    try {
      const res = await axios.get(url,{
        headers: {
          'Content-Type': 'application/json',
        }
      })

      return res?.data;
    }
    catch (err) {
      console.log(err);
    }
    return null;
  }

  function getMediaSequenceItem() {
    if (mediaSequence.sequences && mediaSequence.sequences.length > 0) {
      const sequence = mediaSequence.sequences[indexSequence];
      if (sequence?.secondSequence) {
        sequence.secondSequence.mute = sequence.mute;
        sequence.secondSequence.duration = sequence.duration;
      }
      if (sequence.splitType == split_type_horizontal) {
        const splitPercent = sequence.splitPercent || 50;
        const restPercent = 100 - splitPercent;
        const upHeight = Math.floor(screenHeight * (splitPercent / 100));
        const heightRemains = screenHeight - upHeight;

        return (
          <div>
            <div height={upHeight}>
              <SequenceDiv
                splitType={sequence.splitType}
                sequence={sequence}
                brandId={brandId}
                screenHeight={upHeight}
                screenWidth={screenWidth}
                setMediaSequence={setMediaSequence}
                mediaSequence={mediaSequenceInternal}
              />
            </div>
            <div height={heightRemains}>
              <SequenceDiv
                splitType={sequence.splitType}
                sequence={sequence.secondSequence}
                brandId={brandId}
                screenHeight={heightRemains}
                screenWidth={screenWidth}
                setMediaSequence={setMediaSequence}
                mediaSequence={mediaSequenceInternal}
              />
            </div>
          </div>

        )
      }

      if (sequence.splitType == split_type_vertical) {
        const splitPercent = sequence.splitPercent || 50;
        //const restPercent = 100 - splitPercent;
        const leftWidth = Math.floor(screenWidth * (splitPercent / 100));
        const widthRemains = screenWidth - leftWidth;

        return (
          <div width={screenWidth}
               style={{
                 display: 'flex',
               }}
          >
            <div width={leftWidth}>
              <SequenceDiv
                splitType={sequence.splitType}
                sequence={sequence}
                brandId={brandId}
                screenHeight={screenHeight}
                screenWidth={leftWidth}
                setMediaSequence={setMediaSequence}

                mediaSequence={mediaSequenceInternal}
              />
            </div>
            <div height={widthRemains}>
              <SequenceDiv
                splitType={sequence.splitType}
                sequence={sequence.secondSequence}
                brandId={brandId}
                screenHeight={screenHeight}
                screenWidth={widthRemains}
                setMediaSequence={setMediaSequence}
                mediaSequence={mediaSequenceInternal}
              />
            </div>
          </div>

        )
      }

      return (
          <SequenceDiv
            sequence={sequence}
            splitType={sequence.splitType}
            brandId={brandId}
            screenHeight={screenHeight}
            screenWidth={screenWidth}
            setMediaSequence={setMediaSequence}
            mediaSequence={mediaSequenceInternal}
          />
      )
    }
  }

  return (
    <>
      {mediaSequenceInternal ?
        // <div id="mainScreen" style={{
        //   height: screenHeight,
        //   backgroundColor: mediaSequence.backgroundColor,
        //   display: 'flex',
        //   justifyContent: 'center',
        //   alignItems: 'center'
        //
        // }}>
        <>
          {getMediaSequenceItem()}
        </>
        // </div>
        :
        <ClipLoaderComponent/>
      }
    </>
  )
}

export default MediaSequenceDisplay;
