import React, {useEffect, useState} from 'react';
import {TableCell} from "@mui/material";
import localStrings from "../../../localStrings";
import useAuth from "../../../hooks/useAuth";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import GeneralResult from "../../../components/CrudFrom/GeneralResult";
import {BRAND_COLLECTION, DEVICE_HEALTH_COLLECTION} from "../../../utils/constants";
import {collection, getFirestore} from "firebase/firestore";
import {useCollectionData} from "react-firebase-hooks/firestore";
import moment from "moment";
import {
  bulkDeleteLayoutMutation,
  deleteLayoutByBrandIdCache,
  getAllLayoutsByBrandIdQuery
} from "../../../gql/layoutGql";
import {getAllMediaSequencesByBrandIdQuery} from "../../../gql/mediaSequenceGql";
import useAppUtil from "../../../hooks/useAppUtil";

export const getLayouts = async (currentBrand) => {
  return await executeQueryUtil(getAllLayoutsByBrandIdQuery(currentBrand().id));
}

const Results = ({className}) => {

  const {currentBrand} = useAuth();
  const db = getFirestore();
  const {addError} = useAppUtil();

  const refCollection =  collection(db, BRAND_COLLECTION
    + "/" + currentBrand().id + "/" + DEVICE_HEALTH_COLLECTION);

  const [devicesHealth, devicesHealthLoading, devicesHealthError] = useCollectionData(
    refCollection
    , {
      snapshotListenOptions: { includeMetadataChanges: true },
    });

  const [itemSource, setItemSource] = useState({});

  useEffect(() => {
    async function setItemSourceAsync() {
      let result = await getLayouts(currentBrand);
      setItemSource(result);
    }
    setItemSourceAsync();
  }, [currentBrand]);


  const getTableCellColumn = () => {
    return(
      <React.Fragment>
        <TableCell>
          {localStrings.Id}
        </TableCell>
        <TableCell>
          {localStrings.name}
        </TableCell>
        <TableCell>
          {localStrings.actions}
        </TableCell>
      </React.Fragment>
    )
  }

  function getStatusColor(item) {
    var alive = item.alive
    const nowMs = moment().valueOf()
    //let deviceHealthMatching = devicesHealth && devicesHealth.find(h => h.id === item.id && nowMs - h.updateDate > 2000);
    let deviceHealthMatching = devicesHealth && devicesHealth.find(h => h.id === item.id);
    if (deviceHealthMatching) {
      alive = deviceHealthMatching.alive;
    }
    if (alive) {
      return 'green';
    }
    return 'red';
  }

  const getTableCell = (item) => {

    return(
      <React.Fragment>
        <TableCell>
          {item.id}
        </TableCell>
        <TableCell>
          {item.name}
        </TableCell>
      </React.Fragment>
    )
  }

  const removeLayouts = async (ids, size) => {


    const resMediaSequences = await executeQueryUtil(getAllMediaSequencesByBrandIdQuery(currentBrand().id))
    const mediaSequences = resMediaSequences?.data?.getMediaSequencesByBrandId || [];
    let referencedLayoutIds = [];
    let mediaSequenceNames = [];
    mediaSequences.forEach(mediaSequence => {
      //console.log(JSON.stringify(mediaSequence))
      const sequences = mediaSequence.sequences || [];
      sequences.forEach(sequence => {
        if (ids.includes(sequence.layoutId)) {
          referencedLayoutIds.push(sequence.layoutId)
          mediaSequenceNames.push(mediaSequence.name)
        }
        if (sequence.secondSequence?.widgetId && ids.includes(sequence.secondSequence.layoutId)) {
          referencedLayoutIds.push(sequence.layoutId)
          mediaSequenceNames.push(mediaSequence.name)
        }
      });

    })

    if (referencedLayoutIds.length > 0) {
      addError(localStrings
        .formatString(localStrings.warningMessage.layoutReferencedInMediaSequence, [...new Set(mediaSequenceNames)].toString()));
      return;
    }

    await executeMutationUtil(bulkDeleteLayoutMutation(currentBrand().id, ids));
    await deleteLayoutByBrandIdCache(currentBrand().id, ids);
    let itemUpdate = await getLayouts(currentBrand);
    setItemSource(itemUpdate)
    return true;
  }


  const sortOptions = [
    {
      value: 'name|asc',
      label: localStrings.name
    },
  ];

  return (
    <>
      { Object.keys(itemSource).length > 0 ?
        <GeneralResult
          className={className}
          getPathToData={(result) => result.data.getLayoutsByBrandId}
          getTableCell={getTableCell}
          getTableCellColumn={getTableCellColumn}
          getEditLink={(item) => "/dashboard/layouts/detail/" + item.id}
          deleteDialogText={localStrings.confirmMessage.deleteLayout}
          deleteItemsFunction={removeLayouts}
          deleteItemNotifMessage={localStrings.notif.layoutDeleted}
          sortOptions={sortOptions}
          searchProperties={['name']}
          itemsSource={itemSource}
        >
        </GeneralResult>
        :
        <ClipLoaderComponent/>
      }
    </>
  )

};


export default Results;
