import React from 'react';
import AceCodeEditor from "../../../components/editor/AceCodeEditor";
import {Box, Button, SvgIcon} from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import localStrings from "../../../localStrings";
import useAuth from "../../../hooks/useAuth";

function JsonEditor({code, setCode, apply}) {
  const {superAdmin} = useAuth()
  return (
    <div>

      {superAdmin ?
        <AceCodeEditor
          code={code}
          setCode={setCode}
          mode="json"
          //readOnly
          style={{height: '400px', marginBottom: '35px'}}
        />
        :
        <AceCodeEditor
          code={code}
          setCode={setCode}
          mode="json"
          readOnly
          //style={{height: '400px', marginBottom: '35px'}}
        />
      }

      {superAdmin &&
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
            p: 1,
            m: 1,
            height: '25px',
            borderRadius: 1,
          }}
        >
          <Box>
            <Button
              variant="contained"
              color="primary"
              style={{marginLeft: "2px"}}
              onClick={() => apply && apply(code)}
              endIcon={<SvgIcon>
                <ArrowForwardIcon/>
              </SvgIcon>}
            >
              {localStrings.apply}
            </Button>
          </Box>
        </Box>
      }

    </div>
  );
}

export default JsonEditor;
