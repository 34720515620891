import React, {useEffect, useLayoutEffect, useRef, useState} from 'react';
import {collection, doc, getFirestore} from "../lib/firebase";
import {BRAND_COLLECTION, DISPLAY_EVENTS_COLLECTION, DISPLAY_MESSAGES_COLLECTION} from "../utils/constants";
import {useParams} from "react-router-dom";
import {Box, Card, CardContent, Dialog, DialogContent, Toolbar, Typography} from "@mui/material";
import EventForBoard from "../components/EventForBoard";
import {animated, useSpring} from 'react-spring'
import {executeQueryUtil} from "../utils/gqlUtil";
import {getBoardQuery} from "../gql/boardGql";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import {useWindowWidth} from '@react-hook/window-size'
import {isEnoughCredit, removeBackGround} from "./ScreenDisplay/screenDisplay";
import useAuth from "../hooks/useAuth";
import NoCreditCompo from "../components/NoCreditCompo";

let lastValueTranslateX=null

const heightMessage = 55;
const headerHeight = 40;
const headerLogoHeight = '40';

const DEFAULT_DURATION = 18000;
let currentPageVar = 1

function DisplayEvents({brandIdParam, boardIdParam}) {
  const refMessage = useRef(null);
  const [widthTypoMessage, setWidthTypoMessage] = useState(0);
  const screenWidth = useWindowWidth()

  const springOpacityProps = useSpring({
    to: { opacity: 1 },
    from: { opacity: 0 },
    config: { duration: 1000 },
  })

  let {brandId} = useParams();
  let {boardId} = useParams();

  let brandIdp = brandIdParam || brandId;
  let boardIdp = boardIdParam || boardId;

  const db = getFirestore();
  const [board, setBoard] = useState(null);
  const [currentDisplayMessageIndex, setCurrentDisplayMessageIndex]= useState(0);
  const [dialogEvent, setDialogEvent] = useState(null);
  const [nbPage, setNbPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const {setDisplaySettingButton} = useAuth();


  const refBrand = doc(db, BRAND_COLLECTION, brandId || "-1");
  const [brandDb, brandDbLoading, brandDbError] = useDocumentData(refBrand, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  function computeDuration(message) {
    if (!message || !board?.displayMessageDelay) {
      return DEFAULT_DURATION;
    }
    return board.displayMessageDelay;
  }

  function getDisplayMessagesFiltered() {
    if (!displayMessages || !board) {
      return [];
    }
    const displayMessagesFitered = (displayMessages || []).filter(m => !m.hide &&
      (!m.targetBoards || m.targetBoards.length == 0 || m.targetBoards.includes(board.id))
    );

    return displayMessagesFitered;
  }

  function increaseDisplayMessageIndex() {
    setCurrentDisplayMessageIndex((currentDisplayMessageIndex+1) % getDisplayMessagesFiltered().length )
  }

  function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  function getDisplayStatus(status) {
    if (!board) {
      return "";
    }
    let column = (board.boardColumns || []).find(c => c.key === status);
    return column?.display || "";
  }

  useEffect(() => {
    const loadBoard = async () => {
      const projectRes = await executeQueryUtil(getBoardQuery(brandIdp, boardIdp));
      const data = projectRes?.data?.getBoard;
      setBoard(data)
    }
    loadBoard();
  }, [])

  const refCollection =  collection(db, BRAND_COLLECTION + "/" + brandIdp + "/" + DISPLAY_MESSAGES_COLLECTION);

  const [displayMessages, displayMessagesLoading, displayMessagesError] = useCollectionData(
    refCollection
    , {
      snapshotListenOptions: { includeMetadataChanges: false },
    });

  const eventCollection =  collection(db, BRAND_COLLECTION
    + "/" + brandIdp + "/" + DISPLAY_EVENTS_COLLECTION);

  const [events, eventsLoading, eventsError] = useCollectionData(
    eventCollection
    , {
      snapshotListenOptions: { includeMetadataChanges: true },
    });

  useLayoutEffect(() => {
    if (!refMessage || !refMessage.current) {
      return
    }
    setWidthTypoMessage(refMessage.current.offsetWidth);
  }, [getDisplayMessagesFiltered()]);


  function getCurrentDisplayMessage() {
    if (!getDisplayMessagesFiltered() || getDisplayMessagesFiltered().length - 1 < currentDisplayMessageIndex) {
      return null
    }
    return getDisplayMessagesFiltered()
      .sort((a,b) => a.creationDate - b.creationDate)[currentDisplayMessageIndex];
  }

  let { translateX } = useSpring({
    from: {
      translateX: `${screenWidth}px`,
    },
    to: {
      translateX: `-${widthTypoMessage}px`,
      // translateX: `-1000px`,
    },
    config: {
      duration: computeDuration(getCurrentDisplayMessage()?.message),
    },

    loop: true,
    //onRest: () => deleteOldestMessage(),
    onProps: (props, value) => {
      if (value.get() == `-${widthTypoMessage}px` && lastValueTranslateX != value.get()) {
        lastValueTranslateX = value.get()
        increaseDisplayMessageIndex();
      }
      lastValueTranslateX = value.get()
    }
  } )

  function getDialog() {
    return (<div>
      {board &&
        <Dialog
          open={dialogEvent != null}
          maxWidth="lg"
        >
          <animated.div style={springOpacityProps}>
            <DialogContent className={"dialog"}>
              <div className={"dialog"}>
                <Typography variant="h1" component="div">
                  {dialogEvent && dialogEvent.idDisplay}
                </Typography>
                <Typography variant="h2" component="div">
                  {dialogEvent && getDisplayStatus(dialogEvent.status)}
                </Typography>
              </div>
            </DialogContent>
          </animated.div>
        </Dialog>
      }
    </div>)
  }

  function getMaxItem() {
    return board.maxItemPerColumn;
  }

  useEffect(() => {
    setDisplaySettingButton(false);
    removeBackGround()
  }, [])

  useEffect(() => {
      if (!events || !board) {
        return;
      }
      let nbPageToSet = 1;
      let maxItemColumn = 0;
      board.boardColumns.forEach(columnItem => {
        let filteredEvents = events && events
          .filter(e => e.status === columnItem.key && (
            (e.targetBoards || []).includes(board.id)));
        if (filteredEvents.length > getMaxItem()) {
          nbPageToSet *= Math.ceil(filteredEvents.length / getMaxItem())
        }
      })
      setNbPage(nbPageToSet)
    },
    [events]
  )

  useEffect(() => {
    const interval = setInterval(() => {
      console.log("currentPage " + currentPageVar)
      console.log("nbPage " + nbPage)
      let nextPage = currentPageVar + 1;
      console.log("nextPageAfter " + nextPage)
      if (nextPage > nbPage) {
        nextPage = 1;
      }
      currentPageVar=nextPage;
      setCurrentPage(nextPage);
      //setCurrentPage( ((currentPage + 1) % (nbPage + 1)) )
      console.log("nextPage " + nextPage)

      //}, board?.changePageDelay || 5000);
    }, board?.changePageDelay || 5000);
    return () => clearInterval(interval);
  }, [nbPage]);

  function displayItems(columnItem, columKey) {
    let filteredEvents = events && events
      .filter(e => e.status === columnItem.key &&
        (e.targetBoards || []).includes(board.id));

    if (!filteredEvents) {
      return [];
    }
    filteredEvents.sort((a, b) => a.creationDate - b.creationDate)
    let totalEvent = filteredEvents.length;
    let nbPageForColumn = Math.ceil(filteredEvents.length / getMaxItem());
    let currentIndexPage = currentPage % (nbPageForColumn + 1);
    let currentPageItems = filteredEvents.slice((getMaxItem() || 10) * ((currentIndexPage || 1)- 1), Math.min(filteredEvents.length,
      getMaxItem() * ((currentIndexPage || 1))));


    return currentPageItems
      .map((item, key) =>
        <EventForBoard event={item} keyp={columnItem.key}></EventForBoard>
      );

  }

  function getOtherDivHeight() {
    let total = 0;
    if (board?.enableDisplayMessage) {
      total += heightMessage;
    }
    if (board?.enableHeader) {
      total += headerHeight;
    }
    return `${total}px`;
  }

  return (
    <div style={{height: '100%'}}>

      {isEnoughCredit(brandDb) ?
        <>
          <div
            dangerouslySetInnerHTML={{__html: "<style>" + board?.cssDefinition + "</style>"}}
          />
          <Box
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              flexDirection: 'column',
              height: '100%',
              overflow: 'hidden'
            }}
          >
            {board?.enableHeader &&
              <Box sx={{
                width: '100%',
                display: 'flex',
                flexGrow: 1
              }}>
                <Toolbar className={"header"} sx={{height: `${headerHeight}px`, width:'100%', minHeight:'0px !important'}}>
                  <img
                    src={board.urlLogo}
                    height={headerLogoHeight}
                  />
                  <Box sx={{flexGrow: 1}}/>
                  <Typography variant="h2" component="div">
                    {board.titleHeader}
                  </Typography>
                </Toolbar>
              </Box>
            }
            {board?.enableDisplayEventPopup && getDialog()}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                flexGrow: 1
              }}
            >
              {board && board.boardColumns && board.boardColumns.map((columnItem, key) => {
                  return (<div key={key}>
                      <Card
                        className={columnItem.key}
                        sx={{
                          width: `calc(100vw / ${board.boardColumns.length})`,
                          height: `calc(100vh - ${getOtherDivHeight()})`,
                          border: 'solid',
                          borderRadius: '3px',
                          marginTop: '0px'
                        }}
                      >
                        <CardContent sx={{marginTop: '0px', zIndex:1, position: 'relative'}}>
                          <div className={columnItem.key + "-title"}>
                            <Box sx={{
                              border: 'solid',
                              borderRadius: '3px',
                              height: '100%'
                            }}>
                              <Typography variant="h2" component="div">
                                {columnItem.display}
                              </Typography>
                            </Box>
                          </div>
                          {displayItems(columnItem, key)}
                        </CardContent>
                      </Card>
                    </div>
                  )
                }
              )}
            </Box>

            {board?.enableDisplayMessage &&
              <Box className={"footer"} sx={{height: `${heightMessage}px`, overflow: 'hidden', width: '100%'}}>
                {getCurrentDisplayMessage() &&
                  <animated.div
                    style={{translateX}}
                  >
                    <Typography variant="h1" component="div" ref={refMessage}
                                sx={{marginTop: '5px', whiteSpace: 'nowrap', width: 'min-content'}}
                    >
                      {getCurrentDisplayMessage().message}
                    </Typography>
                  </animated.div>
                }
              </Box>
            }
          </Box>
        </>
        :
        <NoCreditCompo/>
      }

    </div>)
}

export default DisplayEvents;
