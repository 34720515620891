import React, {useState} from 'react';
import {useSnackbar} from 'notistack';
import localStrings from "../../localStrings";
import useAuth from "../../hooks/useAuth";
import enqueueSnackbarWithSound from "../../utils/SnackBarWithSound";
import toast from "react-hot-toast";
import DialogConfirm from "../../components/DialogConfirm";
import {deleteObject, getStorage, ref} from "../../lib/firebase";
import {
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  IconButton,
  Tooltip,
  Typography
} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import CheckIcon from '@mui/icons-material/Check';
import FileCopyIcon from '@mui/icons-material/FileCopy';

// const useStyles = makeStyles({
//   root: {
//     //maxWidth: 500,
//     margin: "10px"
//   },
//   media: {
//     height: 250,
//   },
// });

const config = require('../../conf/config.json');

const MediaCard = ({ mediaUrl, fileName, reloadCallBack, selectCallBack}) => {
  const { enqueueSnackbar } = useSnackbar();
  const { currentBrand } = useAuth();
  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);
  const [fileNameToDelete, setFileNameToDelete] = useState(null);


  async function deleteFile() {
    const path = currentBrand().id + "/" + config.remoteFiles.mediasFolder + "/" + fileNameToDelete;
    const storage = getStorage();
    var fileRef = ref(storage, path);
    await deleteObject(fileRef);
    enqueueSnackbarWithSound(toast, null, null,
      localStrings.formatString(localStrings.notif.fileDeleted, fileName));
    if (reloadCallBack) {
      reloadCallBack();
    }
    setConfirmDeleteDialogOpen(false);
  }

  function copy(url) {
    navigator.clipboard.writeText(url)
    //alert("delete " + fileName);
  }

  return(

    <>
      <DialogConfirm
        dialogOpen={confirmDeleteDialogOpen}
        handleClose={() => setConfirmDeleteDialogOpen(false)}
        handleConfirm={() => deleteFile()}
        deleteDialogText={localStrings.confirmMessage.deleteMedia}
      />

      <Card>
          <CardMedia
            component={fileName.endsWith(".mp4") ? 'video' : 'image'}
            sx={{height: 250, margin: "10px"}}
            image={mediaUrl}
            title={fileName}
            autoPlay
            loop
            muted
          />
          <CardContent>

            <Typography variant="body2" color="textSecondary" component="p">
              {fileName}
            </Typography>
          </CardContent>
        <CardActions disableSpacing>
          {selectCallBack ?
            <Tooltip title={localStrings.copyImgUrl}>
              <IconButton aria-label={localStrings.copyImgUrl}  onClick={() => {
                selectCallBack(mediaUrl)
              }}>
                <CheckIcon />
              </IconButton>
            </Tooltip>
            :
            <>
              <Tooltip title={localStrings.copyImgUrl}>
                <IconButton aria-label={localStrings.copyImgUrl}  onClick={() => copy(mediaUrl)}>
                  <FileCopyIcon />
                </IconButton>
              </Tooltip>

              <Tooltip title={localStrings.delete}>
                <IconButton aria-label={localStrings.delete} onClick={() => {
                  setFileNameToDelete(fileName)
                  setConfirmDeleteDialogOpen(true);
                }}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          }



        </CardActions>
      </Card>

    </>
  )
}

export default MediaCard;
