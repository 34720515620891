import React, {useEffect} from 'react';
import localStrings from "../../localStrings";
import LayoutDisplay from "../LayoutDisplay";
import {googlePresentationType, pptPresentationType} from "../MediaSequences/MediaSequenceDetailsView/mediaList";
import {
  sequenceLayoutType,
  sequenceMediaType,
  sequenceWebPageType, sequenceWidgetType,
  sequenceYouTubeType,
  sequenceBoardType
} from "../../utils/ItemSequenceSelector";
import ReactPlayer from "react-player/lazy";
import cloneDeep from "clone-deep";
import {split_type_vertical} from "../MediaSequences/MediaSequenceAddView/MediaSequenceCreateUpdateForm";
import WidgetDisplay from "../WidgetDisplay";
import DisplayEvents from "../DisplayEvents";

function SequenceDiv({sequence, brandId, screenHeight, screenWidth, setMediaSequence, mediaSequence, splitType}) {

  function getPacsId(urlWebLink) {
    let pattern = "/presentation/d/";
    let endUrl = urlWebLink.substring(urlWebLink.indexOf(pattern) + pattern.length + 2);
    return endUrl.substring(0, endUrl.indexOf('/'));
  }

  function getDelayMs(urlWebLink) {
    let pattern = "delayms=";
    if (urlWebLink.indexOf(pattern) == -1) {
      return 10000;
    }
    let endUrl = urlWebLink.substring(urlWebLink.indexOf(pattern) + pattern.length);
    return endUrl.substring(0, endUrl.indexOf('&') == -1 ? endUrl.length : endUrl.indexOf('&'));
  }


  useEffect(() => {

    var refreshId = setInterval(function() {
      var divUBody = document.getElementById("u_body");
      if (divUBody) {
        divUBody.style.removeProperty("min-height");
        divUBody.style.height = screenHeight.toString() + "px";
        divUBody.style.width = screenWidth.toString() + "px";
        divUBody.style.overflow = 'hidden';
        clearInterval(refreshId);
      }
    }, 20);
  }, [document.getElementById("u_body"), sequence])


  function getFullImageLink(googleDocId) {
    return "https://drive.google.com/uc?id=" + googleDocId;
  }

  function updateSequenceDuration (sequence, duration) {
    let cloneMediaSequence = cloneDeep(mediaSequence);
    let findIndex = cloneMediaSequence.sequences.findIndex(s => s.id === sequence.id);
    let sequenceItem = cloneMediaSequence.sequences.find(s => s.id === sequence.id);
    sequenceItem.duration = sequence.duration || duration;
    cloneMediaSequence.sequences.splice(findIndex, 1, sequenceItem);
    // alert("duration video " + duration)
    setMediaSequence(cloneMediaSequence);
  }

  if (!sequence) {
    return null;
  }

  //return (<p>{JSON.stringify(sequence)}</p>);
  if (sequence && (sequence.mimeType === googlePresentationType || sequence.mimeType === pptPresentationType)) {
    if (sequence.urlWebLink) {
      return (
        <iframe
          src={"https://docs.google.com/presentation/d/e/" + getPacsId(sequence.urlWebLink) + "/embed?start=true&loop=true&delayms=" +
            getDelayMs(sequence.urlWebLink) + "&rm=minimal"}
          frameBorder="0" width={screenWidth + "px"} height={screenHeight + "px"} allowFullScreen="true" mozallowfullscreen="true" style={{display: 'block', overflow: 'hidden'}}
          webkitallowfullscreen="true">
        </iframe>
      )
    }
    else {
      return (
        <div style={{textAlign: 'center'}}>
          <h1>
            {localStrings.warningMessage.defineWebLinkUrl}
          </h1>
        </div>
      )
    }
  }
  else if (sequence && sequence.type === sequenceMediaType) {
    // setBackGround(mediaSequence.backgroundColor)
    return (<div style={{
      height: '100%',
      width: 'auto',
      backgroundPosition: 'center',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      margin: 0,
      overflow: 'hidden',
      display: 'flex',
      justifyContent: 'center',
      backgroundColor: mediaSequence.backgroundColor,
      alignItems: 'center'
    }}>
      {splitType === split_type_vertical ?
        <>
          {sequence.urlWebLink.indexOf('.mp4') !== -1 ?
            <ReactPlayer
              height={screenHeight}
              width={(screenWidth).toString() + 'px'}
              loop={true}
              playing={true}
              autoPlay={true}
              volume={sequence.mute ? 0 : 1 }
              config={{
                youtube: {
                  playerVars: { cc_lang_pref: 'fr', cc_load_policy: 1 }
                }
              }}
              url={sequence.urlWebLink}
            />
            :
            <img
              width={(screenWidth) .toString() + "px"}
              src={sequence.urlWebLink} style={{
              objectFit:'cover',
              maxHeight: (screenHeight) .toString() + "px"
            }}/>
          }
        </>
        :
        <>
          {sequence.urlWebLink.indexOf('.mp4') !== -1 ?
            <ReactPlayer
              height={(screenHeight) .toString() + "px"}
              width={(screenWidth).toString() + 'px'}
              loop={true}
              playing={true}
              autoPlay={true}
              volume={sequence.mute ? 0 : 1 }
              config={{
                youtube: {
                  playerVars: { cc_lang_pref: 'fr', cc_load_policy: 1 }
                }
              }}
              url={sequence.urlWebLink}
            />
            :
            <img
              height={(screenHeight) .toString() + "px"}
              src={sequence.urlWebLink} style={{
              objectFit:'cover',

            }}/>
          }
        </>
      }

    </div>);
  }
  else if (sequence && sequence.mimeType && sequence.mimeType.startsWith("image")) {
    //setBackGround(mediaSequence.backgroundColor)

    return (
      <div style={{
        height: '100%',
        width: 'auto',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        display: 'block',
        margin: 0,
        overflow: 'hidden'
      }}>
        <img src={getFullImageLink(sequence.googleDocId)} style={{width: '100%'}}/>
      </div>
    )
  }
  else if (sequence && sequence.mimeType &&  sequence.mimeType.startsWith("video")) {
    return (
      <div style={{
        textAlign: 'center'
      }}>
        <iframe src={'https://drive.google.com/file/d/' + sequence.googleDocId + '/preview?start=1'} width={screenWidth}
                height={screenHeight}
                allow="autoplay"></iframe>
      </div>
    )
  } else if (sequence && sequence.type === sequenceYouTubeType) {
    return (
      <div style={{
        textAlign: 'center',
        backgroundColor: 'black'
      }}>

        <ReactPlayer
          height={screenHeight}
          width={screenWidth}
          loop={true}
          playing={true}
          volume={sequence.mute ? 0 : 1 }
          autoPlay={true}
          onDuration={duration => updateSequenceDuration(sequence, duration)}
          config={{
            youtube: {
              playerVars: { cc_lang_pref: 'fr', cc_load_policy: 1 }
            }
          }}
          url={sequence.urlWebLink} />
      </div>
    )
  }

  else if (sequence && sequence.type === sequenceLayoutType) {
    //removeBackGround();
    return (

      <div style={{
        height:screenHeight + ' !important',
        width:screenWidth + ' !important',
        overflow: "hidden",
      }}>
        <LayoutDisplay brandIdParam={brandId} layoutIdParam={sequence.layoutId}/>
      </div>
    )
  }

  else if (sequence && sequence.type === sequenceWidgetType) {
    return (

      <div style={{
        height:screenHeight + 'px',
        width:screenWidth + 'px',
        backgroundColor: mediaSequence.backgroundColor,
      }}>

        <WidgetDisplay brandIdParam={brandId} layoutIdParam={sequence.widgetId}/>
      </div>
    )
  }

  else if (sequence && sequence.type === sequenceBoardType) {
    return (

      <DisplayEvents boardIdParam={sequence.boardId} brandIdParam={brandId}/>

      // <div style={{
      //   height:screenHeight + 'px',
      //   width:screenWidth + 'px',
      //   backgroundColor: mediaSequence.backgroundColor,
      // }}>
      //
      //   <WidgetDisplay brandIdParam={brandId} layoutIdParam={sequence.widgetId}/>
      // </div>
    )
  }


  else if (sequence && sequence.type === sequenceWebPageType) {
    return (

      <iframe width={screenWidth} height={screenHeight} src={sequence.urlWebLink}>
      </iframe>
    )
  }

  return (<h1>{localStrings.unknownMedia}</h1>)
}

export default SequenceDiv;
