import { Box, Grid } from '@material-ui/core';
import MediaCard from './MediaCard';
import React, { useEffect, useState } from 'react';
import useAuth from "../../hooks/useAuth";
import {getStorage, ref, listAll} from "../../lib/firebase";
import {getDownloadURL, storage} from "../../../src/lib/firebase";
import EmptyList from "../../components/EmptyList";
import localStrings from "../../localStrings";
import ClipLoaderComponent from "../../components/ClipLoaderComponent";
import { Alert, Stack } from '@mui/material';
const config = require('../../conf/config');
const { uuid } = require('uuidv4');

export async function loadImages(currentBrand) {
  const path = currentBrand().id + "/" + config.remoteFiles.mediasFolder;
  const storage = getStorage();
  const listRef = ref(storage, path);

  const res = await listAll(listRef);

  let allItems = [];
  for (let i = 0; i < res.items.length; i++) {
    const itemRef = res.items[i];
    //alert("itemRef " + itemRef);
    const url = await getDownloadURL(itemRef);
    let fileName = itemRef._location.path_.split("/").slice(-1)[0]
    allItems.push({
      url: url,
      fileName: fileName,
      id: uuid()
    })
  }
  return allItems

}

const MedialList = ({ selectCallBack, reload, setReload }) => {
  const {currentBrand, fileUploaded} = useAuth();
  const [files, setFiles] = useState(null);


  async function loadImageAndSet() {
    const images = await  loadImages(currentBrand);
    setFiles(images);
  }

  useEffect(() => {
    loadImageAndSet();
  }, [fileUploaded, reload])

  return (
    <Box mt={2} sx={{width: '100%'}}>
      {currentBrand() && currentBrand().useChargeOnline && currentBrand().totalFilePart && currentBrand().totalFilePart > 1 &&
        <Stack sx={{width: '100%', marginTop: 5}} spacing={2}>
          <Alert severity="warning">{localStrings.chargeableSizeFiles}</Alert>
        </Stack>
      }
      {!files ?
        <ClipLoaderComponent/>
        :
        <>
          {(files && files.length == 0) ?
            <EmptyList title={localStrings.noMediaList}/>
            :
            <Grid
              container
              spacing={1}
            >
              {files.map((item, key) =>

                <Grid
                  item
                  key={key}
                  md={4}
                  xl={4}
                  xs={12}
                >
                  <MediaCard
                    selectCallBack={selectCallBack}
                    reloadCallBack={() => setReload(!reload)}
                    fileName={item.fileName}
                    mediaUrl={item.url}>
                  </MediaCard>

                </Grid>
              )}
            </Grid>
          }
        </>
      }

    </Box>
  )
}

export default MedialList

