import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import {addMinutes, addMonths} from 'date-fns';
import * as Yup from 'yup';
import {useFormik} from 'formik';
import Trash02Icon from '@untitled-ui/icons-react/build/esm/Trash02';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Dialog,
  Divider,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  IconButton,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Typography
} from '@mui/material';
import {DateTimePicker} from '@mui/x-date-pickers';
import localStrings from "../../../../localStrings";
import {executeMutationUtil} from "../../../../utils/gqlUtil";
import {getMediaSequences} from "../../../MediaSequences/MediaSequenceListView/Results";
import useAuth from "../../../../hooks/useAuth";
import {
  addDataScheduleItemByBrandIdToCache,
  bulkDeleteScheduleItemMutation,
  createScheduleItemMutation,
  deleteScheduleItemByBrandIdCache,
  updateScheduleItemMutation
} from "../../../../gql/scheduleItemGql";
import useAppUtil from "../../../../hooks/useAppUtil";
import {LoadingButton} from "@mui/lab";
import {useParams} from "react-router-dom";
import CheckIcon from '@mui/icons-material/Check';
import moment from "moment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {getStartedSchedule, getStartedWidget, setGetStartedDone} from "../../../GettingStarted";

export const repeatOnce = "repeatOnce";
export const repeatDaily = "repeatDaily";
export const repeatWeekly = "repeatWeekly";
export const repeatMonthly = "repeatMonthly";
export const repeatYearly = "repeatYearly";

const useInitialValues = (event, range) => {
  return useMemo(() => {
    if (event) {
      return {
        allDay: event.allDay || false,
        end: event.end ? new Date(parseFloat(event.end)) : addMinutes(new Date(), 30),
        start: event.start ? new Date(parseFloat(event.start)) : new Date(),
        endRepeatDate: event.endRepeatDate ? new Date(parseFloat(event.endRepeatDate)) : addMonths(new Date(), 1),
        repeat: event.repeat || repeatOnce,
        itemId: event.itemId,
        monday: event.monday,
        tuesday: event.tuesday,
        wenesday: event.wenesday,
        thursday: event.thursday,
        friday: event.friday,
        saturday: event.saturday,
        sunday: event.sunday,
        endRepeat: event.endRepeat,
        noEndRepeat: event.noEndRepeat,
        submit: null
      };
    }

    if (range) {

      const aYearFromNow = new Date();
      aYearFromNow.setFullYear(aYearFromNow.getFullYear() + 1);

      return {
        allDay: false,
        end: new Date(range.end),
        start: new Date(range.start),
        submit: null,
        repeat: repeatOnce,
        monday: false,
        tuesday: false,
        wenesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
        endRepeatDate: aYearFromNow,
        noEndRepeat: true,
      };
    }

    return {
      allDay: false,
      color: '',
      description: '',
      end: addMinutes(new Date(), 30),
      start: new Date(),
      title: '',
      submit: null
    };
  }, [event, range]);
};


export const CalendarEventDialog = (props) => {
  const {
    action = 'create',
    event,
    onAddComplete,
    onClose,
    onDeleteComplete,
    onEditComplete,
    open = false,
    range,
    //scheduleId,
    setEvents,
    events,
    transformEvent,
    checkCrossNoEvent,
    getInitialEvent,
    setIdBack,
    doReloadCalendar,
    fillSpreadEvents
  } = props;

  const [mediaSequences, setMediaSequences] = useState([]);
  const {currentBrand, sendPlayConfetti} = useAuth();
  const {addError} = useAppUtil();
  let {scheduleId} = useParams();

  const validationSchema = Yup.object({
    allDay: Yup.bool(),
    end: Yup.date(),
    start: Yup.date(),
    itemId: Yup.string().required(localStrings.check.fieldRequired)
  });

  useEffect(() => {
    const load = async () => {
      const res  = await getMediaSequences(currentBrand);
      setMediaSequences(res?.data?.getMediaSequencesByBrandId ||  []);

    }
    load();
  }, [currentBrand])

  const initialValues = useInitialValues(event, range);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    validationSchema,
    onSubmit: async (values, helpers) => {
      try {
        const data = {
          ...(event || {}),
          ...values,
          end: values.end.getTime().toString(),
          start: values.start.getTime().toString(),
          endRepeatDate: (values.endRepeatDate || new Date()).getTime().toString(),
          title: (mediaSequences || []).find(m => m.id == values.itemId)?.name || ""
        };

        delete data.color;
        delete data.description;

        if (action === 'update') {
          data.id = event.id;
        }

        const spreadEvents = [];
        fillSpreadEvents(transformEvent(data), spreadEvents);
        for (let i = 0; i < spreadEvents.length; i++) {
          const spreadEvent = spreadEvents[i];
          if (!checkCrossNoEvent({
            ...spreadEvent,
            //id: spreadEvent.initialId
          })) {
            addError(localStrings.formatString(localStrings.warningMessage.cannotCrossEntriesDetail, moment(spreadEvent.start).format("LLLL")))
            return;
          }
        }

        if (action === 'update') {
          const res =
            await executeMutationUtil(updateScheduleItemMutation(currentBrand().id, scheduleId, data));
          setEvents([...events.filter(e => e.id !== data.id), transformEvent(res.data?.updateScheduleItem)])
          doReloadCalendar();
          toast.success(localStrings.notif.scheduleItemUpdated);
        } else {
          const added =
            await executeMutationUtil(createScheduleItemMutation(currentBrand().id, scheduleId, data))
          await addDataScheduleItemByBrandIdToCache(currentBrand().id, scheduleId, [added.data?.addScheduleItem]);
          setEvents([...events, transformEvent(added.data?.addScheduleItem)])
          doReloadCalendar();
          toast.success(localStrings.notif.scheduleItemCreated);
        }

        if (action === 'update') {
          onEditComplete?.();
        } else {
          onAddComplete?.();
        }
        await setGetStartedDone(getStartedSchedule, sendPlayConfetti)
      } catch (err) {
        console.log(err);
        addError(err.message);
        //toast.error('Something went wrong!');
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    }
  });

  const handleStartDateChange = useCallback((date) => {
    formik.setFieldValue('start', date);

    // Prevent end date to be before start date
    if (formik.values.end && date && date > formik.values.end) {
      formik.setFieldValue('end', date);
    }
  }, [formik]);

  const handleRepeatDateChange = useCallback((date) => {
    formik.setFieldValue('endRepeatDate', date);
  }, [formik]);

  const handleEndDateChange = useCallback((date) => {
    formik.setFieldValue('end', date);

    // Prevent start date to be after end date
    if (formik.values.start && date && date < formik.values.start) {
      formik.setFieldValue('start', date);
    }
  }, [formik]);

  const handleDelete = useCallback( async () => {
    if (!event) {
      return;
    }

    try {
      let eventForDb =  transformEvent(event)
      eventForDb = getInitialEvent(eventForDb);
      await executeMutationUtil(bulkDeleteScheduleItemMutation(currentBrand().id, scheduleId, [eventForDb.id]))
      await deleteScheduleItemByBrandIdCache(currentBrand().id, scheduleId, [eventForDb.id]);
      setEvents([...events.filter(e => e.id !== event.id)])
      doReloadCalendar();
      toast.success(localStrings.notif.scheduleItemDeleted);
      onClose();
    } catch (err) {
      console.error(err);
    }
  }, [events, event, setEvents, onClose])

  function noDaySelected(values) {
    if (values.repeat !== repeatWeekly) {
      return false;
    }
    return !values.monday && !values.tuesday && !values.wenesday &&!values.thursday &&!values.friday
      && !values.saturday && !values.sunday
  }

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
    >
      <form onSubmit={formik.handleSubmit}>
        <Box sx={{ p: 3 }}>
          <Typography
            align="center"
            gutterBottom
            variant="h5"
          >
            {event
              ? localStrings.editEntry
              : localStrings.addEntry}
          </Typography>
        </Box>
        <Stack
          spacing={2}
          sx={{ p: 3 }}
        >

          <DateTimePicker
            label="Start date"
            onChange={handleStartDateChange}
            renderInput={(inputProps) => (
              <TextField
                fullWidth
                {...inputProps} />
            )}
            value={formik.values.start}
          />
          <DateTimePicker
            label="End date"
            onChange={handleEndDateChange}
            renderInput={(inputProps) => (
              <TextField
                fullWidth
                {...inputProps} />
            )}
            value={formik.values.end}
          />


          <FormControl variant="outlined" fullWidth sx={{marginTop: 0}}>
            <Autocomplete

              noOptionsText={localStrings.noItemAssociated}
              value={formik.values.itemId || null}
              options={mediaSequences.map(m => m.id) || []}
              getOptionLabel={(id) => (mediaSequences || []).find(m => m.id == id)?.name || ""}

              onChange={(event, value) => {
                formik.setFieldValue("itemId", value);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params}
                           error={!formik.values.itemId}
                           helperText={formik.values.itemId ? null : localStrings.check.fieldRequired}
                           fullWidth
                           label={localStrings.mediaSequence}
                  // name="country"
                />
              )}
            />
          </FormControl>


          <FormControl variant="outlined" fullWidth sx={{marginTop: 0}}
                       error={Boolean(formik.touched.repeat && formik.errors.repeat)}
          >
            <Autocomplete
              value={formik.values.repeat || null}
              options={[repeatOnce, repeatWeekly, repeatMonthly]}
              getOptionLabel={(option) => localStrings[option]}
              onChange={(event, value) => {
                formik.setFieldValue("repeat", value);
              }}
              filterSelectedOptions
              renderInput={(params) => (
                <TextField {...params}
                           fullWidth
                           label={localStrings.repeat}
                />
              )}
            />
          </FormControl>

          {formik.values.repeat === repeatWeekly &&
            <FormControl component="fieldset" error={noDaySelected(formik.values)}>
              <FormGroup aria-label="position" row>
                <FormControlLabel
                  value="top"
                  control={<Checkbox
                    checked={formik.values.monday}
                    onChange={(e) => {
                      formik.setFieldValue("monday", e.target.checked);
                    }}
                  />}
                  label={localStrings.mondayInitial}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox
                    checked={formik.values.tuesday}
                    onChange={(e) => {
                      formik.setFieldValue("tuesday", e.target.checked);
                    }}
                  />}
                  label={localStrings.tuesdayInitial}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox
                    checked={formik.values.wenesday}
                    onChange={(e) => {
                      formik.setFieldValue("wenesday", e.target.checked);
                    }}
                  />}
                  label={localStrings.wenesdayInitial}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox
                    checked={formik.values.thursday}
                    onChange={(e) => {
                      formik.setFieldValue("thursday", e.target.checked);
                    }}
                  />}
                  label={localStrings.thursdayInitial}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox
                    checked={formik.values.friday}
                    onChange={(e) => {
                      formik.setFieldValue("friday", e.target.checked);
                    }}
                  />}
                  label={localStrings.fridayInitial}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox
                    checked={formik.values.saturday}
                    onChange={(e) => {
                      formik.setFieldValue("saturday", e.target.checked);
                    }}
                  />}
                  label={localStrings.saturdayInitial}
                  labelPlacement="top"
                />
                <FormControlLabel
                  value="top"
                  control={<Checkbox
                    checked={formik.values.sunday}
                    onChange={(e) => {
                      formik.setFieldValue("sunday", e.target.checked);
                    }}
                  />}
                  label={localStrings.sundayInitial}
                  labelPlacement="top"
                />
              </FormGroup>
              {noDaySelected(formik.values) &&
                <FormHelperText>{localStrings.warningMessage.selectAtLeastOneDay}</FormHelperText>
              }
            </FormControl>
          }

          {formik.values.repeat !== repeatOnce &&
            <FormControlLabel
              control={(
                <Switch
                  checked={formik.values.noEndRepeat}
                  name="noEndRepeat"
                  onChange={() => {
                    formik.setFieldValue('noEndRepeat',  !formik.values.noEndRepeat )
                  }}
                />
              )}
              label={localStrings.repeatTillEndOfTime}
            />
          }
          {formik.values.repeat !== repeatOnce && !formik.values.noEndRepeat &&
            <DatePicker
              label={localStrings.endRepeatDate}
              onChange={handleRepeatDateChange}
              renderInput={(inputProps) => (
                <TextField
                  fullWidth
                  {...inputProps} />
              )}
              value={formik.values.endRepeatDate}
            />
          }

          {!!(formik.touched.end && formik.errors.end) && (
            <FormHelperText error>
              {formik.errors.end}
            </FormHelperText>
          )}
        </Stack>
        <Divider />
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={1}
          sx={{ p: 2 }}
        >
          {event && (
            <IconButton onClick={() => handleDelete()}>
              <SvgIcon>
                <Trash02Icon />
              </SvgIcon>
            </IconButton>
          )}
          <Stack
            alignItems="center"
            direction="row"
            spacing={1}
          >
            <Button
              color="inherit"
              onClick={onClose}
            >
              {localStrings.cancel}
            </Button>

            <LoadingButton
              loadingPosition="end"
              loading={formik.isSubmitting}
              type="submit"
              variant="contained"
              disabled={noDaySelected(formik.values)}
              endIcon={<SvgIcon>
                <CheckIcon />
              </SvgIcon>}
            >
              {localStrings.confirm}
            </LoadingButton>
          </Stack>
        </Stack>
      </form>
    </Dialog>
  );
};

CalendarEventDialog.propTypes = {
  action: PropTypes.oneOf(['create', 'update']),
  event: PropTypes.object,
  onAddComplete: PropTypes.func,
  onClose: PropTypes.func,
  onDeleteComplete: PropTypes.func,
  onEditComplete: PropTypes.func,
  open: PropTypes.bool,
  range: PropTypes.object
};
