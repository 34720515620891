import React from 'react';
import {Box, Dialog, DialogContent, IconButton, Stack, SvgIcon, Typography} from "@mui/material";
import localStrings from "../../localStrings";
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import {ItemListCard} from "../../utils/itemListCard";
import ClipLoaderComponent from "../ClipLoaderComponent";
import ItemSelector from "./itemSelector";

function ItemSelectorDialog({open, items, selectCallBack, onClose, title, loading, children}) {
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      //onClose={onClose}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography variant="h6">
          {title}
        </Typography>
        <IconButton
          color="inherit"
          onClick={onClose}
        >
          {children}
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DialogContent>
        <Box
          sx={{
            width: '100%',
            display: 'grid',
            gap: 3,
          }}
        >
          <ItemSelector items={items} selectCallBack={selectCallBack} onClose={onClose}/>
        </Box>
      </DialogContent>

    </Dialog>
  );
}

export default ItemSelectorDialog;
