import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {executeQueryUtil} from "../utils/gqlUtil";
import {decode as base64_decode} from 'base-64';
import useAuth from "../hooks/useAuth";
import {getWidgetQuery} from "../gql/widgetGql";
import {Helmet} from "react-helmet";
import {doc, getFirestore} from "firebase/firestore";
import {BRAND_COLLECTION, LAYOUT_DATA_COLLECTION, WIDGET_DATA_COLLECTION} from "../utils/constants";
import {useDocument} from "react-firebase-hooks/firestore";
import JsDependenciesEditor from "./Widgets/WidgetDetailsView/jsDependenciesEditor";
import {setBackGround} from "./ScreenDisplay/screenDisplay";
const Mustache = require('mustache');

function WidgetDisplay({brandIdParam,layoutIdParam}) {
  let {brandId} = useParams();
  let {widgetId} = useParams();

  const brandIdP = brandIdParam || brandId;
  const widgetIdP = layoutIdParam || widgetId;
  const [htmlSource, sethtmlSource] = useState("");

  const [widget, setWidget] = useState(null);
  const [decodedValues, setDecodedValues] = useState(null);

  const db = getFirestore();
  const {setDisplaySettingButton} = useAuth();

  const refDoc =  doc(db, BRAND_COLLECTION
    + "/" + brandIdP + "/" + WIDGET_DATA_COLLECTION, widgetIdP);
  const [widgetData, widgetDataLoading, widgetDataError] = useDocument(refDoc);

  const loadData = async () => {
    var res = await executeQueryUtil(getWidgetQuery(brandIdP, widgetIdP));
    setWidget(res?.data?.getWidget);
    setDecodedValues(getDecodedValues(res?.data?.getWidget));
    // if (res?.data?.getWidget?.backgroundColor) {
    //   setBackGround(res.data.getWidget.backgroundColor)
    // }
  }


  useEffect(() => {
    setDisplaySettingButton(false);
    loadData();
  }, [widgetData])

  function getDecodedValues(widget) {
    if (!widget) {
      return "";
    }
    let htmlDecoded = base64_decode(widget.htmlCode)

    if (widgetData?.data()?.bodyData) {
      const data = JSON.parse(base64_decode(widgetData?.data()?.bodyData));
      htmlDecoded = Mustache.render(htmlDecoded, data)
    }
    const cssDecoded = base64_decode(widget.cssCode)
    const jsDecoded = base64_decode(widget.jsCode)
    const jsDependenciesEditorDecoded = base64_decode(widget.jsDependenciesCode)
    const cssDependenciesEditorDecoded = base64_decode(widget.cssDependenciesCode)
    return {
      html: htmlDecoded,
      css: cssDecoded,
      js: jsDecoded,
      jsDependencies: jsDependenciesEditorDecoded,
      cssDependencies: cssDependenciesEditorDecoded,
    }
  }

  function getJsDependenciesCodeLines() {
    if (!decodedValues?.jsDependencies) {
      return [];
    }
    let lines = decodedValues.jsDependencies.split(/\r?\n/);
    return lines.map(l => l.trim());
  }

  function getCssDependenciesCodeLines() {
    if (!decodedValues?.cssDependencies) {
      return [];
    }
    let lines = decodedValues.cssDependencies.split(/\r?\n/);
    return lines.map(l => l.trim());
  }

  return (
    <div>
      {decodedValues &&
        <>
          <Helmet>
            {getJsDependenciesCodeLines().map((line, key) =>
              <script
                type="text/javascript"
                src={line}
                crossorigin=""
              >
              </script>
            )}

            {getCssDependenciesCodeLines().map((line, key) =>
              <link rel="stylesheet" href={line} crossorigin=""/>
            )}
            <script>{decodedValues?.js}</script>
            <style>{decodedValues?.css}</style>
          </Helmet>
          <div
            dangerouslySetInnerHTML={{__html: decodedValues.html}}
          />
        </>
      }
    </div>
  );
}

export default WidgetDisplay;
