import React, {useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import {getLayoutQuery, updateLayoutMutation} from "../../../gql/layoutGql";
import useAuth from "../../../hooks/useAuth";
import {Box, Button, SvgIcon, Tab, Tabs} from "@mui/material";
import localStrings from "../../../localStrings";
import cloneDeep from "clone-deep";
import enqueueSnackbarWithSound from "../../../utils/SnackBarWithSound";
import {useSnackbar} from "notistack";
import useSound from "use-sound";
import config from "../../../conf/config.json";
import useSettings from "../../../hooks/useSettings";
import {decode as base64_decode, encode as base64_encode} from 'base-64';
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import toast from "react-hot-toast";
import {loadImages} from "../../MediaListView/MedialList";
import {uuid} from "uuidv4";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {uploadFiles} from "../../../sections/dashboard/file-manager/file-uploader";
import WebEditor from './script/unlayer';
import JsonEditor from "./jsonEditor";
import HtmlEditor from "./htmlEditor";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import {LoadingButton} from "@mui/lab";

const imageType = "image";

export function getLayoutDisplayUrl(currentBrand, layoutId) {
  return '/screenDisplayLayout/' + currentBrand().id
    + '/' + layoutId;
}

export function geWidgetDisplayUrl(currentBrand, widgetId) {
  return '/screenDisplayWidget/' + currentBrand().id
    + '/' + widgetId;
}

function Editor({}) {
  const [currentTab, setCurrentTab] = useState('design');
  const [htmlCode, setHtmlCode] = useState(null);
  const [jsonCode, setJsonCode] = useState(null);

  const handleTabsChange = (event, value) => {
    setCurrentTab(value);
  };

  const { enqueueSnackbar } = useSnackbar();
  const { playSoundIfActive } = useSettings()
  const [playDone] = useSound(
    config.sound.done,
    { volume: 0.5 }
  );

  let {layoutId} = useParams();
  const emailEditorRef = useRef(null);

  const [layout, setLayout] = useState(null);
  const [items, setItems] = useState(null);
  const [saving, setSaving] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const { currentBrand } = useAuth();

  useEffect(() => {
    const loadData = async () => {
      var resLayout =  await executeQueryUtil(getLayoutQuery(currentBrand().id, layoutId));
      setLayout(resLayout?.data?.getLayout);

      const images = await loadImages(currentBrand)
      let imageDetails = images.map(image => {
        return {
          ...image,
          id: uuid(),
          type: imageType,
        }
      })

      setItems({
        //allSequences: allSequences,
        images: imageDetails,
        brandId: currentBrand().id
      });
    }

    loadData()
  }, [])

  useEffect(() => {
    if (currentTab === 'json') {
      emailEditorRef.current.saveDesign(async function (design) {
        setJsonCode(JSON.stringify(design, null, 2))
      })
    }
    if (currentTab === 'html') {
      exportHtmlPromise().then(htmlCode => setHtmlCode(htmlCode))
      // emailEditorRef.current.saveDesign(async function (design) {
      //   setJsonCode(JSON.stringify(design, null, 2))
      // })
    }
  }, [currentTab])

  const exportHtmlPromise = () => {
    return new Promise((resolve, reject) => {
      try {
        emailEditorRef.current.editor.exportHtml((data) => {
          const {design, html} = data;
          resolve(html);
        });
      }
      catch (err) {
        reject(err);
      }
    })
  }

  const save = async () => {

    let existingData = await executeQueryUtil(getLayoutQuery(currentBrand().id, layout.id))
    emailEditorRef.current.saveDesign(async function (design) {
      setSaving(true);
      let cloneLayout = cloneDeep(layout);
      cloneLayout.design = base64_encode(JSON.stringify(design));
      cloneLayout.html = base64_encode(await exportHtmlPromise());
      await executeMutationUtil(updateLayoutMutation(currentBrand().id, {
        ...existingData.data?.getLayout,
        ...cloneLayout
      }))

      enqueueSnackbarWithSound(toast, playDone, playSoundIfActive,
        localStrings.notif.layoutUpdated);
      setSaving(false);
    })
  }

  const preview = () => {
    alert("preview")
  };

  const loadJsonCode = (code) => {
    emailEditorRef.current.loadDesign(JSON.parse(code));
  }

  const onReady = () => {
    // editor is ready
    console.log('onReady');
    if (layout.design && !loaded) {
      loadJsonCode(base64_decode(layout.design))
      //let decodedJson = JSON.parse(base64_decode(layout.design));
      //emailEditorRef.current.loadDesign(decodedJson);
    }

    setLoaded(true);

    emailEditorRef.current.registerCallback('image', function(files, done) {
      const filesAccepted = files.attachments
      uploadFiles(filesAccepted, currentBrand().id).then(resUrl => {
        done({ progress: 100, url: resUrl })
      })
    })
  };

  const externalOptions = { //displayMode & Tags
    displayMode: "web",

    customJS: [
      window.location.protocol +
      "//" +
      window.location.host +
      "/custom.js"
    ],
    customCSS: [
      "https://examples.unlayer.com/examples/product-library-tool/productTool.css"
    ],
    appearance: {
      theme: 'dark'
    },
    features: {
      preview: false,
      userUploads: false
    },
  }

  const tools = {
    "custom#cast_reactor_tool": {
      data: {
        items: items
      },
      properties: {
        selectedItem: {
          editor: {
            data: {
              items: items
            }
          }
        }
      }
    },
    button: {
      enabled: false
    },
    image: {
      enabled: true
    },
    menu: {
      enabled: false
    },
    form: {
      enabled: false
    },
    timer: {
      enabled: true
    }

  }


  const onLoad = () => {

    emailEditorRef.current.registerCallback('image', function(file, done) {

      uploadFiles([file], currentBrand().id).then(resUrsl => {
        done({ progress: 100, url: resUrsl })
      })

    })
  };
  return (
    <div>
      {(!items || !layout) ?
        <ClipLoaderComponent/>
        :
        <>

          <Tabs
            onChange={handleTabsChange}
            scrollButtons="auto"
            value={currentTab}
            textColor="secondary"
            variant="scrollable"
          >
            <Tab label={localStrings.design} value="design" />
            <Tab label={localStrings.json} value="json" />
            <Tab label={localStrings.html} value="html" />
          </Tabs>


          {/*{currentTab === 'design' &&*/}
          <div id="editor-container" style={{
            display: currentTab === 'design' ? 'block' : 'none',
            height: '500px'
          }}>
            <WebEditor
              style={{marginTop: "50px"}}
              projectId={141982}
              ref={emailEditorRef}
              onReady={onReady}
              tools={tools}
              //onLoad={onLoad}
              options={externalOptions}/>
          </div>
          {/*}*/}
          {currentTab === 'json' &&
            <div style={{
              height: '500px'
            }}>
              <JsonEditor
                code={jsonCode}
                setCode={setJsonCode}
                apply={(code) => loadJsonCode(code)}
              />
            </div>
          }
          {currentTab === 'html' &&
            <div style={{
              height: '500px'
            }}>
              <HtmlEditor
                code={htmlCode}
                setCode={setHtmlCode}
              />

            </div>
          }
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row-reverse',
              p: 1,
              m: 1,
              height: '25px',
              borderRadius: 1,
            }}
          >
            <Box>
              <LoadingButton
                loading={saving}
                variant="contained"
                color="primary"
                style={{marginLeft: "2px"}}
                onClick={() => save()}
                endIcon={<SvgIcon>
                  <ArrowForwardIcon />
                </SvgIcon>}
              >
                {localStrings.save}
              </LoadingButton>
            </Box>
            <Box>
              <Button
                endIcon={<RemoveRedEyeIcon />}
                variant="contained"
                color="primary"
                style={{marginLeft: "2px"}}
                onClick={async () => {
                  await save();
                  window.open(getLayoutDisplayUrl(currentBrand, layoutId), '_blank').focus();
                }}
              >
                {localStrings.preview}
              </Button>
            </Box>
          </Box>
        </>
      }
    </div>
  );
}

export default Editor;
