import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import GeneralEditor, {MODE_CREATE, MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import localStrings from "../../../localStrings";
import {useNavigate} from "react-router";
import TextFieldWithCopy from "../../../components/TextFieldWithCopy";
import {
  addDataMediaSequenceByBrandIdToCache,
  createMediaSequenceMutation,
  getMediaSequenceQuery,
  updateMediaSequenceMutation
} from "../../../gql/mediaSequenceGql";
import * as Yup from "yup";
import cloneDeep from "clone-deep";
import config from "../../../conf/config.json";
import axios from "axios";
import Box from "@mui/material/Box";
import moment from "moment";
import {
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TableCell,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import useAppUtil from "../../../hooks/useAppUtil";
import {SketchPicker} from "react-color";
import PaletteIcon from '@mui/icons-material/Palette';
import XIcon from "@untitled-ui/icons-react/build/esm/X";
import {dashboardBaseRoute, mediaSequencesRoute} from "../../../routes/dashboard";
import {not_found_path} from "../../../routes";
import {getStartedLayout, getStartedMediaSequence, setGetStartedDone} from "../../GettingStarted";

const { uuid } = require('uuidv4');

export function invertColor(hex) {
  if (hex.indexOf('#') === 0) {
    hex = hex.slice(1);
  }
  // convert 3-digit hex to 6-digits.
  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }
  if (hex.length !== 6) {
    throw new Error('Invalid HEX color.');
  }
  // invert color components
  var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
    g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
    b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
  // pad each with zeros and return
  return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
  len = len || 2;
  var zeros = new Array(len).join('0');
  return (zeros + str).slice(-len);
}

export const saveFormMediaSequence = async (values, modeEdit, navigate, brandId, sendPlayConfetti) => {
  let data;

  if (!modeEdit || modeEdit == MODE_EDIT) {
    await setGetStartedDone(getStartedMediaSequence, sendPlayConfetti)
    let res = await executeQueryUtil(getMediaSequenceQuery(brandId, values.id));
    delete values.id;
    delete values.save;
    let data = {
      ...res.data?.getMediaSequence,
      ...values
    };
    await executeMutationUtil(updateMediaSequenceMutation(brandId, data));
    return data;
  }

  else if (modeEdit == MODE_CREATE){
    data = {...values};
    const added = await executeMutationUtil(createMediaSequenceMutation(brandId, data));
    await addDataMediaSequenceByBrandIdToCache(brandId, [added.data?.addMediaSequence]);
    navigate("/" + dashboardBaseRoute + "/mediaSequences/detail/" + added.data?.addMediaSequence?.id);
    return data;
  }
}

export const googlePresentationType = 'application/vnd.google-apps.presentation';
export const pptPresentationType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';

export const split_type_horizontal = "horizontal";
export const split_type_vertical = "vertical";

const MediaSequenceCreateUpdateForm = ({className, modeEdit}) => {
  const [triggerSave, setTriggerSave] = useState(false);
  const [selectColorOpen, setSelectColorOpen] = useState(false);

  const {addError} = useAppUtil();

  let {mediaSequenceId} = useParams();
  const { currentBrand, sendPlayConfetti } = useAuth();
  const navigate = useNavigate();
  const [mediaSequenceItem, setMediaSequenceItem] = useState(null);

  function processTriggerSave() {
    setTriggerSave(!triggerSave);
  }

  const getMediaSequence = async () => {
    if (modeEdit == MODE_CREATE) {
      return null;
    }
    if (modeEdit == MODE_EDIT) {
      var res =  await executeQueryUtil(getMediaSequenceQuery(currentBrand().id, mediaSequenceId));
      if (!res.data?.getMediaSequence) {
        navigate('/' + not_found_path);
        return;
      }
      return res;
    }
    return null;
  }

  const buildFormValues = (item) => {
    return {
      initialValues: {
        id: item.id || '',
        name: item.name || '',
        backgroundColor: item.backgroundColor || '#ffffff',
      },
      validationSchema: {
        name: Yup.string().max(255).required(localStrings.check.fieldRequired),
      }
    };
  }

  const getTableCell = (item) => {
    return(
      <React.Fragment>
        <TableCell>
          {item.name}
        </TableCell>
        <TableCell>
          {/*<img src={"https://drive.google.com/thumbnail?sz=w640&id=" + (item.googleDocId || item.id)} style={{width:'200px'}}/>*/}
          <img src={item.urlThumbNail}  style={{width:'20px'}}/>
          {/*{item.thumbnailLink}*/}
        </TableCell>
        <TableCell>
          { moment.utc(moment.duration(item.duration,"s").asMilliseconds()).format("HH:mm:ss")}
        </TableCell>
      </React.Fragment>
    )
  }

  const getTableCellColumn = () => {
    return(
      <React.Fragment>
        {/*<TableCell>*/}
        {/*  {localStrings.name}*/}
        {/*</TableCell>*/}
        <TableCell>
          {localStrings.name}
        </TableCell>
        <TableCell>
          {localStrings.thumbnailImage}
        </TableCell>
        <TableCell>
          {localStrings.duration}
        </TableCell>
        <TableCell>
          {localStrings.actions}
        </TableCell>

      </React.Fragment>
    )
  }

  const dragEnd = (result) => {
    console.log(result)

    const sourceId = result.draggableId;
    if (!result.destination) {
      return;
    }
    const destinationIndex = result.destination.index;

    let sequencesClone = cloneDeep(mediaSequenceItem.sequences);

    const movedItem = cloneDeep(sequencesClone.find(item => item.id === sourceId));
    const oldIndex = sequencesClone.findIndex(item => item.id == movedItem.id);

    if (destinationIndex === oldIndex) {
      return;
    }
    sequencesClone.splice(oldIndex, 1);
    sequencesClone.splice(Math.max(destinationIndex, 0), 0, movedItem);

    setMediaSequenceItem( cloneDeep({
      ...mediaSequenceItem,
      sequences: sequencesClone
    }))
  }

  async function checkFileShared(file) {
    if (!file.id) {
      return true;
    }
    const url = config.googleDocServiceUrl + "/filterFilesShared/";
    try {
      const res = await axios.post(url, {
        files: [file]
      },{
        headers: {
          'Content-Type': 'application/json',
        }
      })
      console.log(JSON.stringify(res?.data || {}, null, 2))
      const filtered = res?.data;
      return filtered && res?.data.length > 0;
    }
    catch (error) {
      console.log(error);
    }
    return false;
  }

  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {
    return (

      <Grid
        container
        spacing={4}
      >
        {modeEdit == MODE_EDIT &&
          <>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextFieldWithCopy
                inputProps={{
                  autocomplete: "new-password"
                }}
                error={Boolean(touched.id && errors.id)}
                fullWidth
                helperText={touched.id && errors.id}
                label={localStrings.mediaSequenceId}
                name="id"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
                value={values.id}
              />
            </Grid>
          </>
        }

        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            style={{marginBottom: 10}}
            inputProps={{
              autocomplete: "new-password"
            }}
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            label={localStrings.name}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.name}

          />
        </Grid>

        {modeEdit == MODE_EDIT &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              style={{marginBottom: 10}}
              inputProps={{
                autocomplete: "new-password"
              }}
              disabled={true}
              error={Boolean(touched.backgroundColor && errors.backgroundColor)}
              fullWidth
              helperText={touched.backgroundColor && errors.backgroundColor}
              label={localStrings.backgroundColor}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.backgroundColor}
              InputProps={{
                endAdornment:
                  <InputAdornment position="end">
                    <Tooltip title={localStrings.changeBackgroundColor}>
                      <IconButton
                        onClick={() => setSelectColorOpen(true)}
                        edge="end"
                        sx={{
                          border: '2px solid',
                          borderColor: invertColor(values.backgroundColor)
                        }}
                      >
                        <PaletteIcon sx={{
                          color: values.backgroundColor,
                        }}/>
                      </IconButton>
                    </Tooltip>
                  </InputAdornment>
              }}
            />
          </Grid>
        }

        <Dialog open={selectColorOpen}>

          <Stack
            alignItems="center"
            direction="row"
            justifyContent="space-between"
            spacing={3}
            sx={{
              px: 3,
              py: 2
            }}
          >
            <Typography variant="h6">
              {localStrings.backgroundColor}
            </Typography>
            <Box>

            </Box>
            <IconButton
              color="inherit"
              onClick={() => setSelectColorOpen(false)}
            >
              <SvgIcon>
                <XIcon />
              </SvgIcon>
            </IconButton>
          </Stack>

          <DialogContent>
            {/*<FormLabel id="demo-radio-buttons-group-label" sx={{}}>{localStrings.backgroundColor}</FormLabel>*/}
            <SketchPicker
              color={ values.backgroundColor }
              onChangeComplete={ (color) => {
                setFieldValue('backgroundColor', color.hex)
                setSelectColorOpen(false);
              }}
            />
          </DialogContent>
        </Dialog>

      </Grid>

    );
  }

  return (
    <>
      <GeneralEditor
        //triggerSave={triggerSave}
        //itemSource={mediaSequenceItem}
        getItemFunction={() => getMediaSequence()}
        className={className}
        buildFormValues={buildFormValues}
        getCardContentForm={getCardContentForm}
        saveForm={(item, values) => saveFormMediaSequence(values, modeEdit, navigate,
          currentBrand().id, sendPlayConfetti)}
        applyButton={modeEdit == MODE_CREATE ? localStrings.create : localStrings.update}
        resetFormProp={modeEdit == MODE_CREATE}
        messageSuccess={modeEdit == MODE_CREATE ?
          localStrings.notif.mediaSequenceAdded : localStrings.notif.mediaSequenceUpdated}
        titleForm={modeEdit == MODE_CREATE ?
          localStrings.addMediaSequence : localStrings.editMediaSequence}
        getPathToData={(result) => {
          return result.data.getMediaSequence;
        }}
      >
      </GeneralEditor>


    </>
  )
};

export default MediaSequenceCreateUpdateForm;
