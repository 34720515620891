import {executeQueryUtil} from "../../utils/gqlUtil";
import {getMediaSequenceQuery} from "../../gql/mediaSequenceGql";
import cloneDeep from "clone-deep";
import {getScheduleQuery} from "../../gql/scheduleGql";
import {getAllScheduleItemsByBrandIdQuery} from "../../gql/scheduleItemGql";

export const mediaSequencePrefix = "mediaSequence_";
export const schedulePrefix = "schedule_";
export const scheduleItemsByBrandIdPrefix = "scheduleItemsByBrandIdPrefix_";
export const getMediaSequenceWithLocalStorage = async (brandId, sequenceId) => {
  //localStorage.clear();
  if (localStorage.getItem(mediaSequencePrefix + sequenceId) != null && localStorage.getItem(mediaSequencePrefix + sequenceId) != undefined) {
    try {
      const ret = JSON.parse(localStorage.getItem(mediaSequencePrefix + sequenceId));
      return ret;
    }
    catch (error) {
      console.log(error);
    }

  }

  const mediaSequenceRes = await executeQueryUtil(getMediaSequenceQuery(brandId, sequenceId));
  localStorage.setItem(mediaSequencePrefix + sequenceId, JSON.stringify(mediaSequenceRes?.data?.getMediaSequence));
  return mediaSequenceRes?.data?.getMediaSequence;
}

export const getScheduleWithLocalStorage = async (brandId, scheduleId) => {
  const scheduleRes = await executeQueryUtil(getScheduleQuery(brandId, scheduleId));
  return scheduleRes?.data?.getSchedule;
}


export const getAllScheduleItemsByBrandIdLocalStorage = async (brandId, scheduleId) => {
  if (localStorage.getItem(scheduleItemsByBrandIdPrefix + scheduleId) != null && localStorage.getItem(scheduleItemsByBrandIdPrefix + scheduleId) != undefined) {
    try {
      let ret = JSON.parse(localStorage.getItem(scheduleItemsByBrandIdPrefix + scheduleId));
      return ret;
    }
    catch (error) {
      console.log(error);
    }
  }

  const schedulesRes = await executeQueryUtil(getAllScheduleItemsByBrandIdQuery(brandId, scheduleId))
  localStorage.setItem(scheduleItemsByBrandIdPrefix + scheduleId, JSON.stringify(schedulesRes?.data?.getScheduleItemsByBrandId));
  return schedulesRes?.data?.getScheduleItemsByBrandId;
}
