const mimeTypeNotSupported = `
<h1>File is not supported</h1>

<p>Only image or video are supported !!
</p>
`

module.exports = {
  mimeTypeNotSupported: mimeTypeNotSupported,
}
