export const BRAND_COLLECTION = 'brands';
export const SCREEN_COLLECTION = 'screen';
export const TEMP_SCREEN_COLLECTION = 'tempScreen';
export const DEVICE_COLLECTION = 'devices';
export const DISPLAY_EVENTS_COLLECTION = 'displayEvents';
export const DISPLAY_MESSAGES_COLLECTION = 'displayMessages';
export const DEVICE_HEALTH_COLLECTION = 'deviceHealth';
export const PRODUCT_USER_COLLECTION = 'productUsers';
export const TEMP_BRAND_USER_COLLECTION = 'tempBrandUsers';
export const LAYOUT_DATA_COLLECTION = 'layoutDatas';
export const WIDGET_DATA_COLLECTION = 'widgetDatas';
export const MEDIA_SEQUENCE_COLLECTION = 'mediaSequences';
export const SCHEDULE_COLLECTION = 'schedules';

