const fileNotShared = `
<h1>File is not shared or bad link provided</h1>

<p>To be able to display your file on the screen, the document needs to be shared
</p>
<br/>
<p>Connect to you google drive and follow the instructions:</p>
<br/>

<strong>1. Select the file you want to share.</strong>
<br/>
<strong>2. Click Share or Share</strong>
<br/>
<strong>3. Choose Anyone with the link.</strong>
<br/>
<strong>4. Click on Copy link</strong>
<br/>
<br/>
<p>Then paste the link in the form !!</p>
`

module.exports = {
  fileNotShared: fileNotShared,
}
