const SplitVertical = (props) => (
  //<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
  <svg fill="currentColor" width={24}
       height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M10.5 5.5V19.5M13 12.5H15.5M18 12.5H15.5M15.5 12.5V15M15.5 12.5V10M4.5 5.5H20.5V19.5H4.5V5.5Z" stroke="#121923" stroke-width="1.2"/>
  </svg>

);

export default SplitVertical;
