import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";
import { nanoid } from 'nanoid'

const common = `
  id
  name
  brandId
  inputId
  displayType
  screenInputId
  lastAliveCheck
`


export const getScreenQuery = (brandId, screenId) => {
  var debug = `
    query {
      getScreen(brandId: "${brandId}", screenId: "${screenId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getScreen(brandId: "${brandId}", screenId: "${screenId}") {
        ${common}
      }
    }
  `;
}




export const getAllScreensByBrandIdQuery = (brandId) => {
  var debug = `
    query {
      getScreensByBrandId(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getScreensByBrandId(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}



export const updateLiveCheckMutation = (brandId, screenId) => {
  var debug = `
    mutation {
      updateScreenLiveCheck(brandId: "${brandId}", screenId: "${screenId}") {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateScreenLiveCheck(brandId: "${brandId}", screenId: "${screenId}") {
        ${common}
      }
  }
  `;
}

export const reloadScreenMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"], ["displayType"]);

  var debug = `
    mutation {
      reloadScreen(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    reloadScreen(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const updateScreenMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"], ["displayType"]);

  var debug = `
    mutation {
      updateScreen(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateScreen(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const createScreenMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate", "createdAt"],
      [] );
  data.nanoId = nanoid(8);

  var debug = `
    mutation {
      addScreen(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      addScreen(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const bulkDeleteScreenMutation = (brandId, ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkDeleteScreen(brandId: "${brandId}", screensIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkDeleteScreen(brandId: "${brandId}", screensIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const deleteScreenByBrandIdCache = async (brandId, ids) => {
  const query = getAllScreensByBrandIdQuery(brandId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getScreensByBrandId.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getScreensByBrandId: [...newData]
      },
    });
  }
}



export const addDataScreenByBrandIdToCache = async (brandId,datas) => {
  const query = getAllScreensByBrandIdQuery(brandId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getScreensByBrandId: [...data.data.getScreensByBrandId, ...datas]
      },
    });
  }
}

