const Schedule = (props) => (
  //<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
  <svg fill="currentColor" width={24}
       height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <g id="schedule-play">
      <g>
        <path d="M17.5,24c-2.7,0-5-1.7-6-4H0V2h4V0h2v2h6V0h2v2h4v9c3.4,0.3,6,3.1,6,6.5C24,21.1,21.1,24,17.5,24z M17.5,13
			C15,13,13,15,13,17.5s2,4.5,4.5,4.5s4.5-2,4.5-4.5S20,13,17.5,13z M2,18h9c0-0.2,0-0.3,0-0.5c0-0.5,0.1-1,0.2-1.5H8v-2h4
			c0.9-1.4,2.3-2.5,4-2.9V4H2v2h14v2H2V18z M6,16H4v-2h2V16z M14,12H8v-2h6V12z M6,12H4v-2h2V12z"/>
      </g>
      <g>
        <path d="M16,14.5c0.1,0,0,6,0,6l5-3L16,14.5z"/>
      </g>
    </g>
  </svg>

);

export default Schedule;
