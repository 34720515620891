import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import firebaseConfig from 'src/config';
import { getStorage, ref, uploadBytes, getDownloadURL, deleteObject, listAll } from "firebase/storage";
import { getFirestore, collection, onSnapshot, doc, getDoc, getDocs, where, query } from "firebase/firestore";
const app = initializeApp(firebaseConfig);
//firebase.analytics();
const storage = getStorage(app);
const auth = getAuth(app);
export {
  storage,
  getStorage,
  listAll,
  deleteObject,
  uploadBytes,
  ref,
  doc,
  getDoc,
  query,
  where,
  getDocs,
  getDownloadURL,
  getFirestore,
  onSnapshot,
  collection,
  signInWithPopup, auth as default
};


