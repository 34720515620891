const getStartedLayoutContent = `

<p>Use layouts to easily create some content</p>
<br/>
<p>See <a href="https://unlayer.com/" target="new">here </a> for more detail about the way to use the unlayer web page editor</p>
<br/>

<strong>1. Create a new blank layout or use a template</strong>
<br/>
<strong>2. Customize your content and click on update</strong>
<br/>
<strong>3. Your layer can be used in media sequence</strong>
<br/>


`
module.exports = {
  getStartedLayoutContent: getStartedLayoutContent,
}
