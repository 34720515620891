import {gql} from "@apollo/client";
import filterDataGql from "../utils/gqlUtil";

const common = `
  id
  brandId
  screenId
`
export const createTempScreenMutation = () => {

  var debug = `
    mutation {
      addTempScreen {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      addTempScreen {
        ${common}
      }
  }
  `;
}

export const updateTempScreenMutation = (data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"]);

  var debug = `
    mutation {
      updateTempScreen(data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateTempScreen(data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const getTempScreenQuery = (tempScreenId) => {
  var debug = `
    query {
      getTempScreen(tempScreenId: "${tempScreenId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getTempScreen(tempScreenId: "${tempScreenId}") {
        ${common}
      }
    }
  `;
}


export const bulkDeleteTempScreenMutation = (ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkDeleteTempScreen(tempScreenIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
   mutation {
      bulkDeleteTempScreen(tempScreenIds: ${idsString}) {
        ids
      }
    }
  `;
}


