import React, {useEffect, useState} from 'react';
import Logo from "../components/Logo";
import { Alert, Box, useMediaQuery } from '@mui/material';
import {executeMutationUtil} from "../utils/gqlUtil";
import {createTempScreenMutation} from "../gql/tempScreenGql";
import useAuth from "../hooks/useAuth";
import {useDocumentData} from "react-firebase-hooks/firestore";
import {getFirestore, query, where} from "firebase/firestore";
import {TEMP_SCREEN_COLLECTION} from "../utils/constants";
import {doc} from "../lib/firebase";
import {useNavigate} from "react-router";
import {importScreenInApp} from "../htmlContents/importScreenInApp";
import localStrings from "../localStrings";
import ClipLoaderComponent from "../components/ClipLoaderComponent";

const screenNanoId = "screenNanoId";
const paired = "paired";

function ScreenConnect({}) {
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const navigate = useNavigate();
  const [nanoId, setNanoId] = useState(null)
  const [initialLoadDone, setInitialLoadDone] = useState(false)
  //const [tempScreen, setTempScreen] = useState(null)
  const {setDisplaySettingButton} = useAuth();

  const db = getFirestore();

  const refTempScreen = doc(db, TEMP_SCREEN_COLLECTION, localStorage.getItem(screenNanoId) || "-1");
  const [tempScreen, tempScreenLoading, tempScreenError] = useDocumentData(refTempScreen, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  useEffect(() =>{
    if (!tempScreenLoading) {
      setInitialLoadDone(true);
    }
  }, [tempScreenLoading])

  useEffect(() =>{
    if (tempScreen?.brandId && tempScreen?.screenId) {
      localStorage.setItem(paired, "true");
      navigate("/screenDisplay/" + tempScreen.brandId + "/" + tempScreen.screenId)
    }
    if (!tempScreenLoading && tempScreen == null && localStorage.getItem(paired) == "true") {
      localStorage.removeItem(screenNanoId);
      localStorage.setItem(paired, "false");
      window.location.reload();
    }
    //setInitialLoadDone(true);

  }, [tempScreen, tempScreenLoading])


  useEffect(() =>{
    setDisplaySettingButton(false);
    const loadData = async () => {
      const res = await executeMutationUtil(createTempScreenMutation());
      setNanoId(res.data?.addTempScreen?.id)
      localStorage.setItem(screenNanoId, res.data?.addTempScreen?.id);
      //setInitialLoadDone(true);
    }
    if (localStorage.getItem(screenNanoId) == null) {
      loadData();
    }
    else {
      let nanoId = localStorage.getItem(screenNanoId);
      setNanoId(nanoId);
      //setInitialLoadDone(true);
    }
  }, [])

  return (
    <div style={{width: '100%'}}>
      {initialLoadDone ?
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column'
          }}
        >
          <Box>
            <Logo heightlogo={100}/>
          </Box>
          {nanoId &&
            <Box>
              <h1>{localStrings.formatString(localStrings.yourCode, nanoId)}</h1>
            </Box>
          }
          <Box>

          </Box>
          <Box>
            <Alert severity={"info"}>
              <strong>{localStrings.infoMessage.addScreenCastReactorApp}</strong>
              <div style={{width: !lgUp ? '380px': '100%'}}
                   dangerouslySetInnerHTML={{__html: importScreenInApp}}
              />

            </Alert>
          </Box>

          {lgUp &&
            <>
              <Box>
                <strong>{localStrings.scanThisQRCOde}</strong>
              </Box>
              <Box>
                <img src="/static/images/dashboard.castreactor.com.png" width="250px"/>
              </Box>
            </>
          }
        </Box>
        :
        <ClipLoaderComponent/>
      }

      {/*<p>{JSON.stringify(tempScreen || {})}</p>*/}
    </div>
  );
}

export default ScreenConnect;
