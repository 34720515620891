import React from 'react';
import {Alert, Box} from "@mui/material";
import Logo from "./Logo";
import localStrings from "../localStrings";
import {importScreenInApp} from "../htmlContents/importScreenInApp";
import ClipLoaderComponent from "./ClipLoaderComponent";

function NoCreditCompo(props) {
  return (
    <div style={{width: '100%'}}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          flexDirection: 'column'
        }}
      >
        <Box>
          <Logo heightlogo={100}/>
        </Box>
          <Box>
            <h1>{localStrings.noCreditMessageOne}</h1>
          </Box>
        <Box>

        </Box>

      </Box>
    </div>
  );
}

export default NoCreditCompo;
