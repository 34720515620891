const getStartedHelp = `


<div style="text-align:center;">
<h1>Welcome to Cast reactor</h1>
<img
      style="height: 70px;"  
      src="/static/images/castReactorLogo.png"
/>
  <p>Follow the guide to get started with Cast reactor
</p>  
</div>
`
module.exports = {
  getStartedHelp: getStartedHelp,
}
