import React, {useEffect, useState} from 'react';
import {TableCell} from "@mui/material";
import localStrings from "../../../localStrings";
import useAuth from "../../../hooks/useAuth";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import GeneralResult from "../../../components/CrudFrom/GeneralResult";
import {useNavigate} from "react-router";
import {updateHealthCheckMutation} from "../../../gql/BrandGql";
import {BRAND_COLLECTION, DEVICE_HEALTH_COLLECTION} from "../../../utils/constants";
import {collection, getFirestore} from "firebase/firestore";
import {useCollectionData} from "react-firebase-hooks/firestore";
import moment from "moment";
import {
  bulkDeleteWidgetMutation,
  deleteWidgetByBrandIdCache,
  getAllWidgetsByBrandIdQuery
} from "../../../gql/widgetGql";
import {getAllMediaSequencesByBrandIdQuery} from "../../../gql/mediaSequenceGql";
import useAppUtil from "../../../hooks/useAppUtil";

const timeoutRefreshStatus = 60000;

export const getWidgets = async (currentBrand) => {
  return await executeQueryUtil(getAllWidgetsByBrandIdQuery(currentBrand().id));
}

const Results = ({className}) => {
  const {addError} = useAppUtil();
  const {currentBrand} = useAuth();
  const db = getFirestore();

  const refCollection =  collection(db, BRAND_COLLECTION
    + "/" + currentBrand().id + "/" + DEVICE_HEALTH_COLLECTION);

  const [devicesHealth, devicesHealthLoading, devicesHealthError] = useCollectionData(
    refCollection
    , {
      snapshotListenOptions: { includeMetadataChanges: true },
    });

  const [itemSource, setItemSource] = useState({});
  const [projects, setProjects] = useState([]);
  const navigate = useNavigate()
  useEffect(() => {
    async function setItemSourceAsync() {
      let result = await getWidgets(currentBrand);
      setItemSource(result);
      // let resultProjects = await getProjects();
      // setProjects(resultProjects?.data?.getProjects || []);
    }
    setItemSourceAsync();
  }, [currentBrand]);

  async function getHealthStatus() {
    await executeMutationUtil(updateHealthCheckMutation(currentBrand().id));
  }

  useEffect(() => {
    getHealthStatus();
    // const interval = setInterval(() => {
    //   getHealthStatus();
    // }, timeoutRefreshStatus);
    // return () => clearInterval(interval);
  }, []);

  // const getProjects = async () => {
  //   return await executeQueryUtil(getAllProjectsQuery(currentBrand().id));
  // }



  const getTableCellColumn = () => {
    return(
      <React.Fragment>
        <TableCell>
          {localStrings.Id}
        </TableCell>
        <TableCell>
          {localStrings.name}
        </TableCell>
        <TableCell>
          {localStrings.actions}
        </TableCell>
      </React.Fragment>
    )
  }

  function getStatusColor(item) {
    var alive = item.alive
    const nowMs = moment().valueOf()
    //let deviceHealthMatching = devicesHealth && devicesHealth.find(h => h.id === item.id && nowMs - h.updateDate > 2000);
    let deviceHealthMatching = devicesHealth && devicesHealth.find(h => h.id === item.id);
    if (deviceHealthMatching) {
      alive = deviceHealthMatching.alive;
    }
    if (alive) {
      return 'green';
    }
    return 'red';
  }

  const getTableCell = (item) => {

    return(
      <React.Fragment>
        <TableCell>
          {item.id}
        </TableCell>
        <TableCell>
          {item.name}
        </TableCell>
        {/*<TableCell>*/}
        {/*  <CheckCircleOutlineIcon style={{ fill: getStatusColor(item) }}/>*/}
        {/*</TableCell>*/}
        {/*<TableCell>*/}
        {/*  {item.lite &&*/}
        {/*    <DoneIcon/>*/}
        {/*  }*/}
        {/*</TableCell>*/}
        {/*<TableCell>*/}
        {/*  {projects.find(p => p.id === item.projectId)?.name || ""}*/}
        {/*</TableCell>*/}
      </React.Fragment>
    )
  }

  const removeWidgets = async (ids, size) => {

      const resMediaSequences = await executeQueryUtil(getAllMediaSequencesByBrandIdQuery(currentBrand().id))
      const mediaSequences = resMediaSequences?.data?.getMediaSequencesByBrandId || [];
      let referencedWidgetIds = [];
      let mediaSequenceNames = [];
      mediaSequences.forEach(mediaSequence => {
        //console.log(JSON.stringify(mediaSequence))
        const sequences = mediaSequence.sequences || [];
        sequences.forEach(sequence => {
          if (ids.includes(sequence.widgetId)) {
            referencedWidgetIds.push(sequence.widgetId)
            mediaSequenceNames.push(mediaSequence.name)
          }
          if (sequence.secondSequence?.widgetId && ids.includes(sequence.secondSequence.widgetId)) {
            referencedWidgetIds.push(sequence.widgetId)
            mediaSequenceNames.push(mediaSequence.name)
          }
        });

      })

      if (referencedWidgetIds.length > 0) {
        addError(localStrings
          .formatString(localStrings.warningMessage.widgetReferencedInMediaSequence, [...new Set(mediaSequenceNames)].toString()));
        return;
      }

      await executeMutationUtil(bulkDeleteWidgetMutation(currentBrand().id, ids));
      await deleteWidgetByBrandIdCache(currentBrand().id, ids);
      let itemUpdate = await getWidgets(currentBrand);
      setItemSource(itemUpdate)
      return true;
  }


  const sortOptions = [
    {
      value: 'name|asc',
      label: localStrings.name
    },
  ];

  return (
    <>
      { Object.keys(itemSource).length > 0 ?
        <GeneralResult
          className={className}
          getPathToData={(result) => result.data.getWidgetsByBrandId}
          getTableCell={getTableCell}
          getTableCellColumn={getTableCellColumn}
          getEditLink={(item) => "/dashboard/widgets/detail/" + item.id}
          deleteDialogText={localStrings.confirmMessage.deleteWidget}
          deleteItemsFunction={removeWidgets}
          deleteItemNotifMessage={localStrings.notif.widgetDeleted}
          sortOptions={sortOptions}
          searchProperties={['name']}
          itemsSource={itemSource}
        >
        </GeneralResult>
        :
        <ClipLoaderComponent/>
      }
    </>
  )

};


export default Results;
