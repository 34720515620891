const Layout = (props) => (
  //<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
  <svg fill="currentColor" width={24}
       height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
    <path d="M2 5C2 3.34315 3.34315 2 5 2H19C20.6569 2 22 3.34315 22 5V8L9 8H2V5Z"/>
    <path d="M2 10V19C2 20.6569 3.34315 22 5 22H8V10L2 10Z"/>
    <path d="M10 22H19C20.6569 22 22 20.6569 22 19V10L10 10V22Z"/>
  </svg>

);

export default Layout;
