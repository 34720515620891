import React from 'react';
import {IconButton, InputAdornment, OutlinedInput, TextField, Tooltip} from "@mui/material";
import FileCopyIcon from '@mui/icons-material/FileCopy';
import localStrings from "../localStrings";

function TextFieldWithCopy({value, ...rest}) {
  return (
    <TextField
      value={value}
      {...rest}
      InputProps={{
        endAdornment:
          <InputAdornment position="end">
            <Tooltip title={localStrings.copyToClipboard}>
              <IconButton
                onClick={() => navigator.clipboard.writeText(value)}
                edge="end"
              >
                <FileCopyIcon/>
              </IconButton>
            </Tooltip>
          </InputAdornment>
      }}
    />
  );
}

export default TextFieldWithCopy;
