import React, {useCallback, useEffect, useImperativeHandle, useRef, useState} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  FormHelperText,
  Grid,
} from "@mui/material";
import useIsMountedRef from '../../hooks/useIsMountedRef';
import useAppUtil from '../../hooks/useAppUtil';
import { Formik } from 'formik';
import * as Yup from 'yup';
import useSound from 'use-sound';
import useSettings from '../../hooks/useSettings';
import enqueueSnackbarWithSound from '../../utils/SnackBarWithSound';
import ClipLoaderComponent from '../ClipLoaderComponent';
import {makeStyles} from "@mui/styles";
import { useSnackbar } from 'notistack';
import toast from "react-hot-toast";
import {LoadingButton} from "@mui/lab";
import SendIcon from '@mui/icons-material/Send';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';

const config = require("../../conf/config.json");

export const MODE_EDIT = 1;
export const MODE_CREATE = 2;

const GeneralEditor = ({ getItemFunction,
                         itemSource,
                         externalLoading,
                         buildFormValues,
                         getCardContentForm,
                         saveForm,
                         messageSuccess,
                         applyButton,
                         titleForm,
                         getPathToData,
                         children,
                         resetFormProp,
                         componentTitle,
                         noCard,
                         displaySaveButton,
                         hideApplyButton,
                         triggerSave
                       }) => {

  const refSubmitBut = useRef();
  const formRef = useRef()

  useEffect(() => {
    if (formRef.current && triggerSave != undefined) {
      formRef.current.handleSubmit()
    }
  }, [triggerSave, formRef]);

  const [item, setItem] = useState({});
  const { playSoundIfActive } = useSettings()
  const [dataLoaded, setDataLoaded] = useState(false);

  const [playDone] = useSound(
    config.sound.done,
    { volume: 0.5 }
  );
  const isMountedRef = useIsMountedRef();
  const getItem = useCallback(async () => {
    try {
      var result = itemSource ? itemSource : await getItemFunction();
      if (isMountedRef.current && result) {
        let pathToData = getPathToData(result);
        if (pathToData) {
          setItem(pathToData);
        }
        else
        {
          setItem({});
        }

        setDataLoaded(true);
      }
      if (result == null) {
        setDataLoaded(true);
      }
    } catch (err) {
      console.log(err);
      addError(err.message);
      setItem({});
    }
  }, [isMountedRef, itemSource]);
  useEffect(() => {
    getItem();
  }, []);

  const { addError } = useAppUtil();
  const { enqueueSnackbar } = useSnackbar();
  const formValues = buildFormValues(item);

  return (
    <div>
      <Formik
        innerRef={formRef}
        enableReinitialize
        initialValues={formValues.initialValues}
        validationSchema={Yup.object().shape(
          formValues.validationSchema,
        )}

        onSubmit={async (values, {
          resetForm,
          setErrors,
          setStatus,
          setSubmitting,
        }) => {
          try {
            setSubmitting(true);
            setDataLoaded(false);

            let dataUpdated = await saveForm(item, values);
            setItem(dataUpdated || {});
            setDataLoaded(true);
            setStatus({success: true});
            setSubmitting(false);
            if (messageSuccess) {
              enqueueSnackbarWithSound(toast, playDone, playSoundIfActive, messageSuccess);
            }
            if (resetFormProp) {
              resetForm();
            }
          } catch (err) {
            setDataLoaded(true);
            console.error(err);
            setStatus({success: false});
            setErrors({submit: err.message});
            setSubmitting(false);
            console.log(err);
            addError(err.message)
          }
        }}
      >
        {({
            errors,
            handleBlur,
            handleChange,
            handleSubmit,
            isSubmitting,
            setFieldValue,
            touched,
            values
          }) => (
          // <form onSubmit={handleSubmit}>
          <form onSubmit={handleSubmit} id="formGeneralEditor">
            {!noCard ?
              <Card
                //className={clsx(classes.root)}
              >
                <Grid
                  container
                  spacing={4}
                >
                  <Grid
                    item
                    md={6}
                    xs={12}
                  >
                    {titleForm && <CardHeader title={titleForm} />}
                  </Grid>

                  {componentTitle &&
                    <Grid
                      item
                      md={6}
                      xs={12}
                    >
                      {componentTitle()}

                    </Grid>
                  }
                </Grid>

                <Divider />
                {item &&
                  <CardContent>
                    {getCardContentForm({
                      errors,
                      handleBlur,
                      handleChange,
                      handleSubmit,
                      isSubmitting,
                      setFieldValue,
                      touched,
                      values
                    })}
                    {errors.submit && (
                      <Box mt={3}>
                        <FormHelperText error>
                          {errors.submit}
                        </FormHelperText>
                      </Box>
                    )}
                  </CardContent>
                }
                <Divider />
                <Box
                  p={2}
                  display="flex"
                  justifyContent="flex-end"
                  alignContent="space-around"
                >

                  {/*<LoadingButton*/}
                  {/*  //onClick={handleClick}*/}
                  {/*  endIcon={<SendIcon />}*/}
                  {/*  loading={true}*/}
                  {/*  loadingPosition="end"*/}
                  {/*  variant="contained"*/}
                  {/*>*/}
                  {/*  <span>Send   </span>*/}
                  {/*</LoadingButton>*/}

                  {children}
                  {!hideApplyButton &&
                    <LoadingButton
                      endIcon={<ArrowForwardIcon />}
                      loading={!dataLoaded || externalLoading}
                      loadingPosition="end"
                      //ref={refSubmitBut}
                      color="primary"
                      //disabled={isSubmitting}
                      type="submit"
                      variant="contained"
                      style={{ marginLeft: "2px" }}
                      inputProps={{ref: refSubmitBut}}
                    >
                      <span>{applyButton}</span>
                    </LoadingButton>
                  }
                </Box>
              </Card>
              :
              <>
                {getCardContentForm({
                  errors,
                  handleBlur,
                  handleChange,
                  handleSubmit,
                  isSubmitting,
                  setFieldValue,
                  touched,
                  values
                })}
                {displaySaveButton &&
                  <Box
                    p={2}
                    display="flex"
                    justifyContent="flex-end"
                    alignContent="space-around"
                  >
                    {children}

                  </Box>
                }
              </>
            }
          </form>
        )}
      </Formik>

    </div>
  );
}

export default GeneralEditor;
