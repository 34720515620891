const getStartedAddMediaContent = `
<p>Use media list to upload your media from your computer and use it later in other cast reactor components</p>
<br/>

<strong>1. Click on "upload media" button</strong>
<br/>
<strong>2. Select a media from your file system or drag and drop it</strong>
<br/>
<strong>3. Click on upload</strong>
<br/>
`
module.exports = {
  getStartedAddMediaContent: getStartedAddMediaContent,
}
