import React, {useState} from 'react';
import {
  Box,
  Button,
  Card,
  Dialog, DialogActions, DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Slider,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from "@mui/material";
import moment from "moment";
import DeleteIcon from '@mui/icons-material/Delete';
import localStrings from "../../../localStrings";
import EditIcon from '@mui/icons-material/Edit';
import {default_sequence_duration} from "../../ScreenDisplay/screenDisplay";
import {isPresentation, isSequenceDurationDefined} from "./DrawerSequenceEditor";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import SplitVertical from "../../../icons/untitled-ui/duocolor/splitVertical";
import SplitHorizontal from "../../../icons/untitled-ui/duocolor/splitHorizontal";
import CropSquareIcon from '@mui/icons-material/CropSquare';
import {split_type_horizontal, split_type_vertical} from "../MediaSequenceAddView/MediaSequenceCreateUpdateForm";
import SplitVerticalResize from "../../../icons/untitled-ui/duocolor/splitVerticalResize";
import SplitHorizontalResize from "../../../icons/untitled-ui/duocolor/splitHorizontalResize";
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import Screen from "../../../icons/untitled-ui/duocolor/screen";
import SwapHorizIcon from '@mui/icons-material/SwapHoriz';
import {sequenceBoardType, sequenceLayoutType, sequenceWidgetType} from "../../../utils/ItemSequenceSelector";
import HideSourceIcon from '@mui/icons-material/HideSource';
import MotionPhotosOffIcon from '@mui/icons-material/MotionPhotosOff';

const MAX_LENGTH = 25;
function truncate(str, size){
  return (str.length > (size || MAX_LENGTH)) ? str.slice(0, (size || MAX_LENGTH)-1) + '...' : str;
};

function formatDuration(mediaSequence) {
  if (isSequenceDurationDefined(mediaSequence) && !mediaSequence.duration) {
    return localStrings.fullDurationVideo;
  }
  let durationString = moment.utc((mediaSequence.duration || default_sequence_duration) * 1000).format('HH:mm:ss');
  // if (isPresentation(mediaSequence.mimeType)) {
  //   durationString += ' ' + localStrings.perSlide;
  // }
  return durationString;
}

function DialogCOntent() {
  return null;
}

function SequenceCard({mediaSequence, dragging,
                        onOpen, onDelete,
                        onView,
                        onHide,
                        undoSplit, splitVertically, splitHorizontally,
                        addSequenceMain,
                        editMainSequence,
                        editSecondSequence,
                        addSequenceSecond,
                        setSplitPercent,
                        duplicate,
                        ...other}) {
  const [displayScreenLayout, setDisplayScreenLayout] = useState(!mediaSequence.type)
  const [displaySplitPercent, setDisplaySplitPercent] = useState(false)
  const [sliderSplitValue, setSliderSplitValue] = useState(mediaSequence.splitPercent || 50)

  const handleChangeSlider = (event, newValue) => {
    setSliderSplitValue(newValue);
  };

  function mediaSequenceEditable(mediaSequence) {
    //return true;
    if (!mediaSequence?.type) {
      return false;
    }
    if (mediaSequence.type === sequenceLayoutType ||
      mediaSequence.type === sequenceWidgetType ||
      mediaSequence.type == sequenceBoardType
    ) {
      return true;
    }
    return false;
  }

  return (

    <>

      <Dialog open={displaySplitPercent}>

        <DialogTitle>
          <div style={{ width: '100%' }}>
            <Box
              sx={{ display: 'flex', flexDirection: 'row'}}
            >
              <Box sx={{ flexGrow: 1 }}>
                {localStrings.splitScreenAdjust}
              </Box>
              <Box>
                <IconButton onClick={() => setDisplaySplitPercent(false)}>
                  <CloseIcon/>
                </IconButton>
              </Box>
            </Box>
          </div>
        </DialogTitle>

        <DialogContent>
          <Box width={300} mt={'35px'}>
            <Slider
              value={sliderSplitValue}
              onChange={handleChangeSlider}
              aria-label="Small"
              valueLabelDisplay="auto"
            />
          </Box>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => setDisplaySplitPercent(false)} color="primary">
            {localStrings.cancel}
          </Button>
          <Button
            onClick={() => {
              setSplitPercent(mediaSequence.id, sliderSplitValue);
              setDisplaySplitPercent(false);
            }}
            color="primary">
            {localStrings.confirm}
          </Button>
        </DialogActions>
      </Dialog>
      <Card
        elevation={dragging ? 8 : 1}
        //ref={ref}
        sx={{
          marginRight: 1,
          marginLeft: 1,

          backgroundColor: (theme) => theme.palette.mode === 'dark'
            ? 'neutral.800'
            : 'background.paper',
          ...(dragging && {
            backgroundColor: (theme) => theme.palette.mode === 'dark'
              ? 'neutral.800'
              : 'background.paper'
          }),
          p: 3,
          userSelect: 'none',
          '&:hover': {
            backgroundColor: (theme) => theme.palette.mode === 'dark'
              ? 'neutral.700'
              : 'neutral.50'
          },
          '&.MuiPaper-elevation1': {
            boxShadow: 1
          }
        }}
        {...other}>

        <Typography variant="subtitle1">
          {mediaSequence.displayName}
        </Typography>

        <Typography variant="subtitle2">
          {formatDuration(mediaSequence)}
        </Typography>
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={2}
        >
          <Stack
            alignItems="center"
            direction="row-reverse"
            spacing={2}
            sx={{ mt: 2 }}
          >
            {displayScreenLayout &&
              <>
                {mediaSequence.splitType &&
                  <Tooltip title={localStrings.splitScreenAdjust}>
                    <IconButton
                      onClick={() => setDisplaySplitPercent(true)}
                    >
                      {mediaSequence.splitType === split_type_vertical &&
                        <SplitVerticalResize/>
                      }

                      {mediaSequence.splitType === split_type_horizontal &&
                        <SplitHorizontalResize/>
                      }

                    </IconButton>
                  </Tooltip>
                }

                <Tooltip title={localStrings.splitScreenVertically}>
                  <IconButton
                    sx={{
                      color: mediaSequence.splitType === split_type_vertical ? '#6366f1' : 'none'
                    }}
                    onClick={() => splitVertically(mediaSequence.id)}
                  >
                    <SplitVertical/>
                  </IconButton>
                </Tooltip>

                <Tooltip title={localStrings.splitScreenHorizontally}>
                  <IconButton
                    sx={{
                      color: mediaSequence.splitType === split_type_horizontal ? '#6366f1' : 'none'
                    }}
                    onClick={() => splitHorizontally(mediaSequence.id)}
                  >
                    <SplitHorizontal/>
                  </IconButton>
                </Tooltip>

                <Tooltip title={localStrings.splitUndo}>
                  <IconButton
                    sx={{
                      color: !mediaSequence.splitType ? '#6366f1' : 'none'
                    }}
                    onClick={() => undoSplit(mediaSequence.id)}
                  >
                    <CropSquareIcon/>
                  </IconButton>
                </Tooltip>
              </>
            }
            <Tooltip title={mediaSequence.hide ? localStrings.display : localStrings.hide}>
              <IconButton onClick={() => onHide(mediaSequence.id)}>
                <SvgIcon color="action">
                  {mediaSequence.hide ?
                    <MotionPhotosOffIcon/>
                    :
                    <HideSourceIcon />
                  }
                </SvgIcon>
              </IconButton>
            </Tooltip>
            <Tooltip title={localStrings.preview}>
              <IconButton onClick={() => onView(mediaSequence.id)}>
                <SvgIcon color="action">
                  <RemoveRedEyeIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title={localStrings.deleteSequenceToolTip}>
              <IconButton onClick={() => onDelete(mediaSequence)}>
                <SvgIcon color="action">
                  <DeleteIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title={localStrings.editSequenceToolTip}>
              <IconButton onClick={() => onOpen(mediaSequence)}>
                <SvgIcon color="action">
                  <EditIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title={localStrings.duplicate}>
              <IconButton onClick={() => duplicate(mediaSequence)}>
                <SvgIcon color="action">
                  <ContentCopyIcon />
                </SvgIcon>
              </IconButton>
            </Tooltip>

            <Tooltip title={displayScreenLayout? localStrings.hideScreenLayout : localStrings.displayScreenLayout}>
              <IconButton
                sx={{
                  color: displayScreenLayout ? '#6366f1' : 'none'
                }}
                onClick={() => setDisplayScreenLayout(!displayScreenLayout)}>
                <Screen/>
              </IconButton>
            </Tooltip>


          </Stack>

          <Box>
            {mediaSequence?.urlThumbNail &&
              <img src={mediaSequence.urlThumbNail || ""}  style={{width:'20px'}}/>
            }
            {(mediaSequence?.secondSequence?.urlThumbNail && mediaSequence?.splitType) &&
              <img src={mediaSequence.secondSequence.urlThumbNail}  style={{
                width:'20px',
                marginLeft: '5px',
              }}/>
            }
          </Box>


        </Stack>

        {displayScreenLayout &&
          <div style={{width: '100%'}}>

            <Box
              sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                p: 1,
                m: 1,
                borderRadius: 1,
              }}
            >
              <div style={{
                width: '600px',
                height: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundImage: 'url("/static/images/sequences/screen-desktop-svgrepo-com.svg")',
                backgroundSize: '600px 400px',
              }}>

                <Box sx={{
                  width: '355px',
                  height: '235px',
                  transform: 'translateY(-30px)',
                  // backgroundImage: 'url("/static/images/sequences/screen-desktop-svgrepo-com.svg")',
                  // backgroundSize: '450px 250px',
                  //border: '2px solid',
                  //borderRadius: '5px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  //minHeight: '100vh',
                }}>
                  <>
                    {/*<p>{JSON.stringify(mediaSequence || {})}</p>*/}
                    {!mediaSequence?.splitType &&
                      <Box sx={{textAlign: 'center'}}>
                        {mediaSequence?.type &&
                          <>
                            <img src={mediaSequence?.urlThumbNail} width={24} height={24}/>
                            <p>{truncate(mediaSequence?.contentName)}</p>
                          </>
                        }
                        <Button
                          onClick={() => addSequenceMain(mediaSequence.id)}
                          startIcon={(
                            <SvgIcon>
                              {mediaSequence?.type ?
                                <SwapHorizIcon/>
                                :
                                <PlusIcon/>
                              }
                            </SvgIcon>
                          )}
                          color="primary"
                          style={{
                            marginLeft: "2px"}}
                        >
                          {mediaSequence?.type ? localStrings.changeContent : localStrings.addContent}
                        </Button>

                        {mediaSequenceEditable(mediaSequence) &&
                          <Button
                            onClick={() => editMainSequence(mediaSequence.id)}
                            startIcon={(
                              <SvgIcon>
                                <EditIcon/>
                              </SvgIcon>
                            )}
                            color="primary"
                            style={{
                              marginLeft: "2px"}}
                          >
                            {localStrings.editContent}
                          </Button>
                        }
                      </Box>
                    }

                    {/*<p> {mediaSequence?.splitType}</p>*/}

                    {mediaSequence?.splitType === split_type_horizontal &&
                      <>
                        <Typography
                          variant={"h6"}
                          sx={{
                            position: 'absolute',
                            transform: 'translateX(-240px) translateY(-50px)'
                          }}
                        >
                          {(mediaSequence.splitPercent || '50') + " %"}
                        </Typography>

                        <Typography
                          variant={"h6"}
                          sx={{
                            position: 'absolute',
                            transform: 'translateX(-240px) translateY(+50px)'
                          }}
                        >
                          {(100 - (mediaSequence.splitPercent || 50)) + " %"}
                        </Typography>

                        <Box sx={{
                          border: '1px solid',
                          borderColor: 'black',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '125px',
                          width: '360px',
                          position: 'absolute',
                          transform: 'translateY(-60px)',
                        }}/>
                        <Grid container spacing={2}>
                          <Grid item xs={12}>
                            {/*<p>{mediaSequence?.type}</p>*/}
                            {/*<Box>*/}

                            {mediaSequence?.type &&
                              <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                              }}>
                                <Box
                                  sx={{
                                    transform: 'translateY(12px)',
                                    marginRight: '5px'
                                  }}
                                >
                                  <img src={mediaSequence?.urlThumbNail} width={24} height={24}/>
                                </Box>
                                <Box>
                                  <p>{truncate(mediaSequence?.contentName)}</p>
                                </Box>
                              </Box>
                            }

                            <Button
                              onClick={() => addSequenceMain(mediaSequence.id)}
                              sx={{
                                width: mediaSequenceEditable(mediaSequence) ? '50%' :'100%',
                                marginBottom: mediaSequence?.secondSequence?.type ? '0px' : '25px',
                              }}
                              startIcon={(
                                <SvgIcon>
                                  {mediaSequence?.type ?
                                    <SwapHorizIcon/>
                                    :
                                    <PlusIcon/>
                                  }
                                </SvgIcon>
                              )}
                              color="primary"
                              style={{
                                marginLeft: "2px"}}
                            >
                              {mediaSequence?.type ? localStrings.changeContent : localStrings.addContent}
                            </Button>

                            {mediaSequenceEditable(mediaSequence) &&
                              <Button
                                sx={{
                                  width: '48%',
                                  //marginBottom: mediaSequence?.secondSequence?.type ? '0px' : '25px',
                                }}
                                onClick={() => editMainSequence(mediaSequence.id)}
                                startIcon={(
                                  <SvgIcon>
                                    <EditIcon/>
                                  </SvgIcon>
                                )}
                                color="primary"
                                style={{
                                  marginLeft: "2px"}}
                              >
                                {localStrings.editContent}
                              </Button>
                            }


                          </Grid>

                          <Grid item xs={12}>

                            {mediaSequence?.secondSequence?.type &&
                              <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                              }}>
                                <Box
                                  sx={{
                                    transform: 'translateY(12px)',
                                    marginRight: '5px'
                                  }}
                                >
                                  <img src={mediaSequence?.secondSequence?.urlThumbNail} width={24} height={24}/>
                                </Box>
                                <Box>
                                  <p>{truncate(mediaSequence?.secondSequence?.contentName)}</p>
                                </Box>
                              </Box>
                            }

                            <Button
                              onClick={() => addSequenceSecond(mediaSequence.id)}
                              disabled={!mediaSequence?.type}
                              sx={{
                                width: mediaSequenceEditable(mediaSequence?.secondSequence) ? '50%' :'100%',
                                marginTop: mediaSequence?.secondSequence?.type ? '0px' : '25px',
                              }}
                              startIcon={(
                                <SvgIcon>
                                  {mediaSequence?.secondSequence?.type ?
                                    <SwapHorizIcon/>
                                    :
                                    <PlusIcon/>
                                  }
                                </SvgIcon>
                              )}
                              color="primary"
                              style={{
                                marginLeft: "2px"}}
                            >
                              {mediaSequence?.secondSequence?.type ? localStrings.changeContent : localStrings.addContent}
                            </Button>

                            {mediaSequenceEditable(mediaSequence?.secondSequence) &&
                              <Button
                                sx={{
                                  width: '48%',
                                  //marginBottom: mediaSequence?.secondSequence?.type ? '0px' : '25px',
                                }}
                                onClick={() => editSecondSequence(mediaSequence.id)}
                                startIcon={(
                                  <SvgIcon>
                                    <EditIcon/>
                                  </SvgIcon>
                                )}
                                color="primary"
                                style={{
                                  marginLeft: "2px"}}
                              >
                                {localStrings.editContent}
                              </Button>
                            }
                          </Grid>
                        </Grid>
                      </>
                    }

                    {mediaSequence?.splitType === split_type_vertical &&
                      <>

                        <Typography
                          variant={"h6"}
                          sx={{
                            position: 'absolute',
                            transform: 'translateX(-80px) translateY(-150px)'
                          }}
                        >
                          {(mediaSequence.splitPercent || '50') + " %"}
                        </Typography>

                        <Typography
                          variant={"h6"}
                          sx={{
                            position: 'absolute',
                            transform: 'translateX(80px) translateY(-150px)'
                          }}
                        >
                          {(100 - (mediaSequence.splitPercent || '50')) + " %"}
                        </Typography>

                        <Box sx={{
                          border: '1px solid',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          height: '250px',
                          width: '180px',
                          position: 'absolute',
                          borderColor: 'black',
                          transform: 'translateX(-90px)',
                        }}/>
                        <Grid container spacing={2}>
                          <Grid item xs={6}>

                            {mediaSequence?.type &&
                              <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                              }}>
                                <Box
                                  sx={{
                                    transform: 'translateY(12px)',
                                    marginRight: '5px'
                                  }}
                                >
                                  <img src={mediaSequence?.urlThumbNail} width={24} height={24}/>
                                </Box>
                                <Box>
                                  <p>{truncate(mediaSequence?.contentName, 10)}</p>
                                </Box>
                              </Box>
                            }

                            <Button
                              onClick={() => addSequenceMain(mediaSequence.id)}
                              sx={{
                                width: '100%',
                              }}
                              startIcon={(
                                <SvgIcon>
                                  {mediaSequence?.type ?
                                    <SwapHorizIcon/>
                                    :
                                    <PlusIcon/>
                                  }
                                </SvgIcon>
                              )}
                              color="primary"
                              style={{
                                marginLeft: "2px"}}
                            >
                              {mediaSequence?.type ? localStrings.changeContent : localStrings.addContent}
                            </Button>

                            {mediaSequenceEditable(mediaSequence) &&
                              <Button
                                sx={{
                                  width: '100%',
                                  //marginBottom: mediaSequence?.secondSequence?.type ? '0px' : '25px',
                                }}
                                onClick={() => editMainSequence(mediaSequence.id)}
                                startIcon={(
                                  <SvgIcon>
                                    <EditIcon/>
                                  </SvgIcon>
                                )}
                                color="primary"
                                style={{
                                  marginLeft: "2px"}}
                              >
                                {localStrings.editContent}
                              </Button>
                            }
                          </Grid>

                          <Grid item xs={6}>

                            {mediaSequence?.secondSequence?.type &&
                              <Box sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'center'
                              }}>
                                <Box
                                  sx={{
                                    transform: 'translateY(12px)',
                                    marginRight: '5px'
                                  }}
                                >
                                  <img src={mediaSequence?.secondSequence?.urlThumbNail} width={24} height={24}/>
                                </Box>
                                <Box>
                                  <p>{truncate(mediaSequence?.secondSequence?.contentName, 10)}</p>
                                </Box>
                              </Box>
                            }

                            <Button
                              disabled={!mediaSequence?.type}
                              onClick={() => addSequenceSecond(mediaSequence.id)}
                              sx={{
                                width: '100%',
                              }}
                              startIcon={(
                                <SvgIcon>
                                  {mediaSequence?.secondSequence?.type ?
                                    <SwapHorizIcon/>
                                    :
                                    <PlusIcon/>
                                  }
                                </SvgIcon>
                              )}
                              color="primary"
                              style={{
                                marginLeft: "2px"}}
                            >
                              {mediaSequence?.secondSequence?.type ? localStrings.changeContent : localStrings.addContent}
                            </Button>

                            {mediaSequenceEditable(mediaSequence?.secondSequence) &&
                              <Button
                                sx={{
                                  width: '100%',
                                  //marginBottom: mediaSequence?.secondSequence?.type ? '0px' : '25px',
                                }}
                                onClick={() => editSecondSequence(mediaSequence.id)}
                                startIcon={(
                                  <SvgIcon>
                                    <EditIcon/>
                                  </SvgIcon>
                                )}
                                color="primary"
                                style={{
                                  marginLeft: "2px"}}
                              >
                                {localStrings.editContent}
                              </Button>
                            }
                          </Grid>
                        </Grid>
                      </>
                    }



                  </>
                </Box>


              </div>

            </Box>

          </div>
        }



      </Card>
    </>
  );
}

export default SequenceCard;
