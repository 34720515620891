import React, {useState} from 'react';
import ClipLoaderComponent from "../ClipLoaderComponent";
import {ItemListCard} from "../../utils/itemListCard";
import {Box} from "@mui/material";

function ItemSelector({items, selectCallBack, onClose, selectBorder, widthImg, heightImg}) {
  const [selectedId, setSelectedId] = useState(items[0]?.id)

  return (
    <Box
      sx={{
        display: 'grid',
        //gap: 3,
        maxHeight: '150',
        // gridTemplateColumns: 'repeat(3, 1fr)'
      }}
    >
      {!items ?
        <ClipLoaderComponent/>
        :
        <>
          {(items || []).map((item, key) =>
            <ItemListCard
              widthImg={widthImg}
              heightImg={heightImg}
              onOpen={() => {
                if (selectBorder) {
                  setSelectedId(item.id);
                }
                selectCallBack(item.id);
                onClose && onClose();
              }}
              item={{
                name: item.name,
                image: item.image,
                selected: selectBorder && item.id === selectedId,
              }}
            />
          )}
        </>
      }

    </Box>
  );
}

export default ItemSelector;
