import {Box, LinearProgress, CircularProgress} from '@mui/material';
import Logo from "./Logo";

export const SplashScreen = () => (
  <Box
    sx={{
      alignItems: 'center',
      backgroundColor: 'background.paper',
      display: 'flex',
      flexDirection: 'column',
      height: '100vh',
      justifyContent: 'center',
      left: 0,
      p: 3,
      position: 'fixed',
      top: 0,
      width: '100vw',
      zIndex: 1400
    }}
  >
    <Box
      sx={{
        display: 'inline-flex',
        // height: 48,
        // width: 48
      }}
    >
      <Logo heightlogo={250}/>

    </Box>
    <CircularProgress size={80}/>
  </Box>
);
