import React from 'react';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import localStrings from "../../localStrings";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import AccordionDetails from "@mui/material/AccordionDetails";
import {getStartedLayoutContent} from "../../htmlContents/getStartedLayoutContent";
import ReactPlayer from "react-player/lazy";
import {getStartedLayout} from "./index";
import {Button} from "@mui/material";
import {useNavigate} from "react-router-dom";

function AccordionGetStarted({value, expanded, handleChange, title, content, videoLinks, actionUrl, actionText}) {
  const navigate = useNavigate();
  function getStatusColorGetStarted(item) {
    return localStorage.getItem("getStarted_" + item) != null &&
    localStorage.getItem("getStarted_" + item) != undefined ? 'green' : 'red';
  }

  return (
    <Accordion expanded={expanded === value} onChange={handleChange(value)}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1bh-content"
        id="panel1bh-header"
      >
        <Box
          sx={{ display: 'flex', p: 1, borderRadius: 1, width: '100%'}}
        >
          <Box sx={{ flexGrow: 1 }}>
            <Typography sx={{ width: '33%', flexShrink: 0 }}>
              {title}
            </Typography>
          </Box>
          <Box>
            <CheckCircleOutlineIcon style={{ fill: getStatusColorGetStarted(value) }}/>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <div
          dangerouslySetInnerHTML={{__html: content}}
        />

        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row-reverse',
          }}
        >
          <Button onClick={() => navigate(actionUrl)}>
              {actionText}
          </Button>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            borderRadius: 1,
          }}
        >
          {videoLinks.map((link, key) =>
            <Box p={0} m={1} key={key} >
              <a href={link} target="new">
              <div>
                <ReactPlayer
                  loop={true}
                  playing={true}
                  muted={true}
                  url={link} />
              </div>
              </a>
            </Box>
          )}

        </Box>

      </AccordionDetails>
    </Accordion>
  );
}

export default AccordionGetStarted;
