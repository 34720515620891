import PropTypes from 'prop-types';
import {format} from 'date-fns';
import {Box, Card, Divider, Typography} from '@mui/material';
import {bytesToSize} from 'src/utils/bytes-to-size';
import ReactPlayer from 'react-player/lazy';
import React from 'react';

export const ItemListCard = (props) => {
  const { item, onOpen, widthImg, heightImg } = props;

  let size = bytesToSize(item.size);

  if (item.type === 'folder') {
    size += `• ${item.itemsCount} items`;
  }

  return (
    <>
      <Box
        key={item.id}
        sx={{
          border: item.selected ? 'solid 5px' : 'none',
          backgroundColor: 'transparent',
          boxShadow: 0,
          transition: (theme) => theme.transitions.create(['background-color, box-shadow'], {
            easing: theme.transitions.easing.easeInOut,
            duration: 200
          }),
          '&:hover': {
            backgroundColor: 'background.paper',
            boxShadow: 16
          }
        }}
        variant="outlined"
      >
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              mb: 1
            }}
          >
            <Box
              onClick={() => onOpen?.()}
              sx={{
                display: 'inline-flex',
                cursor: 'pointer'
              }}
            >
              {item.image.indexOf('.mp4') != -1 ?
                <ReactPlayer
                  height={'80px'}
                  width={widthImg || '80px'}
                  loop={true}
                  playing={true}
                  autoPlay={true}
                  muted={true}
                  url={item.image}
                />

                :
                <img src={item.image}
                     width={widthImg || '80px'}
                  //height={heightImg || '80px'}
                     style={{
                       objectFit: 'cover'
                     }}
                />
              }
            </Box>
          </Box>
          <Typography
            onClick={() => onOpen?.()}
            sx={{ cursor: 'pointer' }}
            variant="subtitle2"
          >
            {item.name}
          </Typography>
          <Divider sx={{ my: 1 }} />
          {/*<Stack*/}
          {/*  alignItems="center"*/}
          {/*  direction="row"*/}
          {/*  justifyContent="space-between"*/}
          {/*  spacing={1}*/}
          {/*>*/}
          {/*  <div>*/}
          {/*    <Typography*/}
          {/*      color="text.secondary"*/}
          {/*      variant="body2"*/}
          {/*    >*/}
          {/*      {size}*/}
          {/*    </Typography>*/}
          {/*  </div>*/}
          {/*  <div>*/}
          {/*    {item.isPublic && (*/}
          {/*      <Tooltip title="Public">*/}
          {/*        <Avatar*/}
          {/*          sx={{*/}
          {/*            height: 32,*/}
          {/*            width: 32*/}
          {/*          }}*/}
          {/*        >*/}
          {/*          <SvgIcon fontSize="small">*/}
          {/*            <Globe01Icon />*/}
          {/*          </SvgIcon>*/}
          {/*        </Avatar>*/}
          {/*      </Tooltip>*/}
          {/*    )}*/}
          {/*    {showShared && (*/}
          {/*      <AvatarGroup max={3}>*/}
          {/*        {item.shared?.map((person) => (*/}
          {/*          <Avatar*/}
          {/*            key={person.name}*/}
          {/*            src={person.avatar}*/}
          {/*            sx={{*/}
          {/*              height: 32,*/}
          {/*              width: 32*/}
          {/*            }}*/}
          {/*          />*/}
          {/*        ))}*/}
          {/*      </AvatarGroup>*/}
          {/*    )}*/}
          {/*  </div>*/}
          {/*</Stack>*/}
          {/*<Typography*/}
          {/*  color="text.secondary"*/}
          {/*  variant="caption"*/}
          {/*>*/}
          {/*  Created at {createdAt}*/}
          {/*</Typography>*/}
        </Box>
      </Box>
      {/*<ItemMenu*/}
      {/*  anchorEl={popover.anchorRef.current}*/}
      {/*  onClose={popover.handleClose}*/}
      {/*  //onDelete={handleDelete}*/}
      {/*  open={popover.open}*/}
      {/*/>*/}
    </>
  );
};

ItemListCard.propTypes = {
  item: PropTypes.object.isRequired,
  onOpen: PropTypes.func
};
