import React from 'react';
import localStrings from "../../localStrings";
import AceEditor from "react-ace";
import "brace/mode/css";
import "brace/mode/html";
import "brace/mode/json";
import "brace/theme/monokai";

function AceCodeEditor({code, setCode, mode, placeHolderText, readOnly, style}) {
  return (
    <AceEditor
      readOnly={readOnly}
      style={{width: "100%", ...style}}
      placeholder={placeHolderText || ""}
      mode={mode}
      theme="monokai"
      onChange={(code) => {
        setCode(code)
      }}
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      value={code}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
      }}/>
  );
}

export default AceCodeEditor;
