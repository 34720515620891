const GetStarted = (props) => (
  //<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->
  <svg fill="currentColor" width={24}
       height={24} viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg">
    <path d="M24,12h0a2,2,0,0,1,2-2H42a2,2,0,0,1,2,2h0a2,2,0,0,1-2,2H26A2,2,0,0,1,24,12Zm2,14H42a2,2,0,0,0,2-2h0a2,2,0,0,0-2-2H26a2,2,0,0,0-2,2h0A2,2,0,0,0,26,26Zm0,12H42a2,2,0,0,0,2-2h0a2,2,0,0,0-2-2H26a2,2,0,0,0-2,2h0A2,2,0,0,0,26,38ZM10,26h2a2,2,0,0,0,2-2h0a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2H8A2,2,0,0,0,10,26Zm0,12h2a2,2,0,0,0,2-2h0a2,2,0,0,0-2-2H10a2,2,0,0,0-2,2H8A2,2,0,0,0,10,38Zm2.4-22.6,10-10a1.9,1.9,0,0,0,0-2.8,1.9,1.9,0,0,0-2.8,0L11,11.2,8.4,8.6a1.9,1.9,0,0,0-3,.2,2.1,2.1,0,0,0,.2,2.7l4,3.9A1.9,1.9,0,0,0,12.4,15.4Z"/>
  </svg>

);

export default GetStarted;
