import React from 'react';
import {FormControlLabel, Grid, Switch, TextField} from "@mui/material";
import * as Yup from 'yup';
import {useParams} from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import GeneralEditor, {MODE_CREATE, MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import localStrings from "../../../localStrings";
import {useNavigate} from "react-router";
import {addDataBoardToCache, createBoardMutation, getBoardQuery, updateBoardMutation} from "../../../gql/boardGql";
import TextFieldWithCopy from "../../../components/TextFieldWithCopy";
import conf from "../../../conf/config.json";
import {boardsRoute, dashboardBaseRoute} from "../../../routes/dashboard";
import {not_found_path} from "../../../routes";

export function getUrlDashboard(brandId, boardId) {
  return conf.dashBoardBaseUrl + "/" + brandId + "/" + boardId;
}

export const getBoard = async (brandId, boardId, modeEdit, navigate) => {
  if (modeEdit == MODE_CREATE) {
    return null;
  }
  if (!modeEdit || modeEdit == MODE_EDIT) {
    var res =  await executeQueryUtil(getBoardQuery(brandId, boardId));
    if (!res.data?.getBoard) {
      navigate('/' + not_found_path);
      return;
    }
    return res;
  }
  return null;
}

export const saveFormBoard = async (item, values, modeEdit, brandId, navigate) => {
  let data;
  if (!modeEdit || modeEdit == MODE_EDIT) {
    data = {
      ...item,
      ...values,
      changePageDelay: parseInt(values.changePageDelay),
      displayMessageDelay: parseInt(values.displayMessageDelay),
      maxItemPerColumn: parseInt(values.maxItemPerColumn),
    };
    await executeMutationUtil(updateBoardMutation(brandId, data));
  }

  else if (modeEdit == MODE_CREATE){
    data = {...values};
    const projectCreated = await executeMutationUtil(createBoardMutation(brandId, data));
    await addDataBoardToCache(brandId, [projectCreated.data?.addBoard]);
    if (navigate) {
      navigate("/" + dashboardBaseRoute + "/boards/detail/" + projectCreated.data?.addBoard?.id);
    }
  }


  return data;
}

const BoardCreateUpdateForm = ({className, modeEdit}) => {


  let {boardId} = useParams();
  const { currentBrand } = useAuth();
  const navigate = useNavigate();


  const buildFormValues = (item) => {
    return {
      initialValues: {
        name: item.name || '',
        id: item.id,
        changePageDelay: item.changePageDelay || 5000,
        displayMessageDelay: item.displayMessageDelay || 500,
        enableDisplayMessage: item.enableDisplayMessage || false,
        enableDisplayEventPopup: item.enableDisplayEventPopup || false,
        enableHeader: item.enableHeader || false,
        titleHeader: item.titleHeader || '',
        urlLogo: item.urlLogo || '',
        maxItemPerColumn: item.maxItemPerColumn || 10,
      },
      validationSchema: {
        name: Yup.string().max(255).required(localStrings.check.fieldRequired),
        maxItemPerColumn: Yup.number(),
        changePageDelay: Yup.number(localStrings.check.msFormat).positive(localStrings.check.msFormat)
          .required(localStrings.check.fieldRequired),
        displayMessageDelay: Yup.number(localStrings.check.msFormat).positive(localStrings.check.msFormat)
          .required(localStrings.check.fieldRequired)
      }
    };
  }



  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {
    return (
      <Grid
        container
        spacing={4}
      >
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete:"new-password"
            }}
            error={Boolean(touched.name && errors.name)}
            fullWidth
            helperText={touched.name && errors.name}
            label={localStrings.boardName}
            name="name"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.name}
          />
        </Grid>

        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete:"new-password"
            }}
            error={Boolean(touched.changePageDelay && errors.changePageDelay)}
            fullWidth
            helperText={touched.changePageDelay && errors.changePageDelay}
            label={localStrings.changePageDelay}
            name="changePageDelay"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.changePageDelay}
          />
        </Grid>

        {/*<Grid*/}
        {/*  item*/}
        {/*  md={12}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <TextField*/}
        {/*    inputProps={{*/}
        {/*      autocomplete:"new-password"*/}
        {/*    }}*/}
        {/*    error={Boolean(touched.displayMessageDelay && errors.displayMessageDelay)}*/}
        {/*    fullWidth*/}
        {/*    helperText={touched.displayMessageDelay && errors.displayMessageDelay}*/}
        {/*    label={localStrings.displayMessageDelay}*/}
        {/*    name="displayMessageDelay"*/}
        {/*    onBlur={handleBlur}*/}
        {/*    onChange={handleChange}*/}
        {/*    required*/}
        {/*    value={values.displayMessageDelay}*/}
        {/*  />*/}
        {/*</Grid>*/}

        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete:"new-password"
            }}
            error={Boolean(touched.maxItemPerColumn && errors.maxItemPerColumn)}
            fullWidth
            helperText={touched.maxItemPerColumn && errors.maxItemPerColumn}
            label={localStrings.maxItemPerColumn}
            name="maxItemPerColumn"
            onBlur={handleBlur}
            onChange={handleChange}
            required
            value={values.maxItemPerColumn}
          />

        </Grid>

        {/*<Grid*/}
        {/*  item*/}
        {/*  md={12}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <FormControlLabel*/}
        {/*    control={(*/}
        {/*      <Switch*/}
        {/*        checked={values.enableDisplayMessage}*/}
        {/*        edge="start"*/}
        {/*        name="direction"*/}
        {/*        onChange={() => {*/}
        {/*          setFieldValue('enableDisplayMessage', !values.enableDisplayMessage)*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    label={localStrings.enableDisplayMessage}*/}
        {/*  />*/}
        {/*</Grid>*/}

        {/*<Grid*/}
        {/*  item*/}
        {/*  md={12}*/}
        {/*  xs={12}*/}
        {/*>*/}
        {/*  <FormControlLabel*/}
        {/*    control={(*/}
        {/*      <Switch*/}
        {/*        checked={values.enableDisplayEventPopup}*/}
        {/*        edge="start"*/}
        {/*        name="direction"*/}
        {/*        onChange={() => {*/}
        {/*          setFieldValue('enableDisplayEventPopup', !values.enableDisplayEventPopup)*/}
        {/*        }}*/}
        {/*      />*/}
        {/*    )}*/}
        {/*    label={localStrings.enableDisplayEventPopup}*/}
        {/*  />*/}
        {/*</Grid>*/}

        <Grid
          item
          md={12}
          xs={12}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.enableHeader}
                edge="start"
                name="direction"
                onChange={() => {
                  setFieldValue('enableHeader', !values.enableHeader)
                }}
              />
            )}
            label={localStrings.enableHeaderBoard}
          />
        </Grid>

        {values.enableHeader &&
        <>
          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              inputProps={{
                autocomplete:"new-password"
              }}
              error={Boolean(touched.urlLogo && errors.urlLogo)}
              fullWidth
              helperText={touched.urlLogo && errors.urlLogo}
              label={localStrings.urlLogoBoard}
              name="urlLogo"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.urlLogo}
            />
          </Grid>

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              inputProps={{
                autocomplete:"new-password"
              }}
              error={Boolean(touched.titleHeader && errors.titleHeader)}
              fullWidth
              helperText={touched.titleHeader && errors.titleHeader}
              label={localStrings.titleHeaderBoard}
              name="titleHeader"
              onBlur={handleBlur}
              onChange={handleChange}
              required
              value={values.titleHeader}
            />
          </Grid>
        </>
        }



        {modeEdit == MODE_EDIT &&
          <>
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextFieldWithCopy
                inputProps={{
                  autocomplete: "new-password"
                }}
                readOnly
                error={Boolean(touched.name && errors.name)}
                fullWidth
                helperText={touched.name && errors.name}
                label={localStrings.boardUrl}
                name="name"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
                value={getUrlDashboard(currentBrand().id, boardId)}
              />

            </Grid>

            <Grid
              item
              md={12}
              xs={12}
            >
              <TextFieldWithCopy
                inputProps={{
                  autocomplete: "new-password"
                }}
                readOnly
                error={Boolean(touched.id && errors.id)}
                fullWidth
                helperText={touched.id && errors.id}
                label={localStrings.boardId}
                name="id"
                onBlur={handleBlur}
                onChange={handleChange}
                disabled
                value={values.id}
              />
            </Grid>
          </>
        }

      </Grid>
    );
  }




  return <GeneralEditor getItemFunction={() => getBoard(currentBrand().id, boardId, modeEdit, navigate)}
                        className={className}
                        buildFormValues={buildFormValues}
                        getCardContentForm={getCardContentForm}
                        saveForm={(item, values) => saveFormBoard(item, values, modeEdit, currentBrand().id, navigate)}
                        applyButton={modeEdit == MODE_CREATE ? localStrings.create : localStrings.update}
                        resetFormProp={modeEdit == MODE_CREATE}
                        messageSuccess={modeEdit == MODE_CREATE ?
                          localStrings.notif.projectCreated : localStrings.notif.boardUpdated}
                        titleForm={modeEdit == MODE_CREATE ? localStrings.createBoard : localStrings.general}
                        getPathToData={(result) => {
                          return  result.data.getBoard;
                        }}
  />
};

export default BoardCreateUpdateForm;
