const React = require("react");
const importScreenInApp = `

<p>Open your cast reactor dashboard (http://dashboard.castreactor.com) and create a new screen. Then add the code listed above 
</p>

<img src="/static/screenShot/2023-04-20_17_39_20-Cast_Reactor.png" width=550/>


`

module.exports = {
  importScreenInApp: importScreenInApp,
}
