import React, {useState} from 'react';
import {
  Button,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
  SvgIcon,
  Switch,
  TextField,
  Typography
} from "@mui/material";
import GeneralEditor from "../../../components/CrudFrom/GeneralEditor";
import localStrings from "../../../localStrings";
import Box from "@mui/material/Box";
import {googlePresentationType, pptPresentationType} from "../MediaSequenceAddView/MediaSequenceCreateUpdateForm";
import * as Yup from "yup";
import moment from "moment";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import {default_sequence_duration} from "../../ScreenDisplay/screenDisplay";
import { sequenceMediaType, sequenceYouTubeType } from '../../../utils/ItemSequenceSelector';
import XIcon from "@untitled-ui/icons-react/build/esm/X";

export const isValidUrl = (url) => {
  if (!url) {
    return true;
  }
  try {
    new URL(url);
  } catch (e) {
    console.error(e);
    return false;
  }
  return true;
};


export function isPresentation(mimeType) {
  return mimeType === googlePresentationType || mimeType === pptPresentationType;
}

export function isSequenceDurationDefined(item) {
  return item?.type === sequenceYouTubeType || item?.secondSequence?.type === sequenceYouTubeType;
}

export function isSequenceMutable(item) {
  return item?.type === sequenceYouTubeType || item?.secondSequence?.type === sequenceYouTubeType ||
    (item?.type === sequenceMediaType && item?.urlWebLink.indexOf('.mp4')) || (item?.secondSequence?.type === sequenceMediaType && item.secondSequence?.urlWebLink?.indexOf('.mp4')) ||
    (item && item?.mimeType &&  item?.mimeType?.startsWith("video")) || (item?.secondSequence && item?.secondSequence?.mimeType &&  item?.secondSequence?.mimeType?.startsWith("video"));
}

function DrawerSequenceEditor({mediaSequenceEdited, setMediaSequenceEdited, updateMediaSequence, closeCallBack}) {
  const [saving, setSaving] = useState(false);
  const [fullDurationOfMedia, setFullDurationOfMedia] = useState(false);

  async function saveFormSequence(item, values) {
    setSaving(true);
    let durationSeconds = parseInt(values.secondsDuration) || default_sequence_duration;
    let durationMinutes = parseInt(values.minutesDuration) || 0;
    let durationHours = parseInt(values.hoursDuration) || 0;
    values.duration = durationSeconds + 60 * durationMinutes + 3600 * durationHours;
    delete values.secondsDuration;
    delete values.minutesDuration;
    delete values.hoursDuration;
    delete values.save;
    delete item.save;

    if (fullDurationOfMedia) {
      values.duration = 0;
    }

    if (updateMediaSequence) {
      await updateMediaSequence({
        ...item,
        ...values
      })
    }
    setSaving(false);
  }

  const buildFormValues = (item) => {
    //label={itemSequence.type === sequenceYouTubeType ? localStrings.fullDurationVideo : label}
    var duration = (item.duration || 0)* 1000 ;
    var tempTime = moment.duration(duration);
    if (isPresentation(item.mimeType)) {
      return {
        initialValues: {
          displayName: item.displayName  || localStrings.noName,
          secondsDuration: item.duration  || default_sequence_duration,
          mimeType: item.mimeType || '',
          urlWebLink: item.urlWebLink || '',
          type: item.type,
        },
        validationSchema: {
          secondsDuration: Yup.number().min(0),
          urlWebLink: Yup.string()
            .test("is-url-valid", localStrings.check.wrongUrlValue, (value) => {
              return isValidUrl(value);
            })
            .required(localStrings.check.required),
        }
      }
    }

    return {
      initialValues: {
        displayName: item.displayName  || localStrings.noName,
        hoursDuration: tempTime.hours()  || 0,
        minutesDuration: tempTime.minutes()  || 0,
        secondsDuration: tempTime.seconds()  || default_sequence_duration,
        type: item.type,
        mute: item.mute,
      },
      validationSchema: {
        hoursDuration: Yup.number().max(24, localStrings.check.wrongHoursValue).min(0),
        minutesDuration: Yup.number().max(60, localStrings.check.wrongMinutesValue).min(0),
        secondsDuration: Yup.number().max(60, localStrings.check.wrongSecondsValue).min(0),
      }
    };
  }


  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {
    return (
      <Grid
        container
        spacing={4}
      >
        {isSequenceDurationDefined(mediaSequenceEdited) &&
          <>
            <Grid
              item
              md={12}
              xs={12}
            >
              <FormControlLabel
                // className={classes.formControl}
                control={(
                  <Switch
                    checked={fullDurationOfMedia}
                    edge="start"
                    name="direction"
                    onChange={() => {
                      setFullDurationOfMedia(!fullDurationOfMedia)
                    }}
                  />
                )}
                label={localStrings.fullDurationOfMedia}
              />
            </Grid>

          </>
        }

        {isSequenceMutable(mediaSequenceEdited) &&
        <Grid
          item
          md={12}
          xs={12}
        >
          <FormControlLabel
            // className={classes.formControl}
            control={(
              <Switch
                checked={values.mute}
                edge="start"
                name="direction"
                onChange={() => {
                  setFieldValue("mute", !values.mute)
                }}
              />
            )}
            label={localStrings.mute}
          />
        </Grid>
        }

        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete: "new-password"
            }}
            error={Boolean(touched.displayName && errors.displayName)}
            fullWidth
            helperText={touched.displayName && errors.displayName}
            label={localStrings.name}
            name="displayName"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.displayName}
          />
        </Grid>


        {/*{!isPresentation(values.mimeType) && !fullDurationOfMedia &&*/}
        {/*  <>*/}
        {/*    <Grid*/}
        {/*      item*/}
        {/*      md={12}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <TextField*/}
        {/*        inputProps={{*/}
        {/*          autocomplete: "new-password"*/}
        {/*        }}*/}
        {/*        error={Boolean(touched.hoursDuration && errors.hoursDuration)}*/}
        {/*        fullWidth*/}
        {/*        helperText={touched.hoursDuration && errors.hoursDuration}*/}
        {/*        //placeholder={isSequenceDurationDefined(values) ? localStrings.fullDurationVideo : null}*/}
        {/*        label={localStrings.hoursDuration}*/}
        {/*        name="hoursDuration"*/}
        {/*        onBlur={handleBlur}*/}
        {/*        onChange={handleChange}*/}
        {/*        value={values.hoursDuration}*/}
        {/*      />*/}
        {/*    </Grid>*/}


        {/*    <Grid*/}
        {/*      item*/}
        {/*      md={12}*/}
        {/*      xs={12}*/}
        {/*    >*/}
        {/*      <TextField*/}
        {/*        inputProps={{*/}
        {/*          autocomplete: "new-password"*/}
        {/*        }}*/}
        {/*        error={Boolean(touched.minutesDuration && errors.minutesDuration)}*/}
        {/*        fullWidth*/}
        {/*        helperText={touched.minutesDuration && errors.minutesDuration}*/}
        {/*        placeholder={isSequenceDurationDefined(values) ? localStrings.fullDurationVideo : null}*/}
        {/*        //placeholder={localStrings.fullDurationVideo}*/}
        {/*        label={localStrings.minutesDuration}*/}
        {/*        name="minutesDuration"*/}
        {/*        onBlur={handleBlur}*/}
        {/*        onChange={handleChange}*/}
        {/*        value={values.minutesDuration}*/}
        {/*      />*/}
        {/*    </Grid>*/}
        {/*  </>*/}
        {/*}*/}
        {/*{!fullDurationOfMedia &&*/}
        <Grid
          item
          md={12}
          xs={12}
        >
          <TextField
            inputProps={{
              autocomplete: "new-password"
            }}
            error={Boolean(touched.secondsDuration && errors.secondsDuration)}
            fullWidth

            helperText={touched.secondsDuration && errors.secondsDuration}
            placeholder={isSequenceDurationDefined(values) ? localStrings.fullDurationVideo : null}
            //label={isPresentation(values.mimeType) ? localStrings.durationForEachSlide : localStrings.secondsDuration}
            label={localStrings.secondsDuration}
            name="secondsDuration"
            onBlur={handleBlur}
            onChange={handleChange}
            value={values.secondsDuration}
          />
        </Grid>
        {/*}*/}

        {isPresentation(values.mimeType) &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <Box mt={2}>
              <TextField
                style={{marginBottom: 10}}
                inputProps={{
                  autocomplete: "new-password"
                }}
                error={Boolean(touched.urlWebLink && errors.urlWebLink)}
                helperText={touched.urlWebLink && errors.urlWebLink}
                fullWidth
                label={localStrings.urlWebLink}
                name="urlWebLink"
                //onBlur={handleBlur}
                onChange={handleChange}
                value={values.urlWebLink}
              />
            </Box>
          </Grid>
        }
      </Grid>)
  }

  function resetFullDuration() {
    updateMediaSequence({
      ...mediaSequenceEdited,
      duration: 0,
    })
  }

  return (
    <Drawer
      anchor="right"
      onClose={() => setMediaSequenceEdited(null) }
      open={mediaSequenceEdited}
      PaperProps={{
        sx: {
          width: '100%',
          maxWidth: 500
        }
      }}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography variant="h6">
          {mediaSequenceEdited?.displayName}
        </Typography>
        <Box>

        </Box>
        <IconButton
          color="inherit"
          onClick={() => setMediaSequenceEdited(null)}
        >
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>

      <GeneralEditor
        //triggerSave={triggerSave}
        //noCard
        displaySaveButton
        itemSource={mediaSequenceEdited || {}}
        buildFormValues={buildFormValues}
        getCardContentForm={getCardContentForm}
        saveForm={(item, values) => saveFormSequence(item, values)}
        hideApplyButton={false}
        applyButton={localStrings.update}
        resetFormProp={true}
        messageSuccess={localStrings.notif.mediaSequenceUpdated}
        //titleForm={mediaSequenceEdited?.name}
        getPathToData={(result) => {
          return result;
        }}
      >
      </GeneralEditor>

    </Drawer>
  );
}

export default DrawerSequenceEditor;
