import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  name
  htmlCode
  cssCode
  jsCode
  jsDependenciesCode
  cssDependenciesCode
  backgroundColor
`

export const getWidgetQuery = (brandId, widgetId) => {
  var debug = `
    query {
      getWidget(brandId: "${brandId}", widgetId: "${widgetId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getWidget(brandId: "${brandId}", widgetId: "${widgetId}") {
        ${common}
      }
    }
  `;
}


export const getAllWidgetsByBrandIdQuery = (brandId) => {
  var debug = `
    query {
      getWidgetsByBrandId(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getWidgetsByBrandId(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}

export const updateWidgetMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"]);

  var debug = `
    mutation {
      updateWidget(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateWidget(brandId: "${brandId}", data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const createWidgetMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate", "createdAt"],
      [] );

  var debug = `
    mutation {
      addWidget(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      addWidget(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const bulkDeleteWidgetMutation = (brandId, ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkDeleteWidget(brandId: "${brandId}", widgetsIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkDeleteWidget(brandId: "${brandId}", widgetsIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const deleteWidgetByBrandIdCache = async (brandId, ids) => {
  const query = getAllWidgetsByBrandIdQuery(brandId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getWidgetsByBrandId.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getWidgetsByBrandId: [...newData]
      },
    });
  }
}



export const addDataWidgetByBrandIdToCache = async (brandId,datas) => {
  const query = getAllWidgetsByBrandIdQuery(brandId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getWidgetsByBrandId: [...data.data.getWidgetsByBrandId, ...datas]
      },
    });
  }
}

