import {Outlet} from 'react-router-dom';
import {Layout as MarketingLayout} from 'src/layouts/marketing';
import Error401Page from 'src/pages/401';
import Error404Page from 'src/pages/404';
import Error500Page from 'src/pages/500';
import {dashboardBaseRoute, dashboardRoutes} from './dashboard';
import Login from "../pages/Login";
import ResetPassword from "../pages/ResetPassword";
import Register from "../pages/Register";
import AuthChange from "../pages/AuthChange";
import {Navigate} from "react-router";
import {Layout as AuthModernLayout} from "../layouts/auth/modern-layout";
import ScreenDisplay from "../pages/ScreenDisplay/screenDisplay";
import LayoutDisplay from "../../src/pages/LayoutDisplay";
import WidgetDisplay from "../pages/WidgetDisplay";
import ScreenConnect from "../pages/ScreenConnect";
import DisplayEvents from "../pages/DisplayEvents";

export const loginRoute = 'login';
export const connectScreen = 'connect';
export const displayEvents = 'connect';
export const registerRoute = 'register';
export const resetPasswordRoute = 'resetPassword';
export const emailVerificationRoute = 'emailVerification';
export const authChangeRoute = 'authChange';
export const displayEventsFoundRoute = 'displayEvents/:brandId/:boardId';
export const screenDisplayRoute = 'screenDisplay/:brandId/:screenId';
export const screenDisplaySequenceRoute = 'screenDisplaySequence/:brandId/:sequenceId';
export const screenDisplayScheduleRoute = 'screenDisplaySchedule/:brandId/:scheduleId';
export const screenDisplayLayoutRoute = 'screenDisplayLayout/:brandId/:layoutId';
export const widgetDisplayLayoutRoute = 'screenDisplayWidget/:brandId/:widgetId';


export const not_found_path = '404';
export const routes = [
  {
    element: (
      <MarketingLayout>
        <Outlet />
      </MarketingLayout>
    ),
    children: [
      {
        index: true,
        element: <Navigate to={"/" + dashboardBaseRoute}/>
      },
      {
        path: screenDisplaySequenceRoute,
        element: <ScreenDisplay />
      },
      {
        path: screenDisplayScheduleRoute,
        element: <ScreenDisplay />
      },
      { path: displayEventsFoundRoute, element: <DisplayEvents /> },
      { path: screenDisplayRoute, element: <ScreenDisplay /> },
      { path: screenDisplayLayoutRoute, element: <LayoutDisplay /> },
      { path: widgetDisplayLayoutRoute, element: <WidgetDisplay /> },
      {
        path: loginRoute,
        element:
          <AuthModernLayout>
            <Login />
          </AuthModernLayout>
      },
      {
        path: connectScreen,
        element:
          <ScreenConnect/>
      },

      {
        path: resetPasswordRoute,

        element:
          <AuthModernLayout>
            <ResetPassword />
          </AuthModernLayout>
      },
      {
        path: registerRoute,
        element:
          <AuthModernLayout>
            <Register />
          </AuthModernLayout>
      },
      {
        path: authChangeRoute,
        element:
          <AuthModernLayout>
            <AuthChange />
          </AuthModernLayout>
      },

    ]
  },
  ...dashboardRoutes,
  // ...authRoutes,
  //...authDemoRoutes,
  // {
  //   path: 'checkout',
  //   element: <CheckoutPage />
  // },
  // {
  //   path: 'contact',
  //   element: <ContactPage />
  // },
  {
    path: '401',
    element: <Error401Page />
  },
  {
    path: not_found_path,
    element: <Error404Page />
  },
  {
    path: '500',
    element: <Error500Page />
  },
  {
    path: '*',
    element: <Error404Page />
  }
];
