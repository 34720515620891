const getStartedWidgetContent = `

<p>Use widgets to easily create some content with HTML, CSS and javascript</p>
<br/>

<strong>1. Create a new blank widget or use a template</strong>
<br/>
<strong>2. Associate your html css and javascript code</strong>
<br/>
<strong>3. Your widget can be used in media sequence</strong>
<br/>

`
module.exports = {
  getStartedWidgetContent: getStartedWidgetContent,
}
