import React, { useRef, useState } from 'react';
import localStrings from '../localStrings';
import useAuth from '../hooks/useAuth';
import ReactFlagsSelect from 'react-flags-select';
import { IconButton, Popover, Tooltip } from '@mui/material';
import LanguageIcon from '@mui/icons-material/Language';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320,
    padding: 2
  },
  list: {
    padding: 1
  },
  listItemText: {
    marginRight: 1
  },
  listItemTextSelected: {
    marginRight: 1,
    fontWeight: "bold",
  },
  lastActivity: {
    whiteSpace: 'nowrap'
  }
}));

const SwitchLanguage = () => {
  const { setLang,lang } = useAuth();
  const [isOpen, setOpen] = useState(false);
  const ref = useRef(null);
  const classes = useStyles();
  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={localStrings.selectLanguage}>
        <IconButton
          // color="inherit"
          onClick={handleOpen}
          ref={ref}
        >
          <LanguageIcon/>

        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >

        <div style={{minHeight: "150px"}}>

          <ReactFlagsSelect
            selected={lang.toLocaleLowerCase() == "en" ? "GB" : lang.toUpperCase()}
            countries={["GB", "FR"]}
            customLabels={{ GB: "GB", FR: "FR" }}
            placeholder={localStrings.selectLanguage}
            onSelect={code => {
              if (code == "GB") {
                code = "en";
              }
              setLang(code)
              localStrings.setLanguage(code.toLocaleLowerCase())
            }}
          />
        </div>

      </Popover>
    </>

  );
};

export default SwitchLanguage;
