import React, {useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {executeQueryUtil} from "../utils/gqlUtil";
import {getLayoutQuery} from "../gql/layoutGql";
import {decode as base64_decode} from 'base-64';
import {useDocument} from "react-firebase-hooks/firestore";
import {doc, getFirestore} from "firebase/firestore";
import useAuth from "../hooks/useAuth";
import {BRAND_COLLECTION, DEVICE_HEALTH_COLLECTION, LAYOUT_DATA_COLLECTION} from "../../src/utils/constants";
import {removeBackGround} from "./ScreenDisplay/screenDisplay";
const Mustache = require('mustache');

function LayoutDisplay({brandIdParam,layoutIdParam}) {
  let {brandId} = useParams();
  let {layoutId} = useParams();

  const brandIdP = brandIdParam || brandId;
  const layoutIdP = layoutIdParam || layoutId;

  const [layout, setLayout] = useState(null);
  const [renderDone, setRenderDone] = useState(false);
  const [htmlSource, sethtmlSource] = useState(null);
  const db = getFirestore();
  const {setDisplaySettingButton} = useAuth();

  const refDoc =  doc(db, BRAND_COLLECTION
    + "/" + brandIdP + "/" + LAYOUT_DATA_COLLECTION, layoutIdP);
  const [layoutData, layoutDataLoading, layoutDataError] = useDocument(refDoc);

  const loadData = async () => {
    var resLayout = await executeQueryUtil(getLayoutQuery(brandIdP, layoutIdP));
    let layoutRes = resLayout?.data?.getLayout;
    setLayout(layoutRes);
    const htmlDecoded = base64_decode(layoutRes.html)
    sethtmlSource(htmlDecoded)
    setRenderDone(true)
  }

  useEffect(() => {
    setDisplaySettingButton(false);
    loadData();
  }, [])

  function renderHtml() {
    if (!layout) {
      return "";
    }
    const htmlDecoded = base64_decode(layout.html)
    if (layoutData?.data()?.bodyData) {
      const data = JSON.parse(base64_decode(layoutData?.data()?.bodyData));
      sethtmlSource(Mustache.render(htmlDecoded, data || {}));
    }
    return htmlDecoded
  }

  useEffect(() => {
    renderHtml();
    removeBackGround();
  }, [layoutData, layout])

  return (
    <>
      {layout && htmlSource &&
        <div
          dangerouslySetInnerHTML={{__html: htmlSource}}
        />
      }
    </>
  );
}

export default LayoutDisplay;
