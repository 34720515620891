import React from 'react';
import useAppUtil from "../../hooks/useAppUtil";
import ModalPresenter from './ModalPresenter';
import {Navigate} from "react-router";
import {Alert, Button, Dialog, DialogActions, DialogContent} from "@mui/material";
import ReactPlayer from "react-player/lazy";
import localStrings from "../../localStrings";

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `50%`,
    left: `50%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

export default function APPUtilNotification() {
  const { error, removeError, warning, removeWarning,
    errorRedirect, removeErrorAndRedirect, redirectPageUri, removeRedirectPageUri, presenter, showPresenter } = useAppUtil();
  if (errorRedirect) {
    return (<Navigate to="/error" />);
  }

  return (
    <React.Fragment>
      {
        redirectPageUri &&
        <Navigate to={redirectPageUri} />
      }
      <Dialog
        open={!!error}
        onClose={removeError}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogContent>
          <Alert severity={!error || !error.status ? "error" : error.status} onClose={removeError}>
            <strong>{error ? error.message : ""}</strong>
            {error && error.htmlContent &&
              <div
                dangerouslySetInnerHTML={{__html: error.htmlContent}}
              />

              //<ReactMarkdown>{error.mdContent}</ReactMarkdown>
            }
            {error && error.videoLink &&
              <div style={{
                textAlign: 'center',
                backgroundColor: 'black'
              }}>
                <ReactPlayer
                  loop={true}
                  playing={true}
                  muted={true}
                  width={'531px'}
                  url={error.videoLink} />
              </div>
            }


            </Alert>
        </DialogContent>
        <DialogActions>
          <Button onClick={removeError}
                  color="primary"
                  autoFocus>
            {localStrings.iUnderstand}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!warning}
        onClose={removeWarning}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >

        <DialogContent>
          <Alert severity="warning" onClose={removeWarning} >
            {/*<AlertTitle>{localStrings.error}</AlertTitle>*/}
            <strong>{warning ? warning.message : ""}</strong>
          </Alert>
        </DialogContent>

        <DialogActions>
          <Button onClick={removeWarning}
                  color="primary"
                  autoFocus>
            {localStrings.iUnderstand}
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={!!presenter}
        onClose={() => showPresenter(null)}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <DialogContent>
          <ModalPresenter content={presenter}/>
        </DialogContent>

        <DialogActions>
          <Button onClick={() => showPresenter(null)}
                  color="primary"
                  autoFocus>
            {localStrings.iUnderstand}
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  )
}
