import React from 'react';
import {Card, CardContent, Typography} from "@mui/material";
import localStrings from "../localStrings";

function EmptyList({title}) {
  return (
    <Card>
      <CardContent>
        <Typography gutterBottom variant="h6" component="div" sx={{textAlign: 'center'}}>
          {title || localStrings.emptyList}
        </Typography>
      </CardContent>
    </Card>
  );
}

export default EmptyList;
