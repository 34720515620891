import React, {useEffect} from 'react';
import PageWithTitle from "../../components/pages/pageWithTitle";
import localStrings from "../../localStrings";
import "./Player.css";
import {getStartedLayoutContent} from "../../htmlContents/getStartedLayoutContent";
import {getStartedAddMediaContent} from "../../htmlContents/getStartedAddMediaContent";
import {getStartedAddMediaSequenceContent} from "../../htmlContents/getStartedAddMediaSequenceContent";
import {getStartedWidgetContent} from "../../htmlContents/getStartedWidgetContent";
import {getStartedConnectScreenContent} from "../../htmlContents/getStartedConnectScreenContent";
import {getStartedAddSceduleContent} from "../../htmlContents/getStartedAddSceduleContent";

import AccordionGetStarted from "./AccordionGetStarted";
import {startedPopupDisplayed} from "../../contexts/FirebaseAuthContext";

export const getStartedLayout = 'layout';
export const getStartedAddMedia = 'addMedia';
export const getStartedMediaSequence = 'mediaSequence';
export const getStartedWidget = 'widget';
export const getStartedScreen = 'screen';
export const getStartedSchedule = 'schedule';

export async function setGetStartedDone(item, sendPlayConfetti) {
  if (!sendPlayConfetti) {
    return;
  }
  if (localStorage.getItem("getStarted_" + item) != null &&
    localStorage.getItem("getStarted_" + item) != undefined) {
    return;
  }

  sendPlayConfetti();
  localStorage.setItem("getStarted_" + item, "true");
}

function GetStarted(props) {
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    localStorage.setItem(startedPopupDisplayed, "true");
  }, [])



  return (
    <PageWithTitle title={localStrings.getStarded}>
      <div style={{
        width: '100%'
      }}>
        <AccordionGetStarted
          expanded={expanded}
          title={localStrings.getStarted.createLayout}
          handleChange={handleChange}
          content={getStartedLayoutContent}
          value={getStartedLayout}
          videoLinks={["/static/videos/createLayout.mp4"]}
          actionUrl="/dashboard/layouts/add"
          actionText={localStrings.createLayoutNow}
         />

        <AccordionGetStarted
          expanded={expanded}
          title={localStrings.getStarted.addMedia}
          handleChange={handleChange}
          content={getStartedAddMediaContent}
          value={getStartedAddMedia}
          videoLinks={["/static/videos/addMedia.mp4"]}
          actionUrl="/dashboard/medialList"
          actionText={localStrings.addMediaNow}
        />

        <AccordionGetStarted
          expanded={expanded}
          title={localStrings.getStarted.createMediaSequence}
          handleChange={handleChange}
          content={getStartedAddMediaSequenceContent}
          value={getStartedMediaSequence}
          videoLinks={["/static/videos/addMediaSequence.mp4"]}
          actionUrl="/dashboard/mediaSequences/add"
          actionText={localStrings.addMediaSequenceNow}
        />

        <AccordionGetStarted
          expanded={expanded}
          title={localStrings.getStarted.createWidget}
          handleChange={handleChange}
          content={getStartedWidgetContent}
          value={getStartedWidget}
          videoLinks={["/static/videos/addWidget.mp4"]}
          actionUrl="/dashboard/widgets/add"
          actionText={localStrings.addWidgetNow}
        />

        <AccordionGetStarted
          expanded={expanded}
          title={localStrings.getStarted.connectScreen}
          handleChange={handleChange}
          content={getStartedConnectScreenContent}
          value={getStartedScreen}
          videoLinks={["/static/videos/addScreen1.mp4", "/static/videos/addScreen2.mp4"]}
          actionUrl="/dashboard/screens/add"
          actionText={localStrings.connectScreenNow}
        />

        <AccordionGetStarted
          expanded={expanded}
          title={localStrings.getStarted.addSchedule}
          handleChange={handleChange}
          content={getStartedAddSceduleContent}
          value={getStartedSchedule}
          videoLinks={["/static/videos/addSchedule.mp4"]}
          actionUrl="/dashboard/schedules/add"
          actionText={localStrings.addScheduleNow}
        />
      </div>
    </PageWithTitle>
  );
}

export default GetStarted;
