import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const commonWithDetail = `
  id
  name
  backgroundColor
  sequences {
    id
    googleDocId
    leaf
    splitType
    splitPercent
    urlThumbNail
    displayName
    contentName
    mimeType
    duration
    urlWebLink
    type
    layoutId
    widgetId
    boardId
    hide
    mute
    secondSequence {
      id
      googleDocId
      leaf
      splitType
      splitPercent
      urlThumbNail
      displayName
      contentName
      mimeType
      duration
      urlWebLink
      type
      layoutId
      widgetId
      boardId
      mute
    }
  }
`

// const common = `
//   id
//   name
//   backgroundColor
//   sequences {
//     id
//     googleDocId
//     urlThumbNail
//     displayName
//     contentName
//     mimeType
//     duration
//     urlWebLink
//     detailPresentation
//     type
//     layoutId
//     widgetId
//   }
// `


export const getMediaSequenceQuery = (brandId, mediaSequenceId) => {
  let gqlSource = commonWithDetail;

  var debug = `
    query {
      getMediaSequence(brandId: "${brandId}", mediaSequenceId: "${mediaSequenceId}") {
        ${gqlSource}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getMediaSequence(brandId: "${brandId}", mediaSequenceId: "${mediaSequenceId}") {
        ${gqlSource}
      }
    }
  `;
}


export const getAllMediaSequencesByBrandIdQuery = (brandId) => {
  var debug = `
    query {
      getMediaSequencesByBrandId(brandId: "${brandId}") {
        ${commonWithDetail}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getMediaSequencesByBrandId(brandId: "${brandId}") {
        ${commonWithDetail}
      }
    }
  `;
}

export const updateMediaSequenceMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"], ["splitType"]);

  var debug = `
    mutation {
      updateMediaSequence(brandId: "${brandId}", data: ${dataString}) {
        ${commonWithDetail}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      updateMediaSequence(brandId: "${brandId}", data: ${dataString}) {
        ${commonWithDetail}
      }
  }
  `;
}

export const createMediaSequenceMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate", "createdAt"],
      [] );

  var debug = `
    mutation {
      addMediaSequence(brandId: "${brandId}", data: ${dataString}) {
        ${commonWithDetail}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      addMediaSequence(brandId: "${brandId}", data: ${dataString}) {
        ${commonWithDetail}
      }
  }
  `;
}

export const bulkDeleteMediaSequenceMutation = (brandId, ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkDeleteMediaSequence(brandId: "${brandId}", mediaSequencesIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkDeleteMediaSequence(brandId: "${brandId}", mediaSequencesIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const deleteMediaSequenceByBrandIdCache = async (brandId, ids) => {
  const query = getAllMediaSequencesByBrandIdQuery(brandId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getMediaSequencesByBrandId.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getMediaSequencesByBrandId: [...newData]
      },
    });
  }
}

// export const addDataMediaSequenceQueryToCache = async (brandId,data) => {
//   const query = getMediaSequenceQuery(brandId);
//   const data = await executeQueryUtil(query);
//
//   if (data) {
//     await apolloClient.writeQuery({
//       query: query,
//       data: {
//         getMediaSequencesByBrandId: [...data.data.getMediaSequencesByBrandId, ...datas]
//       },
//     });
//   }
// }


export const addDataMediaSequenceByBrandIdToCache = async (brandId,datas) => {
  const query = getAllMediaSequencesByBrandIdQuery(brandId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getMediaSequencesByBrandId: [...data.data.getMediaSequencesByBrandId, ...datas]
      },
    });
  }
}

