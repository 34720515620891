import PropTypes from 'prop-types';
import { Box, Stack, Typography } from '@mui/material';
import Logo from "../../components/Logo";
import { LogoSamsung } from 'src/components/logos/logo-samsung';
import { LogoVisma } from 'src/components/logos/logo-visma';
import { LogoBolt } from 'src/components/logos/logo-bolt';
import { LogoAws } from 'src/components/logos/logo-aws';
import { LogoAccenture } from 'src/components/logos/logo-accenture';
import { LogoAtt } from 'src/components/logos/logo-att';
import { RouterLink } from 'src/components/router-link';
import { paths } from 'src/paths';
import localStrings from "../../localStrings";

export const Layout = (props) => {
  const { children } = props;

  return (

    <Box
      sx={{
        backgroundColor: 'background.default',
        display: 'flex',
        flex: '1 1 auto',
        flexDirection: {
          xs: 'column-reverse',
          md: 'row'
        }
      }}
    >
      <Box
        sx={{
          alignItems: 'center',
          height: '100vh',
          backgroundColor: 'neutral.800',
          backgroundImage: 'url("/assets/gradient-bg.svg")',
          backgroundPosition: 'top center',
          backgroundRepeat: 'no-repeat',
          color: 'common.white',
          display: 'flex',
          flex: {
            xs: '0 0 auto',
            md: '1 1 auto'
          },
          justifyContent: 'center',
          p: {
            xs: 4,
            md: 8
          }
        }}
      >
        <Box maxWidth="md">
          <Typography
            sx={{ mb: 1 }}
            variant="h4"
          >
            {localStrings.content.welcome}
          </Typography>
          <Typography
            color="text.secondary"
            sx={{ mb: 4 }}
          >
            {localStrings.content.welcomeHeader}
            {/*A professional kit that comes with ready-to-use MUI components developed with one common goal in mind, help you build faster & beautiful applications.*/}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{ mb: 2 }}
          >
            {localStrings.content.welcomeSubHeader}
          </Typography>
          <Stack
            alignItems="center"
            direction="row"
            flexWrap="wrap"
            gap={4}
            sx={{
              color: 'text.primary',
              '& > *': {
                color: 'neutral.400',
                flex: '0 0 auto'
              }
            }}
          >
            {/*<LogoSamsung />*/}
            {/*<LogoVisma />*/}
            {/*<LogoBolt />*/}
            {/*<LogoAws />*/}
            {/*<LogoAccenture />*/}
            {/*<LogoAtt />*/}
          </Stack>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          display: 'flex',
          // flex: {
          //   xs: '1 1 auto',
          //   md: '0 0 auto'
          // },
          flexDirection: 'column',
          justifyContent: {
            md: 'center'
          },
          maxWidth: '100%',
          // p: {
          //   xs: 4,
          //   md: 8
          // },
          width: {
            md: 600
          }
        }}
      >
        <div>
          {/*<Box sx={{  }}>*/}
            {/*<Stack*/}
            {/*  alignItems="center"*/}
            {/*  component={RouterLink}*/}
            {/*  direction="row"*/}
            {/*  display="inline-flex"*/}
            {/*  href={paths.index}*/}
            {/*  spacing={1}*/}
            {/*  sx={{ textDecoration: 'none' }}*/}
            {/*>*/}
              <Box
                sx={{
                  display: 'flex',
                  width: '100%',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Logo />
              </Box>
              {/*<Box*/}
              {/*  sx={{*/}
              {/*    color: 'text.primary',*/}
              {/*    fontFamily: '\'Plus Jakarta Sans\', sans-serif',*/}
              {/*    fontSize: 14,*/}
              {/*    fontWeight: 800,*/}
              {/*    letterSpacing: '0.3px',*/}
              {/*    lineHeight: 2.5,*/}
              {/*    '& span': {*/}
              {/*      color: 'primary.main'*/}
              {/*    }*/}
              {/*  }}*/}
              {/*>*/}
              {/*  Devias Kit <span>PRO</span>*/}
              {/*</Box>*/}
            {/*</Stack>*/}
          {/*</Box>*/}
          {children}
        </div>
      </Box>
    </Box>
  );
};

Layout.propTypes = {
  children: PropTypes.node
};
