import React, {useEffect, useState} from 'react';
import {Autocomplete, FormControl, Grid, TextField} from "@mui/material";
import {useParams} from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import GeneralEditor, {MODE_CREATE, MODE_EDIT} from "../../../components/CrudFrom/GeneralEditor";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import localStrings from "../../../localStrings";
import {useNavigate} from "react-router";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import conf from "../../../conf/config.json";
import TextFieldWithCopy from "../../../components/TextFieldWithCopy";
import {
  addDataScreenByBrandIdToCache,
  createScreenMutation,
  getScreenQuery,
  updateScreenMutation
} from '../../../gql/screenGql';
import {getAllMediaSequencesByBrandIdQuery} from "../../../gql/mediaSequenceGql";
import {dashboardBaseRoute} from "../../../routes/dashboard";
import {not_found_path} from "../../../routes";
import {getTempScreenQuery, updateTempScreenMutation} from "../../../gql/tempScreenGql";
import cloneDeep from "clone-deep";
import enqueueSnackbarWithSound from "../../../utils/SnackBarWithSound";
import toast from "react-hot-toast";
import useAppUtil from "../../../hooks/useAppUtil";
import {CourseLesson} from "../../../sections/dashboard/academy/course-lesson";
import {explainPairingScreen} from "./explainContentUpdateData";
import * as Yup from "yup";
import {getAllSchedulesByBrandIdQuery} from "../../../gql/scheduleGql";
import {getStartedScreen, getStartedWidget, setGetStartedDone} from "../../GettingStarted";

export const mediaSequenceType = 'mediaSequence';
export const scheduleType = 'schedule';

export const saveFormScreen = async (item, values, modeEdit, navigate, brandId, addError, sendPlayConfetti) => {
  let data;

  if (!modeEdit || modeEdit == MODE_EDIT) {
    delete values.mediaSequence;

    if (values.item?.type === mediaSequenceType) {
      data = {
        ...values,
        displayType: mediaSequenceType,
        inputId: values.item.id
      };
    }
    else if (values.item?.type === scheduleType) {
      data = {
        ...values,
        displayType: scheduleType,
        inputId: values.item.id
      };
    }

    delete data.item;
    await setGetStartedDone(getStartedScreen, sendPlayConfetti)
    await executeMutationUtil(updateScreenMutation(brandId, data));

  }

  else if (modeEdit == MODE_CREATE){
    data = {...values};

    const res = await executeQueryUtil(getTempScreenQuery(values.screenInputId))
    if (!res?.data?.getTempScreen) {
      addError(localStrings.warningMessage.NoTempScreenId)
      return
    }
    if (res?.data?.getTempScreen.brandId) {
      addError(localStrings.warningMessage.ScreenAlreadyAssociated)
      return
    }
    const screenCreated = await executeMutationUtil(createScreenMutation(brandId, data));
    await addDataScreenByBrandIdToCache(brandId, [screenCreated.data?.addScreen]);

    const tempScreenClone = cloneDeep(res?.data?.getTempScreen);
    tempScreenClone.brandId = brandId;
    tempScreenClone.screenId = screenCreated?.data?.addScreen?.id;

    await executeMutationUtil(updateTempScreenMutation(tempScreenClone))
    enqueueSnackbarWithSound(toast, null, null, localStrings.notif.screenPaired);
    navigate("/" + dashboardBaseRoute + "/screens/detail/" + screenCreated.data?.addScreen?.id);
  }

  //navigate("/" +dashboardBaseRoute + "/" + screensRoute)
  return data;
}

const ScreenCreateUpdateForm = ({className, modeEdit}) => {
  let {screenId} = useParams();
  const { currentBrand, superAdmin, sendPlayConfetti} = useAuth();
  const navigate = useNavigate();
  const [items, setItems] = useState(null);
  const {addError} = useAppUtil();

  async function fillItems() {
    let allItems = [];

    const resMediaSequences = await executeQueryUtil(getAllMediaSequencesByBrandIdQuery(currentBrand().id));

    (resMediaSequences.data?.getMediaSequencesByBrandId || []).forEach(ms => {
      allItems.push({
        ...ms,
        type: mediaSequenceType,
        typeLocal: localStrings.mediaSequences
      })
    })

    const resSchedules = await executeQueryUtil(getAllSchedulesByBrandIdQuery(currentBrand().id));

    (resSchedules.data?.getSchedulesByBrandId || []).forEach(s => {
      allItems.push({
        ...s,
        type: scheduleType,
        typeLocal: localStrings.schedules
      })
    })

    setItems(allItems);
  }

  useEffect( () => {
    fillItems();
  }, [currentBrand()]);

  const getScreen = async () => {
    if (modeEdit == MODE_CREATE) {
      return null;
    }
    if (modeEdit == MODE_EDIT) {
      var res =  await executeQueryUtil(getScreenQuery(currentBrand().id, screenId));
      if (!res.data?.getScreen) {
        navigate('/' + not_found_path);
        return

      }
      return res;
    }
    return null;
  }


  const buildFormValues = (item) => {
    // let validation = {
    //   initialValues: {
    //     id: item.id || '',
    //     name: item.name || '',
    //     nanoId: item.nanoId,
    //     item: items?.find(p => p.id === item.inputId && p.type == item.displayType),
    //     screenInputId: item.screenInputId,
    //   },
    // };
    // const index =

    let validation = {
      initialValues: {
        id: item.id || '',
        name: item.name || '',
        nanoId: item.nanoId,
        item: items?.find(p => p.id === item.inputId && p.type == item.displayType),
        screenInputId: item.screenInputId,
      },
    };

    if (modeEdit == MODE_CREATE) {
      validation.validationSchema = {
        screenInputId: Yup.string().max(255).required(localStrings.check.fieldRequired),
      }
    }
    else {
      validation.validationSchema = {
        item: Yup.object().required(localStrings.check.fieldRequired),
      }
    }
    return validation;
  }

  function formatItemDisplay(item) {
    if (!item.name) {
      return "";
    }
    // if (item.type === mediaSequenceType) {
    //   return localStrings.formatString(localStrings.itemMediaSequence, item.name);
    // }
    return item.name;
  }

  function getUrlScreenDisplay() {
    return conf.screenBaseUrl + "/" + currentBrand().id + "/" + screenId;
  }


  const getCardContentForm = ({
                                errors,
                                handleBlur,
                                handleChange,
                                handleSubmit,
                                isSubmitting,
                                setFieldValue,
                                touched,
                                values
                              }) => {
    return (
      <>
        {modeEdit == MODE_CREATE &&
          <CourseLesson content={explainPairingScreen} name={"paringScreen"}/>

        }

        <Grid
          container
          spacing={4}
        >

          <Grid
            item
            md={12}
            xs={12}
          >
            <TextField
              style={{marginBottom: 10}}
              inputProps={{
                autocomplete: "new-password"
              }}
              error={Boolean(touched.name && errors.name)}
              fullWidth
              helperText={touched.name && errors.name}
              label={localStrings.deviceName}
              name="name"
              onBlur={handleBlur}
              onChange={handleChange}
              value={values.name}
            />
          </Grid>
          {modeEdit == MODE_CREATE &&
            <Grid
              item
              md={12}
              xs={12}
            >
              <TextField
                //style={{marginBottom: 10}}
                inputProps={{
                  autocomplete: "new-password"
                }}
                // disabled={modeEdit == MODE_EDIT}
                //readOnly={modeEdit == MODE_EDIT}
                //error={Boolean(touched.screenInputId && errors.screenInputId)}
                fullWidth
                //helperText={touched.screenInputId && errors.screenInputId}
                label={localStrings.pairingCode}
                name="screenInputId"
                //onBlur={handleBlur}
                onChange={handleChange}
                value={values.screenInputId}
              />
            </Grid>
          }

          {modeEdit == MODE_EDIT &&
            <>
              <Grid
                item
                md={12}
                xs={12}
              >
                <FormControl variant="outlined" fullWidth sx={{marginTop: 0}}
                             error={Boolean(touched.category && errors.category)}
                >
                  <Autocomplete
                    noOptionsText={localStrings.noItemAssociated}
                    value={values.item || null}
                    id="tags-outlined"
                    options={items || []}
                    groupBy={(option) => option.typeLocal}
                    getOptionLabel={(item) => formatItemDisplay(item)}
                    onChange={(event, value) => {
                      setFieldValue("item", value);
                    }
                    }
                    filterSelectedOptions
                    inputValue={values.item}
                    renderInput={(params) => (
                      <TextField
                        style={{ marginBottom: 10 }}
                        {...params}
                        helperText={touched.item && errors.item}
                        error={Boolean(touched.item && errors.item)}
                        label={localStrings.contentString}
                        // placeholder={localStrings.item}
                      />
                    )}
                  />
                </FormControl>
              </Grid>

              {superAdmin &&
                <Grid
                  item
                  md={12}
                  xs={12}
                >
                  <TextFieldWithCopy
                    inputProps={{
                      autocomplete: "new-password"
                    }}
                    readOnly
                    fullWidth
                    label={localStrings.screenUrl}
                    name="name"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    disabled
                    value={getUrlScreenDisplay()}
                  />
                </Grid>
              }
            </>
          }



        </Grid>

      </>
    );
  }


  return (
    <>
      {items ?
        <GeneralEditor getItemFunction={() => getScreen(modeEdit)}
                       className={className}
                       buildFormValues={buildFormValues}
                       getCardContentForm={getCardContentForm}
                       saveForm={(item, values) => saveFormScreen(item, values, modeEdit, navigate,
                         currentBrand().id, addError, sendPlayConfetti)}
                       applyButton={modeEdit == MODE_CREATE ? localStrings.create : localStrings.update}
                       resetFormProp={modeEdit == MODE_CREATE}
                       messageSuccess={modeEdit == MODE_CREATE ?
                         localStrings.notif.deviceAdded : localStrings.notif.deviceUpdated}
                       titleForm={modeEdit == MODE_CREATE ?
                         localStrings.addDevice : localStrings.editDevice}
                       getPathToData={(result) => {
                         return result.data.getScreen;
                       }}
        />
        :
        <ClipLoaderComponent/>
      }
    </>
  )
};

export default ScreenCreateUpdateForm;
