const SplitHorizontal = (props) => (
  //<?xml version="1.0" encoding="utf-8"?><!-- Uploaded to: SVG Repo, www.svgrepo.com, Generator: SVG Repo Mixer Tools -->

<svg fill="currentColor" width={24}
     height={24} viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
  <path d="M19.5 10.5V20.5H5.5V10.5M19.5 10.5H5.5M19.5 10.5V4.5H5.5V10.5M12.5 13V15.5M12.5 18V15.5M12.5 15.5H15M12.5 15.5H10" stroke="#121923" stroke-width="1.2"/>
</svg>

);

export default SplitHorizontal;
