const getStartedConnectScreenContent = `

<p>Connect a screen using a browser software in full screen. Open 
<a href="https://dashboard.castreactor.com/connect" target="new">this</a> page and copy </p>
<br/>

<strong>1. Copy the code</strong>
<br/>
<strong>2. Pair your screen in cast reactor</strong>
<br/>
<strong>3. Set the content (could be a media sequence or a schedule)</strong>
<br/>

`
module.exports = {
  getStartedConnectScreenContent: getStartedConnectScreenContent,
}
