import React, {useEffect, useState} from 'react';
import {doc, getDoc, getFirestore} from "../../lib/firebase";
import {useParams} from "react-router-dom";
import {executeMutationUtil, executeMutationUtilSync, executeQueryUtil} from "../../utils/gqlUtil";
import {getScreenQuery, updateLiveCheckMutation} from "../../gql/screenGql";
import {getMediaSequenceQuery} from "../../gql/mediaSequenceGql";
import ClipLoaderComponent from "../../components/ClipLoaderComponent";
import cloneDeep from "clone-deep";
import {
  BRAND_COLLECTION, DEVICE_HEALTH_COLLECTION,
  MEDIA_SEQUENCE_COLLECTION,
  SCHEDULE_COLLECTION,
  SCREEN_COLLECTION
} from "../../utils/constants";
import {collection, onSnapshot} from "firebase/firestore";
import useAuth from "../../hooks/useAuth";
import MediaSequenceDisplay from "./mediaSequenceDisplay";
import {mediaSequenceType, scheduleType} from "../Screens/ScreenAddView/ScreenCreateUpdateForm";
import {getScheduleQuery} from "../../gql/scheduleGql";
import ScheduleDisplay from "./scheduleDisplay";
import {
  getMediaSequenceWithLocalStorage,
  getScheduleWithLocalStorage,
  scheduleItemsByBrandIdPrefix,
  schedulePrefix,
  mediaSequencePrefix
} from "./loadDataLocalStorage";
import {Box} from "@mui/material";
import Logo from "../../components/Logo";
import localStrings from "../../localStrings";
import {useCollectionData, useDocumentData} from "react-firebase-hooks/firestore";
import NoCredit from "../NoCredit";
import NoCreditCompo from "../../components/NoCreditCompo";

export const default_sequence_duration = 5;

export function removeBackGround() {

  const divRoot = document.getElementById("root");
  if (divRoot) {
    divRoot.style.removeProperty('background-color');
  }

  const divCss = document.getElementById("root")?.firstChild;
  if (divCss) {
    divCss.className = ''
    divCss.style.removeProperty('background-color');
  }
  const divCssChild = divCss.firstChild;
  if (divCssChild) {
    divCssChild.style.removeProperty('background-color');
  }

  const divMainScreen = document.getElementById("mainScreen");
  if (divMainScreen) {
    divMainScreen.style.removeProperty('background-color');
  }

  document.getElementsByTagName("body")[0].style.overflow = 'clip';
}

export function setBackGround(color) {
  if (!color) {
    return;
  }
  const divRoot = document.getElementById("root");
  if (divRoot) {
    divRoot.style.backgroundColor = color;
  }
  const divMainScreen = document.getElementById("mainScreen");
  if (divMainScreen) {
    divMainScreen.style.backgroundColor = color;
  }

}

export const isEnoughCredit = (brandDb) => {
  if (!brandDb) {
    return true;
  }

  const minimalCreditToLock = brandDb.minimalCreditToLock == undefined ? 0 : brandDb.minimalCreditToLock;
  if (brandDb.credit < minimalCreditToLock) {
    return false;
  }
  return true;
}

function ScreenDisplay() {

  const [screen, setScreen] = useState(null);
  const [mediaSequence, setMediaSequence] = useState(null);
  const [schedule, setSchedule] = useState(null);
  //const [noScreenSetting, setNoScreenSetting] = useState(false);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [screenDbLoadDone, setScreenDbLoadDone] = useState(false);
  const [mediaSequenceDb, setMediaSequenceDb] = useState(null);
  const [screenDb, setScreenDb] = useState(null);
  const [scheduleDb, setScheduleDb] = useState(null);

  let {brandId} = useParams();
  let {screenId} = useParams();
  let {sequenceId} = useParams();
  let {scheduleId} = useParams();

  const query = new URLSearchParams(window.location.search);
  const mediaId = query.get('mediaId');
  const db = getFirestore();


  const refBrand = doc(db, BRAND_COLLECTION, brandId || "-1");
  const [brandDb, brandDbLoading, brandDbError] = useDocumentData(refBrand, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });



  useEffect(() => {
    if (!brandId || !screenId || !screenDb) {
      return;
    }
    const interval = setInterval(
      () => {
        executeMutationUtilSync(updateLiveCheckMutation(brandId, screenId))
      },
      30000,
    );

    return () => clearInterval(interval);
  }, [screenDb]);

  const {currentBrand, setDisplaySettingButton} = useAuth()

  useEffect(() => {
    setDisplaySettingButton(false);
    removeBackGround()
  }, [])

  useEffect(() => {
    if (!mediaId) {
      return;
    }

    const pathColl = BRAND_COLLECTION + "/" + brandId + "/" + MEDIA_SEQUENCE_COLLECTION;
    const docRef = doc(db, pathColl, sequenceId || "-1");
    getDoc(docRef).then(snap => {
      setMediaSequenceDb(snap.data())
    })

    const unsub = onSnapshot(doc(db, pathColl, sequenceId || "-1"),
      (doc) => {
        if (doc.data()) {
          setMediaSequenceDb(doc.data())
        }
      });

    return () => {
      unsub();
    }
  }, [])

  useEffect(() => {
    if (!scheduleId) {
      return;
    }

    const pathColl = BRAND_COLLECTION + "/" + brandId + "/" + SCHEDULE_COLLECTION;
    const docRef = doc(db, pathColl, scheduleId || "-1");
    getDoc(docRef).then(snap => {
      setScheduleDb(snap.data())
    })

    const unsub = onSnapshot(doc(db, pathColl, scheduleId || "-1"),
      (doc) => {
        if (doc.data()) {
          setMediaSequenceDb(doc.data())
        }
      });

    return () => {
      unsub();
    }
  }, [])

  useEffect(() => {
    if (!screenId) {
      return;
    }

    const pathColl = BRAND_COLLECTION + "/" + brandId + "/" + SCREEN_COLLECTION;
    const docRef = doc(db, pathColl, screenId);
    getDoc(docRef).then(snap => {
      setScreenDb(snap.data())
      setScreenDbLoadDone(true);
    })

    const unsub = onSnapshot(doc(db, pathColl, screenId),
      (doc) => {
        if (doc.data()?.reload) {
          for (var key in localStorage){
            console.log(key)
            if (key.indexOf(mediaSequencePrefix) != -1 ||
              key.indexOf(schedulePrefix) != -1 ||
              key.indexOf(scheduleItemsByBrandIdPrefix) != -1
            ) {
              localStorage.removeItem(key);
            }
          }
          window.location.reload();
        }
      });

    return () => {
      unsub();
    }
  }, [])


  function setMediaSequenceAndFilterHide(mediaSequence) {
    const mediaSequenceClone = cloneDeep(mediaSequence);
    mediaSequenceClone.sequences = mediaSequenceClone.sequences.filter(s => !s.hide);
    setMediaSequence(mediaSequenceClone);
  }


  useEffect(() => {
    const loadScreen = async () => {
      //alert("loadScreen")
      if (sequenceId) {
        if (!mediaId && initialLoadDone) {
          return;
        }
        if (mediaId) {
          setMediaSequenceAndFilterHide(mediaSequenceDb);
          return;
        }
        const mediaSequenceRes = await executeQueryUtil(getMediaSequenceQuery(brandId, sequenceId));
        const mediaSequence = cloneDeep(mediaSequenceRes?.data?.getMediaSequence);
        setMediaSequenceAndFilterHide(mediaSequence);
      }
      else if (scheduleId) {
        const scheduleRes = await executeQueryUtil(getScheduleQuery(brandId, scheduleId));
        const schedule = cloneDeep(scheduleRes?.data?.getSchedule);
        setSchedule(schedule);
      }
      else {
        const dataScreen = screenDb;
        if (dataScreen && dataScreen.displayType == mediaSequenceType && dataScreen.inputId) {
          const mediaSequence = await getMediaSequenceWithLocalStorage(brandId, dataScreen.inputId)
          setMediaSequenceAndFilterHide(cloneDeep(mediaSequence));
        }
        else if (dataScreen && dataScreen.displayType == scheduleType && dataScreen.inputId) {
          const schedule = await getScheduleWithLocalStorage(brandId, dataScreen.inputId);
          setSchedule(schedule);
        }
      }
      setInitialLoadDone(true);
    }
    loadScreen();
  }, [mediaSequenceDb, screenDb, screenDbLoadDone])


  return (
    <>
      {isEnoughCredit(brandDb) ?
        <>
          {/*<p>{JSON.stringify(mediaSequence || {})}</p>*/}

          {mediaSequence ?
            <MediaSequenceDisplay
              mediaSequence={mediaSequence}
              setMediaSequence={setMediaSequence}
              brandId={brandId}
              mediaId={mediaId}
            />
            :
            <>
              {/*<p>{JSON.stringify(setSchedule || {})}</p>*/}
              {schedule ?
                <ScheduleDisplay
                  brandId={brandId}
                  schedule={schedule}
                />
                :
                <>
                  {(screenDb && !screenDb.inputId) ?
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <Box>
                        <Logo heightlogo={100}/>
                      </Box>
                      <Box>
                        <h1>{localStrings.noContentScreenDefined}</h1>
                      </Box>
                    </Box>

                    :

                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column'
                      }}
                    >
                      <Box>
                        <Logo heightlogo={100}/>
                      </Box>
                      <Box>
                        <ClipLoaderComponent/>
                      </Box>
                    </Box>

                  }
                </>
              }

            </>
          }
        </>
        :
        <NoCreditCompo/>
      }
    </>
  )
}

export default ScreenDisplay;
