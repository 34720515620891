import React, {createContext, useEffect, useReducer, useState} from 'react';
import firebase from 'src/lib/firebase';
import {
  applyActionCode,
  confirmPasswordReset,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  sendEmailVerification,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  verifyPasswordResetCode
} from "firebase/auth";
import {getFirestore} from "firebase/firestore";
import {Navigate} from "react-router";
import {executeMutationUtil, executeQueryUtil} from "../utils/gqlUtil";
import {getProductUserQuery, updateProductUserMutation} from "../gql/productUserGql";
import {getBrandQuery} from "../gql/BrandGql";
import {SplashScreen} from "../components/splash-screen";
import moment from "moment";
import {getSuperUserWhiteListQuery} from "../gql/superUserWhiteList";
import {getBrandAdminQuery} from "../gql/BrandAdminGql";
import {bulkDeleteTempBrandUserMutation, getTempBrandUserByEmailQuery} from "../gql/tempBrandUserGql";
import cloneDeep from "clone-deep";
import {
  authChangeRoute,
  connectScreen,
  displayEventsFoundRoute,
  emailVerificationRoute,
  loginRoute,
  registerRoute,
  resetPasswordRoute
} from "../routes";
import {FileUploader} from "../sections/dashboard/file-manager/file-uploader";
import {useNavigate} from "react-router-dom";
import {useWindowSize} from "@react-hook/window-size";
import Confetti from "react-confetti";
import {Button, Dialog, DialogActions, DialogContent, DialogTitle} from "@mui/material";
import localStrings from "../localStrings";
import {dashboardBaseRoute, getStartedRoute} from "../routes/dashboard";
import {getStartedHelp} from "../htmlContents/getStartedHelp";

const db = getFirestore();

const initialAuthState = {
  isAuthenticated: false,
  isInitialized: false,
  isSkipPhoneVerification: false,
  userGetAtLeastOnEstablishment: false,
  user: null,
  userInDb: null,
  brand : null,
  superAdmin: false,
  emailVerified: false,
  displayNavBar: true,
  displaySettingButton: true,
  processUserInDb: false,
  logging: false,
  uploadFileOpen: false,
  fileUploaded: false,
  lang: 'EN'
};

const PROCESS_USER_IN_DB = 'PROCESS_USER_IN_DB';
const DISPLAY_SETTING_BUTTON = 'DISPLAY_SETTING_BUTTON';
const DISPLAY_NAV_BAR = 'DISPLAY_NAV_BAR';
const UPDATE_DB_PROFILE = 'UPDATE_DB_PROFILE';
const AUTHENTICATE_TO_APP = 'AUTHENTICATE_TO_APP';
const INITIALIZED = 'INITIALIZED';
const AUTHENTICATED = 'AUTHENTICATED';
const E_MAIL_VERIFIED = 'E_MAIL_VERIFIED';
const BRAND = 'BRAND';
const LANG = 'LANG';
const SUPER_ADMIN = 'SUPER_ADMIN';
const LOGGING = 'LOGGING';
const UPLOAD_FILE_OPEN = 'UPLOAD_FILE_OPEN';
const FILE_UPLOADED = 'FILE_UPLOADED';

const reducer = (state, action) => {
  switch (action.type) {

    case UPLOAD_FILE_OPEN: {
      const { uploadFileOpen } = action.payload;
      return {
        ...state,
        uploadFileOpen: uploadFileOpen,
      };
    }

    case FILE_UPLOADED: {
      const { fileUploaded } = action.payload;
      return {
        ...state,
        fileUploaded: fileUploaded,
      };
    }

    case LOGGING: {
      const { logging } = action.payload;
      return {
        ...state,
        logging: logging,
      };
    }

    case PROCESS_USER_IN_DB: {
      const { processUserInDb } = action.payload;
      return {
        ...state,
        processUserInDb: processUserInDb,
      };
    }

    case DISPLAY_NAV_BAR: {
      const { displayNavBar } = action.payload;
      return {
        ...state,
        displayNavBar: displayNavBar,
      };
    }

    case DISPLAY_SETTING_BUTTON: {
      const { displaySettingButton } = action.payload;
      return {
        ...state,
        displaySettingButton: displaySettingButton,
      };
    }

    case BRAND: {
      const { brand } = action.payload;
      return {
        ...state,
        brand: brand,
      };
    }

    case LANG: {
      const { lang } = action.payload;
      return {
        ...state,
        lang: lang,
      };
    }

    case UPDATE_DB_PROFILE: {
      const { userInDb } = action.payload;
      return {
        ...state,
        userInDb: userInDb,
      };
    }

    case SUPER_ADMIN: {
      const { superAdmin } = action.payload;
      return {
        ...state,
        superAdmin: superAdmin,
      };
    }

    case INITIALIZED: {
      const { isInitialized } = action.payload;
      return {
        ...state,
        isInitialized: isInitialized,
      };
    }

    case AUTHENTICATED: {
      const { isAuthenticated } = action.payload;
      return {
        ...state,
        isAuthenticated: isAuthenticated,
      };
    }

    case E_MAIL_VERIFIED: {
      const { emailVerified } = action.payload;
      return {
        ...state,
        emailVerified: emailVerified,
      };
    }

    default: {
      return { ...state };
    }
  }
};

const AuthContext = createContext({
  ...initialAuthState,
  applyActionCodeContext: () => Promise.resolve(),
  confirmPasswordResetContext: () => Promise.resolve(),
  verifyPasswordResetCodeContext: () => Promise.resolve(),
  sendPasswordResetEmailContext: () => Promise.resolve(),
  createUserWithEmailAndPasswordContext: () => Promise.resolve(),
  signInWithEmailAndPasswordContext: () => Promise.resolve(),
  sendEmailVerificationContext: () => Promise.resolve(),
  signInWithGoogle: () => Promise.resolve(),
  setAuthenticated: () => Promise.resolve(),
  setLogging: () => Promise.resolve(),
  setUploadFileOpen: () => Promise.resolve(),
  setFileUploaded: () => Promise.resolve(),
  setEmailVerified: () => Promise.resolve(),
  setInitialized: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  setContextDbUser: () => Promise.resolve(),
  currentBrand: () => {},
  setBrand: () => Promise.resolve(),
  setLang: () => Promise.resolve(),
  setDisplayNavBar: () => Promise.resolve(),
  setDisplaySettingButton: () => Promise.resolve(),
  toggleProcessUserInDb: () => Promise.resolve(),
  userInRole: () => {},
});

export const startedPopupDisplayed = "startedPopupDisplayed";
export const AuthProvider = ({ children }) => {
  const navigate = useNavigate();
  firebase.useDeviceLanguage();
  const [state, dispatch] = useReducer(reducer, initialAuthState);

  const { width, height } = useWindowSize();
  const [ playConfetti, setPlayConfetti ] = useState(false);
  const [ displayGetStartedPopup, setDisplayGetStartedPopup ] = useState(false);
  const currentBrand = () => {
    return state.brand;
  }

  useEffect(() => {
    let pathName = window.location.pathname;
    //alert(pathName)
    if (pathName.startsWith("/dashboard") &&
      !localStorage.getItem(startedPopupDisplayed)) {
      setDisplayGetStartedPopup(true);
    }
  }, [])

  const setDisplayedGetStartedPopup = () => {
    //localStorage.setItem(startedPopupDisplayed, "true");
    setDisplayGetStartedPopup(false)
  }

  const sendPlayConfetti = () => {
    setPlayConfetti(true);
    setTimeout(() => {
      setPlayConfetti(false)
    }, 5000)
  };

  const signInWithGoogle = () => {
    const provider = new GoogleAuthProvider();
    return signInWithPopup(firebase, provider);
  };

  const createUserWithEmailAndPasswordContext = async (email, password) => {
    return createUserWithEmailAndPassword(firebase, email, password);
  };

  const applyActionCodeContext = async (actionCode) => {
    return applyActionCode(firebase, actionCode);
  };

  const confirmPasswordResetContext = async (actionCode, newPassword) => {
    return confirmPasswordReset(firebase, actionCode, newPassword);
  };

  const verifyPasswordResetCodeContext = async (actionCode) => {
    return verifyPasswordResetCode(firebase, actionCode);
  };

  const sendPasswordResetEmailContext = async (email) => {
    return sendPasswordResetEmail(firebase, email);
  };

  const signInWithEmailAndPasswordContext = async (user, password) => {
    return signInWithEmailAndPassword(firebase, user, password)
  }

  const sendEmailVerificationContext = async () => {
    return sendEmailVerification(firebase.currentUser);
  };


  const updateProfile = async (profileInfo) => {
    var currentUser = await firebase.currentUser;
    if (currentUser) {
      await currentUser.updateProfile(profileInfo);
    }
  };

  const setAuthenticated = async (authenticated) => {
    dispatch({
      type: AUTHENTICATED,
      payload: {
        isAuthenticated: authenticated,
      }
    });
  };

  const setLogging = async (logging) => {
    dispatch({
      type: LOGGING,
      payload: {
        logging: logging,
      }
    });
  };

  const setUploadFileOpen = async (uploadFileOpen) => {
    dispatch({
      type: UPLOAD_FILE_OPEN,
      payload: {
        uploadFileOpen: uploadFileOpen,
      }
    });
  };

  const setFileUploaded = async (fileUploaded) => {
    dispatch({
      type: FILE_UPLOADED,
      payload: {
        fileUploaded: fileUploaded,
      }
    });
  };

  const setEmailVerified = async (emailVerified) => {
    dispatch({
      type: E_MAIL_VERIFIED,
      payload: {
        emailVerified: emailVerified,
      }
    });
  };

  const setInitialized = async (initialized) => {
    dispatch({
      type: INITIALIZED,
      payload: {
        isInitialized: initialized,
      }
    });
  };


  const setBrand = async (brand) => {
    dispatch({
      type: BRAND,
      payload: {
        brand: brand,
      }
    });
  };

  const setLang = async (lang) => {
    dispatch({
      type: LANG,
      payload: {
        lang: lang,
      }
    });
  };

  const setDisplayNavBar = async (displayNavBar) => {
    dispatch({
      type: DISPLAY_NAV_BAR,
      payload: {
        displayNavBar: displayNavBar,
      }
    });
  };

  const setDisplaySettingButton = async (displaySettingButton) => {
    dispatch({
      type: DISPLAY_SETTING_BUTTON,
      payload: {
        displaySettingButton: displaySettingButton,
      }
    });
  };

  const toggleProcessUserInDb = async () => {
    dispatch({
      type: PROCESS_USER_IN_DB,
      payload: {
        processUserInDb: !state.processUserInDb,
      }
    });
  };

  const userInRole = (role) => {
    return (state.userInDb?.roles || []).includes(role);
  }

  const currentUser = () => {
    var user = firebase.currentUser;
    return user;
  };


  function setContextDbUser(userInDb) {
    dispatch({
      type: UPDATE_DB_PROFILE,
      payload: {
        userInDb: userInDb,
      }
    });
  }

  function setUserSuperAdmin(value) {
    dispatch({
      type: SUPER_ADMIN,
      payload: {
        superAdmin: value,
      }
    });
  }



  const logout = async () => {
    await setContextDbUser(null);
    return firebase.signOut();
  };


  async function processUserInDb(user) {
    //alert("processUserInDb ")
    const resSuperAdmins = await executeQueryUtil(getSuperUserWhiteListQuery())
    const allSuperAdminEmails = (resSuperAdmins?.data?.getSuperUsers || []).map(item => item.email);
    let isSuperAdmin = allSuperAdminEmails.includes(user.email);
    setUserSuperAdmin(isSuperAdmin);
    //alert("user.uid " + user.uid)
    const res = await executeQueryUtil(getProductUserQuery(user.uid));
    setContextDbUser(res?.data?.getProductUser);
    let userDb = res?.data?.getProductUser;
    //alert("userDb " + JSON.stringify(userDb || {}))
    if (!userDb && user.email.endsWith("gmail.com")) {
      window.location.reload();
    }
    if (userDb) {
      let resUser = await executeQueryUtil(getTempBrandUserByEmailQuery(user.email));
      let tempBrandUser = resUser?.data?.getTempBrandUserByEmail;
      if (tempBrandUser && tempBrandUser.id && user.emailVerified) {
        let userDbClone = cloneDeep(userDb)
        if (!userDb.brandId) {
          userDbClone.brandId = tempBrandUser.brandId;
          userDbClone.mainUser = false;
          let resUpdateUser = await executeMutationUtil(updateProductUserMutation(userDbClone));
          userDb = resUpdateUser.data.updateProductUser;
        }
        if (!(userDbClone?.allowedBrandIds || []).includes(tempBrandUser.brandId)) {
          if (!userDbClone.allowedBrandIds) {
            userDbClone.allowedBrandIds = [];
          }
          userDbClone.allowedBrandIds.push(tempBrandUser.brandId);
          let resUpdateUser = await executeMutationUtil(updateProductUserMutation(userDbClone));
          userDb = resUpdateUser.data.updateProductUser;
        }
        //alert("delete temp user " + tempBrandUser.id)
        await executeMutationUtil(bulkDeleteTempBrandUserMutation([tempBrandUser.id]));
        //alert("userDb.brandId " + userDb.brandId)
        setContextDbUser(userDbClone);
        //window.location.reload();
        //return;
      }
      //alert("userDb.brandId " + userDb.brandId)
      if (userDb.brandId) {

        let resBrand;
        if (isSuperAdmin) {
          resBrand = await executeQueryUtil(getBrandAdminQuery(userDb.brandId));
          await setBrand(resBrand?.data?.getBrandAdmin);
          if (!isPathAllowedNotLogged(window.location.pathname)) {
            navigate('/dashboard', { replace: true });
          }

        } else {
          resBrand = await executeQueryUtil(getBrandQuery(userDb.brandId));
          //alert("resBrand " + JSON.stringify(resBrand?.data?.getBrand))
          await setBrand(resBrand?.data?.getBrand);
          if (!isPathAllowedNotLogged(window.location.pathname)) {
            navigate('/dashboard', { replace: true });
          }
        }
      }
    }
  }

  useEffect(() => {
    if (currentUser()) {
      processUserInDb(currentUser())
    }
  }, [state.processUserInDb])

  useEffect(() => {
    const unsubscribe = firebase.onAuthStateChanged(async (user) => {
      if (user) {
        if (!firebase.currentUser) {
          return
        }
        try {
          let token = await firebase.currentUser.getIdToken(true)
          localStorage.setItem("authToken", token)
          localStorage.setItem("genTimeToken", moment().unix())
        }
        catch (err) {
          console.log(err);
        }

        await processUserInDb(user);

        setAuthenticated(true);
        setEmailVerified(user.emailVerified)
      } else {
        localStorage.removeItem("authToken")
        setAuthenticated(false)
      }

      setInitialized(true)
    });

    return unsubscribe;
  }, [dispatch]);

  function isPathAllowedNotLogged(pathname) {
    if (pathname.startsWith('/displayEvents')) {
      return true;
    }

    // if (pathname.startsWith('/displayEvents')) {
    //   return true;
    // }

    return [
      "/" + authChangeRoute,
      "/" + authChangeRoute,
      "/" + resetPasswordRoute,
      "/" + loginRoute,
      "/" + connectScreen,
      "/" + emailVerificationRoute,
      "/" + registerRoute,
      // "/app/" + accountRoute,
      //"/" + graphQlRoute,
      "/" + displayEventsFoundRoute,
      "/" + displayEventsFoundRoute,
      "/screenDisplay",
    ].find(p => {
      return pathname.startsWith(p)
    }) != null
  }

  // if (!state.isInitialized || state.logging) {
  //   return <SplashScreen />;
  // }

  if (!state.isInitialized) {
    return <SplashScreen />;
  }

  if ((!state.isAuthenticated || !state.emailVerified) && !isPathAllowedNotLogged(window.location.pathname)) {
    return <Navigate to={"/login"}/>
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        signInWithGoogle,
        verifyPasswordResetCodeContext,
        confirmPasswordResetContext,
        sendPasswordResetEmailContext,
        createUserWithEmailAndPasswordContext,
        signInWithEmailAndPasswordContext,
        sendEmailVerificationContext,
        applyActionCodeContext,
        logout,
        currentUser,
        updateProfile,
        setAuthenticated,
        setLogging,
        setEmailVerified,
        setInitialized,
        setContextDbUser,
        currentBrand,
        setBrand,
        userInRole,
        setDisplayNavBar,
        setDisplaySettingButton,
        toggleProcessUserInDb,
        setFileUploaded,
        setUploadFileOpen,
        sendPlayConfetti,
        setLang
      }}
    >

      <Dialog open={displayGetStartedPopup} onClose={() => setDisplayGetStartedPopup(false)}>
        <DialogTitle>{localStrings.getStarded}</DialogTitle>
        <DialogContent>

          <div
            dangerouslySetInnerHTML={{__html: getStartedHelp}}
          />
        </DialogContent>

        <DialogActions>
          <Button onClick={() => {
            setDisplayedGetStartedPopup();
          }}
          color="primary"
          autoFocus
          >
            {localStrings.cancel}
          </Button>
          <Button
            onClick={() => {
              setDisplayedGetStartedPopup();
              navigate("/" + dashboardBaseRoute + "/" + getStartedRoute)
            }}
            color="primary"
            //disabled={checkBoxesSelected.length == 0}
          >
            {localStrings.view}
          </Button>
        </DialogActions>
      </Dialog>


      {currentBrand() &&
        <FileUploader
          brandId={currentBrand().id}
          onClose={() => {
            alert("onClose");
            setUploadFileOpen(false);
          }}
          open={true}
          onUpload={(files) => {
            setFileUploaded(!state.fileUploaded)
          }}
        />
      }
      {children}

      {playConfetti &&
        <Confetti
          width={width}
          height={height}
        />
      }
    </AuthContext.Provider>
  );
};

export default AuthContext;
