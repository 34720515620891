import React, {useEffect, useRef, useState} from 'react';
import {useParams} from 'react-router-dom';
import useAuth from "../../../hooks/useAuth";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import localStrings from "../../../localStrings";
import {useNavigate} from "react-router";
import {
  addDataMediaSequenceByBrandIdToCache,
  getMediaSequenceQuery,
  updateMediaSequenceMutation
} from "../../../gql/mediaSequenceGql";
import * as Yup from "yup";
import cloneDeep from "clone-deep";
import config from "../../../conf/config.json";
import axios from "axios";
import Box from "@mui/material/Box";
import {DurationPicker} from "material-duration-picker";
import {formatDuration} from "date-fns";
import {
  Button, Card, CardActions, CardContent,
  CardHeader,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField
} from "@mui/material";
import useAppUtil from "../../../hooks/useAppUtil";
import ItemSequenceSelector, {
  sequenceBoardType, sequenceGoogleDrivePresentationType,
  sequenceGoogleDriveType,
  sequenceLayoutType,
  sequenceMediaType, sequenceWebPageType, sequenceWidgetType,
  sequenceYouTubeType
} from "../../../utils/ItemSequenceSelector";
import CloseIcon from '@mui/icons-material/Close';
import useDrivePicker from "react-google-drive-picker";
import {fileNotShared} from "../../../htmlContents/fileNotShared";
import {mimeTypeNotSupported} from "../../../htmlContents/mimeTypeNotSupported";
import ReactPlayer from "react-player/lazy";
import {publishOnTheWeb} from "../../../htmlContents/publishOnTheWeb";
import {default_sequence_duration} from "../../ScreenDisplay/screenDisplay";
import enqueueSnackbarWithSound from "../../../utils/SnackBarWithSound";
import toast from "react-hot-toast";
import DrawerSequenceEditor, {isValidUrl} from "./DrawerSequenceEditor";
import DialogContentText from "@mui/material/DialogContentText";
import DragNDropPresenter from "./DragNDropPresenter";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import {not_found_path} from "../../../routes";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import {getUrlDashboard} from "../../Boards/BoardAddView/BoardCreateUpdateForm";

const { uuid } = require('uuidv4');

export const googlePresentationType = 'application/vnd.google-apps.presentation';
export const pptPresentationType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation';

const apiKey= 'AIzaSyDJMNf1nieH7xZJYWMBHoVPqiACSCgIFKQ';
const clientId= '467057143201-idkrj67tinuthcoho3qc8cino1utkcab.apps.googleusercontent.com';

export const split_type_horizontal = "horizontal";
export const split_type_vertical = "vertical";

const MediaList = () => {
  const [mediaSequenceEdited, setMediaSequenceEdited] = useState(null);
  const [webLinkDialogOpen, setWebLinkDialogOpen] = useState(false);
  const [deletedSequence, setDeletedSequence] = useState(null);
  const [tempSelectedFile, setTempSelectedFile] = useState(null);
  const [selectedSequenceId, setSelectedSequenceId] = useState(null);
  const [secondSequence, setSecondSequence] = useState(false);
  const [loadingGoogle, setLoadingGoogle] = useState(false);
  const [webLink, setWebLink] = useState("");
  const {setDisplayNavBar} = useAuth();
  const [openPicker] = useDrivePicker();
  const handleOpenPicker = () => {
    openPicker({
      clientId: clientId,
      developerKey: apiKey,
      viewId: "DOCS",
      // token: token, // pass oauth token in case you already have one
      showUploadView: false,
      showUploadFolders: true,
      supportDrives: true,
      multiselect: true,
      //customViews: customViewsArray, // custom view
      callbackFunction: (data) => {
        if (data.action === 'cancel') {
          console.log('User clicked cancel/close button')
        }
        if (data.action === 'picked'){
          (data?.docs || []).forEach(f => {
            setLoadingGoogle(true);
            addSelectedFile(f)
          })
        }
        if (data.action != 'loaded') {
          setDisplayNavBar(true);
        }
        else {
          setDisplayNavBar(false);
        }

        console.log(data)
      },
    })
  }

  const [dialogSelectOpen, setDialogSelectOpen] = useState(false);

  const {addError} = useAppUtil();

  let {mediaSequenceId} = useParams();
  const { currentBrand } = useAuth();
  const navigate = useNavigate();
  const [mediaSequenceItem, setMediaSequenceItem] = useState(null);

  async function processTriggerSave() {
    let validationOk = true;
    let sequences = cloneDeep(mediaSequenceItem.sequences);
    (sequences || []).forEach(s => {
      delete s.thumbnailLink;
      if (s.mimeType === googlePresentationType && !s.urlWebLink) {
        if (addError) {
          addError(localStrings.formatString(localStrings.errorMessage.presShouldHaveWebLink, s.name));
        }
        validationOk = false;
      }
    })

    if (!validationOk) {
      return;
    }

    let res = await executeQueryUtil(getMediaSequenceQuery(currentBrand().id, mediaSequenceItem.id));

    let data = {
      ...res.data?.getMediaSequence,
      sequences: sequences
    };
    await executeMutationUtil(updateMediaSequenceMutation(currentBrand().id, data));
    //await addDataMediaSequenceByBrandIdToCache(currentBrand().id, [data]);
    enqueueSnackbarWithSound(toast, null, false, localStrings.notif.mediaSequenceUpdated);
  }

  const loadMediaSequence = async () => {
    var res =  await executeQueryUtil(getMediaSequenceQuery(currentBrand().id, mediaSequenceId));

    if (!res.data?.getMediaSequence) {
      navigate('/' + not_found_path);
      return;
    }
    setMediaSequenceItem(res.data?.getMediaSequence)
  }

  useEffect(() => {
    loadMediaSequence();
  }, [])

  useEffect(() => {
    if (mediaSequenceItem && mediaSequenceItem.save) {
      processTriggerSave();
      mediaSequenceItem.save = false;
      setMediaSequenceItem(cloneDeep(mediaSequenceItem));
    }
  }, [mediaSequenceItem])

  const buildFormValues = (item) => {
    return {
      initialValues: {
        id: item.id || '',
        name: item.name || '',
        backgroundColor: item.backgroundColor || 'FFFFFF',
        //localPrinterIp: item.localPrinterIp || '',
        // project: projectItems.find(p => p.id === item.projectId),
      },
      validationSchema: {
        name: Yup.string().max(255).required(localStrings.check.fieldRequired),
      }
    };
  }

  const dragEnd = (result) => {
    console.log(result)

    const sourceId = result.draggableId;
    if (!result.destination) {
      return;
    }
    const destinationIndex = result.destination.index;

    let sequencesClone = cloneDeep(mediaSequenceItem.sequences);

    const movedItem = cloneDeep(sequencesClone.find(item => item.id === sourceId));
    const oldIndex = sequencesClone.findIndex(item => item.id == movedItem.id);

    if (destinationIndex === oldIndex) {
      return;
    }
    sequencesClone.splice(oldIndex, 1);
    sequencesClone.splice(Math.max(destinationIndex, 0), 0, movedItem);

    setMediaSequenceItem( cloneDeep({
      ...mediaSequenceItem,
      sequences: sequencesClone
    }))
  }


  async function getGoogleFileInfo(id) {
    const url = config.googleDocServiceUrl + "/googleFileSharedDetail/" + id;
    try {
      const res = await axios.get(url,{
        headers: {
          'Content-Type': 'application/json',
        }
      })

      return res.data;
    }
    catch (error) {
      console.log(error);
    }
    return null;
  }


  async function checkFileShared(file) {
    if (!file.id) {
      return true;
    }
    const url = config.googleDocServiceUrl + "/filterFilesShared/";
    try {
      const res = await axios.post(url, {
        files: [file]
      },{
        headers: {
          'Content-Type': 'application/json',
        }
      })
      console.log("filterFilesShared " + JSON.stringify(res?.data || {}))
      const filtered = res?.data;
      return filtered && res?.data.length > 0;
    }
    catch (error) {
      console.log(error);
    }
    return false;
  }

  const saveSettings = () => {
    processTriggerSave();
  }


  function setFieldValue(itemSequence, fieldName, value) {
    let sequencesClone = cloneDeep(mediaSequenceItem.sequences);
    let itemSequenceClone = cloneDeep(itemSequence);
    itemSequenceClone[fieldName] = value;
    const index = sequencesClone.findIndex(item => item.id == itemSequence.id);

    sequencesClone.splice(index, 1, itemSequenceClone);

    setMediaSequenceItem( cloneDeep({
      ...mediaSequenceItem,
      sequences: sequencesClone
    }))
  }

  function getFileComponent(ids) {
    if (ids.length !== 1) {
      return;
    }
    const id = ids[0];
    const itemSequence = mediaSequenceItem.sequences.find(s => s.id == id);
    const mimeType = itemSequence.mimeType;
    let label = localStrings.duration;
    if (mimeType === googlePresentationType)
    {
      label = localStrings.durationForEachSlide;
    }
    return(
      <div style={{width: '100%'}}>
        <Grid
          item
          md={12}
          xs={12}
        >
          <Box mt={2}>
            <DurationPicker
              fullWidth
              //TextFieldComp={<h1>TEST</h1>}
              style={{color: "inherit"}}
              placeholder={itemSequence.type === googlePresentationType ? localStrings.fullDurationVideo : null}
              views={['hours', 'minutes', 'seconds']}
              label={itemSequence.type === sequenceYouTubeType ? localStrings.fullDurationVideo : label}
              value={itemSequence.duration || (itemSequence.type === sequenceYouTubeType ? 0 :default_sequence_duration)}
              onValueChange={(v) => setFieldValue(itemSequence, 'duration', v)}
              formatDuration={(duration) =>
                formatDuration(duration)
              }
            />
          </Box>
        </Grid>

        {(mimeType === googlePresentationType || mimeType === pptPresentationType) &&
          <Grid
            item
            md={12}
            xs={12}
          >
            <Box mt={2}>
              <TextField
                style={{marginBottom: 10}}
                inputProps={{
                  autocomplete: "new-password"
                }}
                //error={Boolean(touched.name && errors.name)}
                fullWidth
                //helperText={touched.name && errors.name}
                label={localStrings.urlWebLink}
                name="urlWebLink"
                //onBlur={handleBlur}
                onChange={(event) => setFieldValue(itemSequence, 'urlWebLink', event.target.value)}
                value={itemSequence.urlWebLink}
                variant="outlined"
              />
            </Box>
          </Grid>
        }
      </div>
    );
  }

  async function updateSequences(sequences) {
    let mediaSequenceItemClone = cloneDeep(mediaSequenceItem);
    for (const sequence of sequences) {
      delete sequence.save;
    }
    mediaSequenceItemClone.sequences = sequences
    const resUpdate = await executeMutationUtil(updateMediaSequenceMutation(currentBrand().id, mediaSequenceItemClone));
    setMediaSequenceItem(mediaSequenceItemClone);
    enqueueSnackbarWithSound(toast, null, false, localStrings.notif.mediaSequenceUpdated);
  }

  function getNextIndex() {
    if (!mediaSequenceItem.sequences || mediaSequenceItem.sequences.length == 0) {
      return 0;
    }
    const indexes = mediaSequenceItem.sequences.map(sequence => sequence.index);
    return Math.max(indexes) + 1;
  }

  function addNewSequence() {
    let mediaSequenceItemClone = cloneDeep(mediaSequenceItem);
    mediaSequenceItemClone.sequences = [...(mediaSequenceItemClone.sequences || []), {
      id: uuid(),
      //index: getNextIndex()
    }]
    setMediaSequenceItem({...mediaSequenceItemClone, save: true});
  }

  function updateMediaSequenceKeyValue(id, key, value) {
    let mediaSequenceItemClone = cloneDeep(mediaSequenceItem);
    const indexSequ = mediaSequenceItemClone.sequences.findIndex(seq => seq.id === id);
    const item = mediaSequenceItemClone.sequences.find(seq => seq.id === id);
    item[key] = value;
    mediaSequenceItemClone.sequences.splice(indexSequ, 1, item);
    setMediaSequenceItem({...mediaSequenceItemClone, save: true});
  }

  function onView(id) {
    window.open('/screenDisplaySequence/' + currentBrand().id + '/' +
      mediaSequenceItem.id + "?mediaId=" + id, '_blank').focus()
    //updateMediaSequenceKeyValue(id, "splitType", null)
  }

  function undoSplit(id) {
    updateMediaSequenceKeyValue(id, "splitType", null)
  }

  function splitVertically(id) {
    const item = mediaSequenceItem.sequences.find(seq => seq.id === id);
    if (!item.splitPercent) {
      updateMediaSequenceKeyValue(id, "splitPercent", 50)
    }
    updateMediaSequenceKeyValue(id, "splitType", split_type_vertical)
  }

  function splitHorizontally(id) {
    const item = mediaSequenceItem.sequences.find(seq => seq.id === id);
    if (!item.splitPercent) {
      updateMediaSequenceKeyValue(id, "splitPercent", 50)
    }
    updateMediaSequenceKeyValue(id, "splitType", split_type_horizontal)
  }

  function setSplitPercent(id, value) {
    updateMediaSequenceKeyValue(id, "splitPercent", value)
  }

  function editMainSequence(id) {
    //alert("editMainSequence " + id)
    const item = mediaSequenceItem.sequences.find(seq => seq.id === id || seq.secondSequence?.id === id);
    if (item.type === sequenceWidgetType) {
      navigate('/dashboard/widgets/detail/' + item.widgetId + '?urlBack=' +
        encodeURI(window.location.href) + '&urlBackTitle=' +
        localStrings.formatString(localStrings.backUrl, mediaSequenceItem.name || localStrings.mediaSequence)
        , { replace: true } );
    }
    if (item.type === sequenceLayoutType) {
      navigate('/dashboard/layouts/detail/' + item.layoutId + '?urlBack=' +
        encodeURI(window.location.href) + '&urlBackTitle=' +
        localStrings.formatString(localStrings.backUrl, mediaSequenceItem.name || localStrings.mediaSequence)
        , { replace: true } );
    }
    if (item.type === sequenceBoardType) {
      navigate('/dashboard/boards/detail/' + item.boardId + '?urlBack=' +
        encodeURI(window.location.href) + '&urlBackTitle=' +
        localStrings.formatString(localStrings.backUrl, mediaSequenceItem.name || localStrings.mediaSequence)
        , { replace: true } );
    }

  }

  function editSecondSequence(id) {
    alert("editSecondSequence" + id)
  }

  function addSequenceMain(id) {
    setSelectedSequenceId(id);
    setDialogSelectOpen(true);
  }

  function addSequenceSecond(id) {
    //alert("addSequenceSecond " + id);
    setSelectedSequenceId(id);
    setDialogSelectOpen(true);
    setSecondSequence(true);
  }

  function duplicate(sequence) {
//    alert("duplicate" + JSON.stringify(sequence));

    let mediaSequenceItemClone = cloneDeep(mediaSequenceItem);
    const indexSequ = mediaSequenceItemClone.sequences.findIndex(seq => seq.id === sequence.id);
    //const item = mediaSequenceItemClone.sequences.find(seq => seq.id === sequence.id);
    let sequenceClone = cloneDeep(sequence);
    if (sequenceClone.displayName) {
      sequenceClone.displayName += localStrings.copyExt;
    }
    sequenceClone.id = uuid();
    mediaSequenceItemClone.sequences.splice(indexSequ + 1, 0, sequenceClone);
    setMediaSequenceItem({...mediaSequenceItemClone, save: true});
  }

  function getUrlThumbNail(file) {
    return file.iconUrl || "";
  }

  function isFileRequiresWebLink(file) {
    return file.mimeType === googlePresentationType;
    //qdsqsd
  }

  async function addSelectedFile(item, urlThumbNail) {
    let fileShared = await checkFileShared(item);
    if (! fileShared ) {
      setLoadingGoogle(false)
      addError(localStrings.warningMessage.fileNotShared,
        "warning" ,
        fileNotShared,
        "/static/videos/video1.mp4"
      );

      return;
    }
    if (isFileRequiresWebLink(item) && !item.urlWebLink) {
      setLoadingGoogle(false)
      //alert("FileRequiresWebLink");
      setTempSelectedFile(item);
      setWebLinkDialogOpen(true);
      return;
    }

    let mediaSequenceItemClone = cloneDeep(mediaSequenceItem);
    let mediaSequenceIndex = mediaSequenceItemClone.sequences.findIndex(s => s.id === selectedSequenceId)
    let mediaSequence = mediaSequenceItemClone.sequences.find(s => s.id === selectedSequenceId);

    if (!secondSequence) {
      mediaSequence = {
        ...mediaSequence,
        googleDocId: item.id,
        urlThumbNail: urlThumbNail || getUrlThumbNail(item),
        contentName: item.name,
        mimeType: item.mimeType,
        duration: item.duration,
        type: item.type,
        urlWebLink: item.urlWebLink,
        layoutId: item.layoutId,
        widgetId: item.widgetId,
        boardId: item.boardId,
      }
    }
    else {
      mediaSequence = {
        ...mediaSequence,
        secondSequence: {
          googleDocId: item.id,
          urlThumbNail: urlThumbNail || getUrlThumbNail(item),
          contentName: item.name,
          mimeType: item.mimeType,
          duration: item.duration,
          type: item.type,
          urlWebLink: item.urlWebLink,
          layoutId: item.layoutId,
          widgetId: item.widgetId,
          boardId: item.boardId,
        }
      }
    }
    setSecondSequence(false);
    mediaSequenceItemClone.sequences.splice(mediaSequenceIndex, 1, mediaSequence);
    setMediaSequenceItem({...mediaSequenceItemClone, save: true});
    setLoadingGoogle(false)
  }

  async function mediaSequenceAddCallBack(item) {
    setDialogSelectOpen(false);

    if (item.sequenceType === sequenceGoogleDrivePresentationType) {
      await addSelectedFile({
        type: sequenceWebPageType,
        mimeType: googlePresentationType,
        name: localStrings.googleSlide,
        urlWebLink: item.urlSelected,
      }, "/static/images/sequences/Google_Slides_logo.png")
    }


    if (item.sequenceType === sequenceGoogleDriveType) {
      const googleId = item.urlSelected.substring(item.urlSelected.indexOf("/d/") + 3, item.urlSelected.lastIndexOf("/"));
      const res = await getGoogleFileInfo(googleId);

      if (res == null) {
        addError(localStrings.warningMessage.bad,
          "warning" ,
          fileNotShared,
          "/static/videos/shareGoogle.mp4"
        );
        return;
      }

      if ( !res.mimeType.startsWith("image") && !res.mimeType.startsWith("video")) {
        addError(localStrings.warningMessage.invalidMimeType,
          "warning" ,
          mimeTypeNotSupported
        );
        return;
      }


      await addSelectedFile({
        id: res.id,
        urlThumbNail: res.iconLink,
        contentName: res.name,
        name: res.name,
        mimeType: res.mimeType,
        type: "googleDoc"
      }, res.iconLink)

    }



    if (item.sequenceType === sequenceYouTubeType) {
      await addSelectedFile({
        type: item.sequenceType,
        name: item.urlSelected,
        urlWebLink: item.urlSelected,
      }, "/static/images/sequences/1384060.png")
    }

    if (item.sequenceType === sequenceWebPageType) {
      await addSelectedFile({
        type: item.sequenceType,
        name: item.urlSelected,
        urlWebLink: item.urlSelected,
      }, "/static/images/sequences/webpage-svgrepo-com.svg")
    }


    if (item.sequenceType === sequenceLayoutType) {
      await addSelectedFile({
        type: item.sequenceType,
        name: item.selectedLayout.name,
        urlWebLink: item.selectedLayout.displayUrl,
        layoutId: item.selectedLayout.id,
      }, "/static/images/sequences/layout-2-svgrepo-com.svg")
    }

    if (item.sequenceType === sequenceBoardType) {
      await addSelectedFile({
        type: item.sequenceType,
        name: item.selectedLayout.name,
        urlWebLink: getUrlDashboard(currentBrand().id, item.selectedLayout.id),
        boardId: item.selectedLayout.id,
      }, "/static/images/sequences/board-svgrepo-com.svg")
    }

    if (item.sequenceType === sequenceWidgetType) {
      await addSelectedFile({
        type: item.sequenceType,
        name: item.selectedWidget.name,
        urlWebLink: item.selectedWidget.displayUrl,
        widgetId: item.selectedWidget.id,
      }, "/static/images/sequences/widget-alt-svgrepo-com.svg")
    }

    if (item.sequenceType === sequenceMediaType) {
      await addSelectedFile({
        type: item.sequenceType,
        name: item.selectedMedia.fileName,
        urlWebLink: item.selectedMedia.url,
      }, "/static/images/sequences/images-svgrepo-com.svg")
    }

  }

  async function updateSingleSequence(mediaSequence) {
    let mediaSequenceItemClone = cloneDeep(mediaSequenceItem);
    delete mediaSequenceItemClone.save;
    const index = mediaSequenceItemClone.sequences.findIndex(s => s.id === mediaSequence.id);
    if (index >= 0) {
      mediaSequenceItemClone.sequences.splice(index, 1, mediaSequence);
      await executeMutationUtil(updateMediaSequenceMutation(currentBrand().id, mediaSequenceItemClone));
    }
    setMediaSequenceItem(mediaSequenceItemClone);
    setMediaSequenceEdited(null);
  }

  async function deleteSequence() {
    if (deletedSequence?.id) {
      let mediaSequenceItemClone = cloneDeep(mediaSequenceItem);
      delete mediaSequenceItemClone.save;
      mediaSequenceItemClone.sequences = mediaSequenceItemClone.sequences.filter(s => s.id !== deletedSequence?.id);
      await executeMutationUtil(updateMediaSequenceMutation(currentBrand().id, mediaSequenceItemClone));
      setMediaSequenceItem(mediaSequenceItemClone);
      enqueueSnackbarWithSound(toast, null, false, localStrings.notif.mediaSequenceUpdated);
    }
    setDeletedSequence(null);
  }

  async function onHide(sequenceId) {
      let mediaSequenceItemClone = cloneDeep(mediaSequenceItem);
      delete mediaSequenceItemClone.save;
      const sequenceToUpdate = mediaSequenceItemClone.sequences.find(s => s.id === sequenceId);
      sequenceToUpdate.hide = !sequenceToUpdate.hide;
      await executeMutationUtil(updateMediaSequenceMutation(currentBrand().id, mediaSequenceItemClone));
      setMediaSequenceItem(mediaSequenceItemClone);
      enqueueSnackbarWithSound(toast, null, false, localStrings.notif.mediaSequenceUpdated);
  }

  return (
    <>
      <Dialog
        open={dialogSelectOpen}
      >
        <DialogTitle>
          <div style={{ width: '100%' }}>
            <Box
              sx={{ display: 'flex', flexDirection: 'row'}}
            >
              <Box sx={{ flexGrow: 1 }}>
                {localStrings.selectItem}
              </Box>
              <Box>
                <IconButton onClick={() => setDialogSelectOpen(false)}>
                  <CloseIcon/>
                </IconButton>
              </Box>
            </Box>
          </div>
        </DialogTitle>

        <DialogContent>
          <ItemSequenceSelector sequenceAddCallBack={(sequence) => mediaSequenceAddCallBack(sequence)}/>
          {/*<p>toto</p>*/}
        </DialogContent>
      </Dialog>

      <Dialog open={webLinkDialogOpen} onClose={() => setWebLinkDialogOpen(false)}>
        <DialogTitle>{localStrings.provideWebLink}</DialogTitle>
        <DialogContent>

          <div
            dangerouslySetInnerHTML={{__html: publishOnTheWeb}}
          />


          <div style={{
            textAlign: 'center',
            backgroundColor: 'black'
          }}>
            <ReactPlayer
              loop={true}
              playing={true}
              muted={true}
              width={'531px'}
              url={"/static/videos/video2.mp4"} />
          </div>

          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={localStrings.urlAddress}
            helperText={!isValidUrl(webLink) && localStrings.check.wrongUrlValue}
            error={!isValidUrl(webLink)}
            type="email"
            fullWidth
            value={webLink}
            onChange={(e) => setWebLink(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setWebLinkDialogOpen(false)}>{localStrings.cancel}</Button>
          <Button
            disabled={!isValidUrl(webLink)}
            onClick={() => {
              setWebLinkDialogOpen(false)
              addSelectedFile({
                ...tempSelectedFile,
                urlWebLink: webLink
              })
            }}>{localStrings.ok}</Button>
        </DialogActions>
      </Dialog>
      <DrawerSequenceEditor
        updateMediaSequence={(mediaSequence) => updateSingleSequence(mediaSequence)}
        mediaSequenceEdited={mediaSequenceEdited}
        setMediaSequenceEdited={setMediaSequenceEdited}
      />


      <Dialog
        open={deletedSequence}
        onClose={() => setDeletedSequence(null)}
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            {localStrings.confirmMessage.deleteItem}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeletedSequence(null)} color="primary">
            {localStrings.cancel}
          </Button>
          <Button onClick={async () => await deleteSequence()} color="primary">
            {localStrings.confirm}
          </Button>
        </DialogActions>
      </Dialog>

      <Card
        container
        spacing={4}
      >
        <Grid
          item
          md={6}
          xs={12}
        >
          <CardHeader title={localStrings.sequences}/>
        </Grid>
        <CardContent>

          <Box
            sx={{
              backgroundColor: (theme) => theme.palette.mode === 'dark'
                ? 'neutral.900'
                : 'neutral.100',
              borderRadius: 0
            }}
          >

            <DragNDropPresenter
              editMainSequence={(id) => editMainSequence(id)}
              editSecondSequence={(id) => editMainSequence(id)}
              addSequenceMain={(id) => addSequenceMain(id)}
              addSequenceSecond={(id) => addSequenceSecond(id)}
              duplicate={(sequence) => duplicate(sequence)}
              undoSplit={(id) => undoSplit(id)}
              onView={(id) => onView(id)}
              onHide={(id) => onHide(id)}
              splitVertically={(id) => splitVertically(id)}
              splitHorizontally={(id) => splitHorizontally(id)}
              setSplitPercent={(id, value) => setSplitPercent(id, value)}
              mediaSequences={mediaSequenceItem?.sequences || []}
              onDropUpdate={(sequences) => updateSequences(sequences)}
              onClickMediaSequence={(mediaSequenceEdited) => setMediaSequenceEdited(mediaSequenceEdited)}
              onDelete={(mediaSequence) => setDeletedSequence(mediaSequence)}
            >

            </DragNDropPresenter>
          </Box>

          <CardActions>
            <Box display="flex" justifyContent="flex-end" sx={{width: '100%'}}>
              <Button
                //onClick={() => setDialogSelectOpen(true)}
                onClick={addNewSequence}
                color="primary"
                variant="contained"
                style={{marginLeft: "2px"}}
              >
                {localStrings.addNewSequence}
              </Button>

              <Button
                endIcon={<RemoveRedEyeIcon />}
                variant="contained"
                color="primary"
                style={{marginLeft: "2px"}}
                onClick={() =>
                  window.open('/screenDisplaySequence/' + currentBrand().id + '/' + mediaSequenceId, '_blank').focus()
                }
              >
                {localStrings.preview}
              </Button>
            </Box>
          </CardActions>

        </CardContent>
      </Card>
      <Dialog open={loadingGoogle}>
        <DialogContent sx={{ m: 5, overflow: 'hidden' }}>
          <ClipLoaderComponent/>
        </DialogContent>
      </Dialog>
    </>
  )
};

export default MediaList;
