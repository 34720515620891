import React from 'react';
import localStrings from "../../../localStrings";
import AceEditor from "react-ace";
import "brace/mode/html";
import "brace/theme/monokai";

function JsDependenciesEditor({code, setCode}) {
  return (
    <AceEditor
      style={{width: "100%"}}
      placeholder={localStrings.yourHtmlCodeHere}
      mode="html"
      theme="monokai"
      onChange={(code) => {
        setCode(code)
      }}
      fontSize={14}
      showPrintMargin={true}
      showGutter={true}
      highlightActiveLine={true}
      value={code}
      setOptions={{
        enableBasicAutocompletion: false,
        enableLiveAutocompletion: false,
        enableSnippets: false,
        showLineNumbers: true,
        tabSize: 2,
      }}/>
  );
}

export default JsDependenciesEditor;
