import { useCallback } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import CreditCard01Icon from '@untitled-ui/icons-react/build/esm/CreditCard01';
import Settings04Icon from '@untitled-ui/icons-react/build/esm/Settings04';
import User03Icon from '@untitled-ui/icons-react/build/esm/User03';
import {
  Box,
  Button,
  Divider,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  SvgIcon,
  Typography
} from '@mui/material';
import { RouterLink } from 'src/components/router-link';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useRouter } from 'src/hooks/use-router';
import { paths } from 'src/paths';
import useAuth from "../../../hooks/useAuth";
import firebase from "../../../lib/firebase";
import localStrings from "../../../localStrings";
import {accountRoute, dashboardBaseRoute} from "../../../routes/dashboard";

export const AccountPopover = (props) => {
  const { anchorEl, onClose, open, ...other } = props;
  const router = useRouter();
  //const auth = useAuth();
  const user = useMockedUser();
  const { logout } = useAuth();

  const handleLogout = useCallback(async () => {
    try {
      onClose?.();
      logout();
      router.push(paths.index);
    } catch (err) {
      console.error(err);
      toast.error('Something went wrong!');
    }
  }, [router, onClose]);

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'center',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={!!open}
      PaperProps={{ sx: { width: 200 } }}
      {...other}>
      <Box sx={{ p: 2 }}>
        {/*<p>{JSON.stringify(firebase?.currentUser)}</p>*/}
        <Typography variant="body1">
          {firebase?.currentUser?.displayName}
        </Typography>
        <Typography
          color="text.secondary"
          variant="body2"
        >
          {firebase?.currentUser?.email}
        </Typography>
      </Box>
      <Divider />
      <Box sx={{ p: 1 }}>
        {/*<ListItemButton*/}
        {/*  component={RouterLink}*/}
        {/*  href={paths.dashboard.social.profile}*/}
        {/*  onClick={onClose}*/}
        {/*  sx={{*/}
        {/*    borderRadius: 1,*/}
        {/*    px: 1,*/}
        {/*    py: 0.5*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <ListItemIcon>*/}
        {/*    <SvgIcon fontSize="small">*/}
        {/*      <User03Icon />*/}
        {/*    </SvgIcon>*/}
        {/*  </ListItemIcon>*/}
        {/*  <ListItemText*/}
        {/*    primary={(*/}
        {/*      <Typography variant="body1">*/}
        {/*        Profile*/}
        {/*      </Typography>*/}
        {/*    )}*/}
        {/*  />*/}
        {/*</ListItemButton>*/}
        <ListItemButton
          component={RouterLink}
          href={"/" + dashboardBaseRoute + "/" + accountRoute + "?tab=general"}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <Settings04Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                {localStrings.account}
              </Typography>
            )}
          />
        </ListItemButton>
        <ListItemButton
          component={RouterLink}
          href={"/" + dashboardBaseRoute + "/" + accountRoute + "?tab=paymentList"}
          onClick={onClose}
          sx={{
            borderRadius: 1,
            px: 1,
            py: 0.5
          }}
        >
          <ListItemIcon>
            <SvgIcon fontSize="small">
              <CreditCard01Icon />
            </SvgIcon>
          </ListItemIcon>
          <ListItemText
            primary={(
              <Typography variant="body1">
                {localStrings.billing}
              </Typography>
            )}
          />
        </ListItemButton>
      </Box>
      <Divider sx={{ my: '0 !important' }} />
      <Box
        sx={{
          display: 'flex',
          p: 1,
          justifyContent: 'center'
        }}
      >
        <Button
          color="inherit"
          onClick={handleLogout}
          size="small"
        >
          {localStrings.logout}
        </Button>
      </Box>
    </Popover>
  );
};

AccountPopover.propTypes = {
  anchorEl: PropTypes.any,
  onClose: PropTypes.func,
  open: PropTypes.bool
};
