import { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { FileDropzone } from 'src/components/file-dropzone';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import localStrings from "../../../localStrings";
import config from "../../../conf/config.json";
import {getDownloadURL, getStorage, ref, uploadBytes} from "../../../lib/firebase";
import enqueueSnackbarWithSound from "../../../utils/SnackBarWithSound";
import toast from "react-hot-toast";
import useAuth from "../../../hooks/useAuth";
import {getStartedAddMedia, setGetStartedDone} from "../../../pages/GettingStarted";


export async function uploadFiles(files, brandId, setUploadFileOpen, setFileUploaded, fileUploaded, sendPlayConfetti) {
  const resUrl = [];
  const path = brandId + "/" + config.remoteFiles.mediasFolder;
  const storage = getStorage();
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    const refFile = ref(storage, path + "/" + file.name);
    await uploadBytes(refFile, file).then((snapshot) => {
      console.log('Uploaded a blob or file!');
    });
    const url = await getDownloadURL(refFile);
    resUrl.push(url)
    enqueueSnackbarWithSound(toast, null, false,
      localStrings.formatString(localStrings.notif.fileUploaded, file.name))
    if (sendPlayConfetti) {
      setGetStartedDone(getStartedAddMedia, sendPlayConfetti)
    }
}
  if (setFileUploaded) {
    setUploadFileOpen(false)
  }
  if (setFileUploaded) {
    setFileUploaded(!fileUploaded)
  }



  return resUrl;



  // onUpload(files)
  // onClose();
}

export const FileUploader = (props) => {
  const { onClose, open = false,  onUpload, brandId } = props;
  const {setUploadFileOpen, uploadFileOpen, setFileUploaded, fileUploaded, sendPlayConfetti} = useAuth();
  const [files, setFiles] = useState([]);

  useEffect(() => {
    setFiles([]);
  }, [uploadFileOpen]);

  const handleDrop = useCallback((newFiles) => {
    let newFilesToAdd = [];

    for (let i = 0; i < newFiles.length; i++) {
      const newFile = newFiles[i];
      if (newFile.size > 10485760)
      {
        toast.error(localStrings.formatString(localStrings.warningMessage.fileTooLarge, newFile.name));
        continue;
      }
      newFilesToAdd.push(newFile)
      //console.log(newFile);
    }

    setFiles((prevFiles) => {
      return [...prevFiles, ...newFilesToAdd];
    });
  }, []);

  const handleRemove = useCallback((file) => {
    setFiles((prevFiles) => {
      return prevFiles.filter((_file) => _file.path !== file.path);
    });
  }, []);

  const handleRemoveAll = useCallback(() => {
    setFiles([]);
  }, []);

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={uploadFileOpen}
      onClose={() => setUploadFileOpen(false)}
    >

      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography variant="h6">
          {localStrings.uploadFiles}
        </Typography>
        <IconButton
          color="inherit"
          onClick={() => setUploadFileOpen(false)}
        >
          <SvgIcon>
            <XIcon />
          </SvgIcon>
        </IconButton>
      </Stack>
      <DialogContent>
        <FileDropzone
          accept={
          {
            'image/png': ['.png'],
            'image/jpg': ['.jpg', '.jpeg'],
            'video/mp4': ['.mp4']
          }
          }
          caption="Max file size is 10 MB"
          files={files}
          maxFilesize={1000}
          onDrop={handleDrop}
          onRemove={handleRemove}
          onRemoveAll={handleRemoveAll}
          onUpload={() => uploadFiles(files, brandId, setUploadFileOpen, setFileUploaded, fileUploaded, sendPlayConfetti)}
        />
      </DialogContent>
    </Dialog>
  );
};

FileUploader.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  brandId: PropTypes.string
};
