export const saveUriSetting = (key, value) => {
  let currentUriEncoded = encodeURI(window.location);
  let settingUri = localStorage.getItem(currentUriEncoded);
  if (!settingUri) {
    let settingObj = {};
    settingObj[key] = value;
    localStorage.setItem(currentUriEncoded, JSON.stringify(settingObj))
  }
  else {
    let settingObj = JSON.parse(settingUri);
    settingObj = {
      ...settingObj,
    }
    settingObj[key] = value;
    localStorage.setItem(currentUriEncoded, JSON.stringify(settingObj))
  }
}

export const getUriSetting = (key) => {
  let currentUriEncoded = encodeURI(window.location);
  let settingUri = localStorage.getItem(currentUriEncoded);
  let setting = localStorage.getItem(currentUriEncoded);
  if (!setting) {
    return null;
  }
  let settingObj = JSON.parse(settingUri);
  return settingObj[key];
}

export const getPageSetting = (page, key) => {
  //let settingUri = localStorage.getItem(currentUriEncoded);
  let setting = localStorage.getItem(page);
  if (!setting) {
    return null;
  }
  let settingObj = JSON.parse(setting);
  return settingObj[key];
}

export const savePageSetting = (page, key, value) => {
  let setting = localStorage.getItem(page);
  if (!setting) {
    let settingObj = {};
    settingObj[key] = value;
    localStorage.setItem(page, JSON.stringify(settingObj))
  }
  else {
    let settingObj = JSON.parse(setting);
    settingObj = {
      ...settingObj,
    }
    settingObj[key] = value;
    localStorage.setItem(page, JSON.stringify(settingObj))
  }
}

export const SORT_FIELD = "sortField";
export const NB_ITEMS = "nbItems";
export const SELECTED_TAB = "selectedTab";
export const EXPLAIN_SHOW = "explainShow";
export const SEARCH_QUERY = "searchQuery";

export const PRODUCT_PAGE_KEY = "productPage";
export const DEAL_PAGE_KEY = "dealPage";
