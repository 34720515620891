import React from 'react';
import {Button, Card, Stack, SvgIcon, Typography, Box, Container} from "@mui/material";
import Upload01Icon from "@untitled-ui/icons-react/build/esm/Upload01";
import Download01Icon from "@untitled-ui/icons-react/build/esm/Download01";
import PlusIcon from "@untitled-ui/icons-react/build/esm/Plus";
import localStrings from "../../localStrings";
import {Seo} from "../seo";

function PageWithTitle({title, mainButtons, actionButtons, children}) {
  return (

    // <Box
    //   component="main"
    //   sx={{
    //     flexGrow: 1,
    //     py: 8
    //   }}
    // >
    <>
      <Seo title={title} />

      <Container maxWidth="xl">
        <Stack spacing={4}>
          <Stack
            direction="row"
            justifyContent="space-between"
            spacing={4}
          >
            <Stack spacing={1}>
              <Typography variant="h4">
                {title}
              </Typography>
              {actionButtons &&
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={1}
                >
                  {/*<Button*/}
                  {/*  color="inherit"*/}
                  {/*  size="small"*/}
                  {/*  startIcon={(*/}
                  {/*    <SvgIcon>*/}
                  {/*      <Upload01Icon/>*/}
                  {/*    </SvgIcon>*/}
                  {/*  )}*/}
                  {/*>*/}
                  {/*  Import*/}
                  {/*</Button>*/}
                  {/*<Button*/}
                  {/*  color="inherit"*/}
                  {/*  size="small"*/}
                  {/*  startIcon={(*/}
                  {/*    <SvgIcon>*/}
                  {/*      <Download01Icon/>*/}
                  {/*    </SvgIcon>*/}
                  {/*  )}*/}
                  {/*>*/}
                  {/*  Export*/}
                  {/*</Button>*/}
                  {actionButtons}
                </Stack>
              }
            </Stack>
            <Stack
              alignItems="center"
              direction="row"
              spacing={3}
            >
              {mainButtons}
            </Stack>
          </Stack>
          {/*<Card>*/}
          {children}
          {/*</Card>*/}
        </Stack>
        <Stack height={100}/>
      </Container>
    </>
    // </Box>
  );
}

export default PageWithTitle;
