import React from 'react';
import AceCodeEditor from "../../../components/editor/AceCodeEditor";

function HtmlEditor({code, setCode}) {
  return (
    <AceCodeEditor
      code={code}
      setCode={setCode}
      mode="html"
      readOnly
    />
  );
}

export default HtmlEditor;
