import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  name
  cssDefinition
  changePageDelay
  displayMessageDelay
  enableDisplayMessage
  enableDisplayEventPopup
  enableHeader
  titleHeader
  urlLogo
  maxItemPerColumn
  boardColumns {
    key,
    display,
    uuid,
  }
`

export const getAllBoardsQuery = (brandId) => {
  var debug = `
    query {
      getBoards(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getBoards(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}


export const getBoardQuery = (brandId, boardId) => {
  var debug = `
    query {
      getBoard(brandId: "${brandId}", boardId: "${boardId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getBoard(brandId: "${brandId}", boardId: "${boardId}") {
        ${common}
      }
    }
  `;
}

export const bulkDeleteBoardMutation = (brandId, ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkDeleteBoard(brandId: "${brandId}", boardIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkDeleteBoard(brandId: "${brandId}", boardIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const createBoardMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate", "createdAt"],
      [] );

  var debug = `
    mutation {
      addBoard(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      addBoard(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const updateBoardMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"],
      [] );

  var debug = `
    mutation {
      updateBoard(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateBoard(brandId: "${brandId}", data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const deleteBoardCache = async (brandId, ids) => {
  const query = getAllBoardsQuery(brandId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getBoards.filter(item =>
      !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getBoards: [...newData]
      },
    });
  }
}

export const addDataBoardToCache = async (brandId, datas) => {
  if (!datas) {
    return
  }
  const query = getAllBoardsQuery(brandId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getBoards: [...data.data.getBoards, ...datas]
      },
    });
  }
}

