import React, { Component } from 'react';
import localStrings from '../localStrings';
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import DialogContentText from "@mui/material/DialogContentText";
import Button from "@mui/material/Button";
import ClipLoaderComponent from "./ClipLoaderComponent";
import useWindowDimensions from "../hooks/useWindowDimensions";
import Box from "@mui/material/Box";
import {makeStyles} from "@mui/styles";
import {useWindowSize} from '@react-hook/window-size'

const DialogConfirm = ({dialogOpen, handleClose, deleteDialogText, handleConfirm,
                         argsConfirm, title, children, minWidth, disableConfirm, processOnGoing, cancelText}) => {

  const [width, height] = useWindowSize()

  const useStyles = makeStyles(() => ({
    paper: { minWidth: Math.min(minWidth, width) },
  }));

  const classes = useStyles();

  return (
    <div>
      <Dialog
        classes={minWidth ? { paper: classes.paper } : null}
        open={dialogOpen}
        onClose={handleClose}
        maxWidth="lg"
        minWidth={minWidth}
        //style={{ paper: { minWidth: minWidth }}}
      >
        <DialogTitle>{title || ""}</DialogTitle>
        <DialogContent>
          {processOnGoing ?
            <Box width={minWidth || null}>
              <ClipLoaderComponent />
            </Box>

            :
            <>
              {deleteDialogText ?
                <DialogContentText>
                  {deleteDialogText}
                </DialogContentText>
                :
                <div>
                  {children}
                </div>
              }
            </>
          }

        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            {cancelText || localStrings.cancel}
          </Button>
          <Button
            disabled={disableConfirm}
            onClick={() => handleConfirm(argsConfirm)} color="primary">
            {localStrings.confirm}
          </Button>
        </DialogActions>
      </Dialog>


    </div>
  );
}

export default DialogConfirm;
