import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
    id
    allDay
    start
    end
    repeat
    itemId
    itemType
    monday
    tuesday
    thursday
    wenesday
    friday
    saturday
    sunday
    title
    noEndRepeat
    endRepeatDate
`


export const getScheduleItemQuery = (brandId, scheduleId, scheduleItemId) => {
  var debug = `
    query {
      getScheduleItem(brandId: "${brandId}", scheduleId: "${scheduleId}", scheduleItemId: "${scheduleItemId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getScheduleItem(brandId: "${brandId}", scheduleId: "${scheduleId}", scheduleItemId: "${scheduleItemId}") {
        ${common}
      }
    }
  `;
}


export const getAllScheduleItemsByBrandIdQuery = (brandId, scheduleId) => {
  var debug = `
    query {
      getScheduleItemsByBrandId(brandId: "${brandId}", scheduleId: "${scheduleId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getScheduleItemsByBrandId(brandId: "${brandId}", scheduleId: "${scheduleId}") {
        ${common}
      }
    }
  `;
}

export const updateScheduleItemMutation = (brandId, scheduleId, data) => {
  if (data.initialId) {
    data.id = data.initialId;
    delete data.initialId;
  }
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"], ["repeat"]);

  var debug = `
    mutation {
      updateScheduleItem(brandId: "${brandId}", scheduleId: "${scheduleId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateScheduleItem(brandId: "${brandId}", scheduleId: "${scheduleId}", data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const createScheduleItemMutation = (brandId, scheduleId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate", "createdAt"],
    ["repeat"] );

  var debug = `
    mutation {
      addScheduleItem(brandId: "${brandId}", scheduleId: "${scheduleId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      addScheduleItem(brandId: "${brandId}", scheduleId: "${scheduleId}", data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const bulkDeleteScheduleItemMutation = (brandId, scheduleId, ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkDeleteScheduleItem(brandId: "${brandId}", scheduleId: "${scheduleId}", scheduleItemsIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkDeleteScheduleItem(brandId: "${brandId}", scheduleId: "${scheduleId}", scheduleItemsIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const deleteScheduleItemByBrandIdCache = async (brandId, scheduleId, ids) => {
  const query = getAllScheduleItemsByBrandIdQuery(brandId, scheduleId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getScheduleItemsByBrandId.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getScheduleItemsByBrandId: [...newData]
      },
    });
  }
}



export const addDataScheduleItemByBrandIdToCache = async (brandId, scheduleId, datas) => {
  const query = getAllScheduleItemsByBrandIdQuery(brandId, scheduleId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getScheduleItemsByBrandId: [...data.data.getScheduleItemsByBrandId, ...datas]
      },
    });
  }
}

