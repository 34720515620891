import PropTypes from 'prop-types';
import Menu01Icon from '@untitled-ui/icons-react/build/esm/Menu01';
import {Avatar, Box, Chip, IconButton, Stack, SvgIcon, useMediaQuery} from '@mui/material';
import {alpha} from '@mui/material/styles';
import {AccountButton} from '../account-button';
import {SearchButton} from '../search-button';
import SwitchBrand from "../../../components/SwitchBrand";
import useAuth from "../../../hooks/useAuth"
import {useNavigate} from "react-router";
import {getFirestore, doc} from "../../../lib/firebase";
import {BRAND_COLLECTION} from "../../../utils/constants";
import {useDocumentData} from "react-firebase-hooks/firestore";
import localStrings from "../../../localStrings";
import SwitchLanguage from '../../../components/SwitchLanguage';
const TOP_NAV_HEIGHT = 64;
const SIDE_NAV_WIDTH = 280;

export const TopNav = (props) => {
  const { onMobileNavOpen, ...other } = props;
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));
  const {superAdmin, userInDb, currentBrand} = useAuth();
  const navigate = useNavigate();
  const db = getFirestore();
  const refBrand = doc(db, BRAND_COLLECTION, currentBrand()?.id || "-1");
  const [brandDb, brandDbLoading, brandDbError] = useDocumentData(refBrand, {
    snapshotListenOptions: { includeMetadataChanges: true },
  });

  function getCredit() {
    if (brandDb?.credit == undefined) {
      return "";
    }
    return parseFloat(brandDb.credit) + " " + localStrings.credits;
  }

  return (
    <Box
      component="header"
      sx={{
        backdropFilter: 'blur(6px)',
        backgroundColor: (theme) => alpha(theme.palette.background.default, 0.8),
        position: 'sticky',
        left: {
          lg: `${SIDE_NAV_WIDTH}px`
        },
        top: 0,
        width: {
          lg: `calc(100% - ${SIDE_NAV_WIDTH}px)`
        },
        zIndex: (theme) => theme.zIndex.appBar
      }}
      {...other}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          minHeight: TOP_NAV_HEIGHT,
          px: 2
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >
          {!lgUp && (
            <IconButton onClick={onMobileNavOpen}>
              <SvgIcon>
                <Menu01Icon />
              </SvgIcon>
            </IconButton>
          )}
          {/*<SearchButton />*/}
        </Stack>
        <Stack
          alignItems="center"
          direction="row"
          spacing={2}
        >

          {(superAdmin || (userInDb && userInDb.allowedBrandIds != null && userInDb.allowedBrandIds.length > 0 &&
              !(userInDb?.allowedBrandIds.length == 1 && userInDb?.allowedBrandIds[0] == userInDb.brandId)
            )) &&
            <SwitchBrand />
          }
          <SwitchLanguage/>
          {(currentBrand() && currentBrand().useChargeOnline) &&
            <Chip
              onClick={() => navigate('/dashboard/account', {replace: true})}
              avatar={<Avatar>¤</Avatar>}
              label={getCredit()}
              variant="outlined"/>
          }

          <AccountButton />
        </Stack>
      </Stack>
    </Box>
  );
};

TopNav.propTypes = {
  onMobileNavOpen: PropTypes.func
};
