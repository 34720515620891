import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  email
  brandId
  
`

export const getTempBrandUsersQuery = (brandId) => {
  var debug = `
    query {
      getTempBrandUsers(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
   query {
      getTempBrandUsers(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}


export const getTempBrandUserByEmailQuery = (email) => {
  var debug = `
    query {
      getTempBrandUserByEmail(email: "${email}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
   query {
      getTempBrandUserByEmail(email: "${email}") {
        ${common}
      }
    }
  `;
}




export const createTempBrandUserMutation = (data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"], ["status", "role"]);

  var debug = `
    mutation {
      addTempBrandUser(data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`
  mutation {
    addTempBrandUser(data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const bulkDeleteTempBrandUserMutation = (ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkTempBrandUser(tempBrandUserIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkTempBrandUser(tempBrandUserIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const deleteTempBrandUserCache = async (brandId, ids) => {
  const query = getTempBrandUsersQuery(brandId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getTempBrandUsers.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getTempBrandUsers: [...newData]
      },
    });
  }
}

export const addDataTempBrandUserToCache = async (brandId, datas) => {
  const query = getTempBrandUsersQuery(brandId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getTempBrandUsers: [...data.data.getTempBrandUsers, ...datas]
      },
    });
  }
}

