import React, {useEffect, useState}  from 'react';
import {Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid} from "@mui/material";
import {makeStyles} from "@mui/styles";
import CardItemSequence from "./cardItemSequence";
import localStrings from "../localStrings";
import {TextField} from "@material-ui/core";
import {ItemListCard} from "./itemListCard";
import ItemSelectorDialog from "../components/itemSelector/ItemSelectorDialog";
import useAuth from "../hooks/useAuth";
import {getLayouts} from "../pages/Layouts/LayoutListView/Results";
import {getLayoutDisplayUrl, geWidgetDisplayUrl} from "../pages/Layouts/LayoutDetailsView/Editor";
import {loadImages} from "../pages/MediaListView/MedialList";
import {getWidgets} from "../pages/Widgets/WidgetListView/Results";
import {executeQueryUtil} from "./gqlUtil";
import {getAllBoardsQuery} from "../gql/boardGql";
import ReactPlayer from "react-player/lazy";

export const sequenceYouTubeType = 'youTube';
export const sequenceWebPageType = 'webPage';
export const sequenceLayoutType = 'layout';
export const sequenceBoardType = 'board';
export const sequenceWidgetType = 'widget';
export const sequenceMediaType = 'media';
export const sequenceGoogleDriveType = 'googleDrive';
export const sequenceGoogleDrivePresentationType = 'googleDrivePres';

function ItemSequenceSelector({sequenceAddCallBack}) {
  const [selectUrlOpen, setSelectUrlOpen] = React.useState(false);
  const [selectUrlTitle, setSelectUrlTitle] = React.useState("");
  const [sequenceType, setSequenceType] = React.useState(null);
  const [videoLink, setVideoLink] = React.useState(null);
  const [sequenceQuestion, setSequenceQuestion] = React.useState("");
  const [urlSelected, setUrlSelected] = React.useState("");
  const [layoutSelectorOpen, setLayoutSelectorOpen] = React.useState(false);
  const [widgetSelectorOpen, setWidgetSelectorOpen] = React.useState(false);
  const [boardSelectorOpen, setBoardSelectorOpen] = React.useState(false);

  const [mediaSelectorOpen, setMediaSelectorOpen] = React.useState(false);
  const [layouts, setLayouts] = useState([]);
  const [boards, setBoards] = useState([]);
  const [widgets, setWidgets] = useState([]);
  const [medias, setMedias] = useState([]);
  const {currentBrand, setUploadFileOpen, fileUploaded} = useAuth();

  // useEffect(()=>{
  //   setMediaSelectorOpen(true)
  // }, [fileUploaded])

  useEffect(() => {
    async function loadLayouts() {
      let result = await getLayouts(currentBrand);
      setLayouts(result.data?.getLayoutsByBrandId);
    }

    async function loadBoards() {
      let result = await executeQueryUtil(getAllBoardsQuery(currentBrand().id));
      setBoards(result.data?.getBoards);
    }

    async function loadWidgets() {
      let result = await getWidgets(currentBrand);
      setWidgets(result.data?.getWidgetsByBrandId);
    }

    async function loadMedias() {
      let images = await loadImages(currentBrand);
      setMedias(images);
    }



    loadWidgets();
    loadBoards();
    loadLayouts();
    loadMedias();
  }, [fileUploaded]);

  function youTubeSelect() {
    setVideoLink(null)
    setSelectUrlOpen(true);
    setSelectUrlTitle(localStrings.itemType.youTube)
    setSequenceQuestion(localStrings.sequencesInput.youTubeUrl);
    setSequenceType(sequenceYouTubeType);
  }

  function webPageSelect() {
    setVideoLink(null)
    setSelectUrlOpen(true);
    setSelectUrlTitle(localStrings.itemType.webPage)
    setSequenceQuestion(localStrings.sequencesInput.webPageUrl);
    setSequenceType(sequenceWebPageType);
  }

  function googleDocSelect() {
    setVideoLink("/static/videos/shareGoogle.mp4")
    setSelectUrlOpen(true);
    setSelectUrlTitle(localStrings.itemType.googleDriveDocument)
    setSequenceQuestion(localStrings.sequencesInput.googleDocUrl);
    setSequenceType(sequenceGoogleDriveType);

  }

  function googleDocPresSelect() {
    setVideoLink("/static/videos/video2.mp4")
    setSelectUrlOpen(true);
    setSelectUrlTitle(localStrings.itemType.googleSlidesDriveDocument)
    setSequenceQuestion(localStrings.sequencesInput.googleSlideDocUrl);
    setSequenceType(sequenceGoogleDrivePresentationType);

  }

  function selectWidget(id) {
    setVideoLink(null)
    const selectedWidget = widgets.find(layout => layout.id === id);
    sequenceAddCallBack({
      sequenceType: sequenceWidgetType,
      selectedWidget: {
        ...selectedWidget,
        displayUrl: geWidgetDisplayUrl(currentBrand, selectedWidget.id)
      }
    })
  }

  function selectLayout(id) {
    setVideoLink(null)
    const selectedLayout = layouts.find(layout => layout.id === id);
    sequenceAddCallBack({
      sequenceType: sequenceLayoutType,
      selectedLayout: {
        ...selectedLayout,
        displayUrl: getLayoutDisplayUrl(currentBrand, selectedLayout.id)
      }
    })
  }

  function selectBoard(id) {
    setVideoLink(null)
    const selectedBoard = boards.find(layout => layout.id === id);
    sequenceAddCallBack({
      sequenceType: sequenceBoardType,
      selectedLayout: {
        ...selectedBoard,
      }
    })
  }

  function selectMedia(id) {
    setVideoLink(null)
    const selectedMedia = medias.find(layout => layout.id === id);
    sequenceAddCallBack({
      sequenceType: sequenceMediaType,
      selectedMedia: selectedMedia,
    })
  }

  function buildLayoutItems() {
    return (layouts || []).map(layout => {
      return {
        id: layout.id,
        image: '/static/images/sequences/layout-2-svgrepo-com.svg',
        name: layout.name
      }
    });
  }

  function buildBoardItems() {
    return (boards || []).map(board => {
      return {
        id: board.id,
        image: '/static/images/sequences/board-svgrepo-com.svg',
        name: board.name
      }
    });
  }

  function buildWidgetsItems() {
    return (widgets || []).map(widget => {
      return {
        id: widget.id,
        image: '/static/images/sequences/widget-alt-svgrepo-com.svg',
        name: widget.name
      }
    });
  }

  function buildMediaItems() {
    return (medias || []).map(media => {
      return {
        id: media.id,
        image: media.url,
        name: media.fileName
      }
    });
  }

  return (
    <div style={{width: '100%'}}>
      <Dialog open={selectUrlOpen} onClose={() => setSelectUrlOpen(false)}>
        <DialogTitle>{selectUrlTitle}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {sequenceQuestion || ""}
            {videoLink &&
              <ReactPlayer
                loop={true}
                playing={true}
                muted={true}
                width={'531px'}
                url={videoLink} />
            }

          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label={localStrings.urlAddress}
            type="email"
            fullWidth
            variant="standard"
            value={urlSelected}
            onChange={(e) => setUrlSelected(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSelectUrlOpen(false)}>{localStrings.cancel}</Button>
          <Button onClick={() => {
            sequenceAddCallBack({
              sequenceType: sequenceType,
              urlSelected: urlSelected
            })
            setSelectUrlOpen(false);
          }}>{localStrings.select}</Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          display: 'grid',
          gap: 3,
          gridTemplateColumns: 'repeat(3, 1fr)'
        }}
      >
        <ItemListCard
          onOpen={youTubeSelect}
          item={{
            name: localStrings.itemType.youTube,
            image: '/static/images/sequences/youtube-color-svgrepo-com.svg'
          }}
        />

        <ItemListCard
          onOpen={googleDocSelect}
          item={{
            name: localStrings.itemType.googleDriveDocument,
            image: '/static/images/sequences/google-drive-svgrepo-com.svg'
          }}
        />

        <ItemListCard
          onOpen={googleDocPresSelect}
          item={{
            name: localStrings.itemType.googleDriveSlideDocument,
            image: '/static/images/sequences/Google_Slides_logo.png'
          }}
        />

        <ItemListCard
          onOpen={() => setLayoutSelectorOpen(true)}
          item={{
            name: localStrings.layout,
            image: '/static/images/sequences/layout-2-svgrepo-com.svg'
          }}
        />


        <ItemListCard
          onOpen={() => setWidgetSelectorOpen(true)}
          item={{
            name: localStrings.widget,
            image: '/static/images/sequences/widget-alt-svgrepo-com.svg'
          }}
        />

        <ItemListCard
          onOpen={() => setMediaSelectorOpen(true)}
          item={{
            name: localStrings.media,
            image: '/static/images/sequences/images-svgrepo-com.svg'
          }}
        />

        <ItemListCard
          onOpen={() => setBoardSelectorOpen(true)}
          item={{
            name: localStrings.board,
            image: '/static/images/sequences/board-svgrepo-com.svg'
          }}
        />

        <ItemListCard
          onOpen={webPageSelect}
          item={{
            name: localStrings.webPage,
            image: '/static/images/sequences/webpage-svgrepo-com.svg'
          }}
        />


      </Box>

      <ItemSelectorDialog
        onClose={() => setLayoutSelectorOpen(false)}
        open={layoutSelectorOpen}
        items={buildLayoutItems()}
        selectCallBack={selectLayout}
        title={localStrings.selectSequenceLayout}
       />

      <ItemSelectorDialog
        onClose={() => setBoardSelectorOpen(false)}
        open={boardSelectorOpen}
        items={buildBoardItems()}
        selectCallBack={selectBoard}
        title={localStrings.selectSequenceBoard}
      />

      <ItemSelectorDialog
        onClose={() => setWidgetSelectorOpen(false)}
        open={widgetSelectorOpen}
        items={buildWidgetsItems()}
        selectCallBack={selectWidget}
        title={localStrings.selectSequenceWidget}
      />

      <ItemSelectorDialog
        onClose={() => setMediaSelectorOpen(false)}
        open={mediaSelectorOpen}
        items={buildMediaItems()}
        selectCallBack={selectMedia}
        title={localStrings.selectMedia}
      >
        <Button
          onClick={() => setUploadFileOpen(true)}
          color="primary"
          variant="contained"
          style={{marginLeft: "2px"}}
        >
          {localStrings.uploadMedias}
        </Button>
      </ItemSelectorDialog>
    </div>
  );
}

export default ItemSequenceSelector;
