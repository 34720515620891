import {lazy, Suspense} from 'react';
import {Outlet} from 'react-router-dom';
import {Layout as DashboardLayout} from 'src/layouts/dashboard';
import GetStarted from "../pages/GettingStarted";
import Canvas from '../pages/Canvas';

const MediaSequenceListView = lazy(() => import( "../pages/MediaSequences/MediaSequenceListView"));
const MediaSequenceAddView = lazy(() => import( "../pages/MediaSequences/MediaSequenceAddView"));
const MediaSequenceDetailsView = lazy(() => import( "../pages/MediaSequences/MediaSequenceDetailsView"));

const ScreenListView = lazy(() => import( "../pages/Screens/ScreenListView"));
const ScreenAddView = lazy(() => import( "../pages/Screens/ScreenAddView"));

const ScreenDetailsView = lazy(() => import( "../pages/Screens/ScreenDetailsView"));
const AccountCreateUpdateView = lazy(() => import( "../pages/Brands/AccountCreateUpdateView"));
const AccountAddView = lazy(() => import( "../pages/Brands/AccountAddView"));

const BoardListView = lazy(() => import( "../pages/Boards/BoardListView"));
const BoardAddView = lazy(() => import( "../pages/Boards/BoardAddView"));
const BoardDetailsView = lazy(() => import( "../pages/Boards/BoardDetailsView"));

const ScheduleListView = lazy(() => import( "../pages/Schedules/ScheduleListView"));
const ScheduleAddView = lazy(() => import( "../pages/Schedules/ScheduleAddView"));
const ScheduleDetailsView = lazy(() => import( "../pages/Schedules/ScheduleDetailsView"));

const LayoutListView = lazy(() => import( "../pages/Layouts/LayoutListView"));
const LayoutAddView = lazy(() => import( "../pages/Layouts/LayoutAddView"));
const LayoutDetailsView = lazy(() => import( "../pages/Layouts/LayoutDetailsView"));

const PaymentSucess = lazy(() => import( "../pages/PaymentSucess"));
const MediaListView = lazy(() => import( "../pages/MediaListView"));
const Overview = lazy(() => import('src/pages/Overview'));

const WidgetListView = lazy(() => import( "../pages/Widgets/WidgetListView"));
const WidgetAddView = lazy(() => import( "../pages/Widgets/WidgetAddView"));
const WidgetDetailsView = lazy(() => import( "../pages/Widgets/WidgetDetailsView"));
// Other

export const mediaSequencesRoute = 'mediaSequences';
export const mediaSequenceAddRoute = 'mediaSequences/add';
export const mediaSequenceDetailRoute = 'mediaSequences/detail/:mediaSequenceId';

export const screensRoute = 'screens';
export const screenAddRoute = 'screens/add';
export const screenDetailRoute = 'screens/detail/:screenId';

export const boardsRoute = 'boards';
export const boardAddRoute = 'boards/add';
export const boardDetailRoute = 'boards/detail/:boardId';

export const schedulesRoute = 'schedules';
export const scheduleAddRoute = 'schedules/add';
export const scheduleDetailRoute = 'schedules/detail/:scheduleId';

export const widgetsRoute = 'widgets';
export const widgetAddRoute = 'widgets/add';
export const widgetDetailRoute = 'widgets/detail/:widgetId';

export const layoutsRoute = 'layouts';
export const layoutAddRoute = 'layouts/add';
export const layoutDetailRoute = 'layouts/detail/:layoutId';

export const accountRoute = 'account';
export const accountAddRoute = 'account/add';

export const paymentSuccessRoute = 'paymentSuccess';

export const medialListRoute = 'medialList';

export const getStartedRoute = 'getStarted';
export const getCanvasRoute = 'canvas';

export const dashboardBaseRoute = 'dashboard';

export const dashboardRoutes = [
  {
    path: dashboardBaseRoute,
    element: (
      <DashboardLayout>
        <Suspense>
          <Outlet />
        </Suspense>
      </DashboardLayout>
    ),
    children: [
      {
        index: true,
        element: <Overview />
      },
      {
        path: "/" + dashboardBaseRoute + "/" +accountRoute,
        element: <AccountCreateUpdateView />
      },
      {
        path: "/" + dashboardBaseRoute + "/" + accountAddRoute,
        element: <AccountAddView />
      },

      { path: screensRoute, element: <ScreenListView/> },
      { path: screenAddRoute, element: <ScreenAddView/> },
      { path: screenDetailRoute, element: <ScreenDetailsView/> },

      { path: boardsRoute, element: <BoardListView/> },
      { path: boardAddRoute, element: <BoardAddView/> },
      { path: boardDetailRoute, element: <BoardDetailsView/> },

      { path: mediaSequencesRoute, element: <MediaSequenceListView/> },
      { path: mediaSequenceAddRoute, element: <MediaSequenceAddView/> },
      { path: mediaSequenceDetailRoute, element: <MediaSequenceDetailsView/> },

      { path: schedulesRoute, element: <ScheduleListView/> },
      { path: scheduleAddRoute, element: <ScheduleAddView/> },
      { path: scheduleDetailRoute, element: <ScheduleDetailsView/> },

      { path: widgetsRoute, element: <WidgetListView/> },
      { path: widgetAddRoute, element: <WidgetAddView/> },
      { path: widgetDetailRoute, element: <WidgetDetailsView/> },

      { path: layoutsRoute, element: <LayoutListView/> },
      { path: layoutAddRoute, element: <LayoutAddView/> },
      { path: layoutDetailRoute, element: <LayoutDetailsView/> },

      { path: paymentSuccessRoute, element: <PaymentSucess/> },

      { path: medialListRoute, element: <MediaListView/> },
      { path: getStartedRoute, element: <GetStarted/> },

      // { path: getCanvasRoute, element: <Canvas/> },
    ]
  }
];
