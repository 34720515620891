import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  name
`


export const getScheduleQuery = (brandId, scheduleId) => {
  var debug = `
    query {
      getSchedule(brandId: "${brandId}", scheduleId: "${scheduleId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getSchedule(brandId: "${brandId}", scheduleId: "${scheduleId}") {
        ${common}
      }
    }
  `;
}


export const getAllSchedulesByBrandIdQuery = (brandId) => {
  var debug = `
    query {
      getSchedulesByBrandId(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getSchedulesByBrandId(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}

export const updateScheduleMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"]);

  var debug = `
    mutation {
      updateSchedule(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateSchedule(brandId: "${brandId}", data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const createScheduleMutation = (brandId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate", "createdAt"],
      [] );

  var debug = `
    mutation {
      addSchedule(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
      addSchedule(brandId: "${brandId}", data: ${dataString}) {
        ${common}
      }
  }
  `;
}

export const bulkDeleteScheduleMutation = (brandId, ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkDeleteSchedule(brandId: "${brandId}", schedulesIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkDeleteSchedule(brandId: "${brandId}", schedulesIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const deleteScheduleByBrandIdCache = async (brandId, ids) => {
  const query = getAllSchedulesByBrandIdQuery(brandId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getSchedulesByBrandId.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getSchedulesByBrandId: [...newData]
      },
    });
  }
}



export const addDataScheduleByBrandIdToCache = async (brandId,datas) => {
  const query = getAllSchedulesByBrandIdQuery(brandId);
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getSchedulesByBrandId: [...data.data.getSchedulesByBrandId, ...datas]
      },
    });
  }
}

