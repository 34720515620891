import LocalizedStrings from 'react-localization';
import React from 'react';

let localStrings = new LocalizedStrings({
  en:
    {
      canvas: "Canvas",
      totalFilePartExplain: "{0} x 100Mo of files",
      chargeFile: "Files",
      chargeableSizeFiles: "You have reached the total max amount of 100mo, files are chargeable when total size is greater 100Mo",
      mute: "Mute",
      scanThisQRCOde: "Scan this QR code to use the app to connect and control your screen",
      googleSlide: "GoogleSlide",
      termsOfService: "Terms of service",
      privacyPolicy: "Privacy policy",
      view: "View",
      maxItemPerColumn: "Maximum item numbers per column",
      createLayoutNow: "Create layout now",
      addMediaNow: "Add media now",
      addMediaSequenceNow: "Add media now",
      addWidgetNow: "Add widget now",
      connectScreenNow: "Connect your screen now",
      addScheduleNow: "Add schedule now",
      online: "Online",
      offline: "Offline",
      noContentScreenDefined: "No content associated to your screen",
      yourCode: "Your cast reactor screen code: {0}",
      editEntry: "Edit entry",
      addEntry: "Add entry",
      repeatTillEndOfTime: "Repeat untill end of time",
      endRepeatDate: "End repeat date",
      allDay: "All day",
      mondayInitial: "Mo",
      tuesdayInitial: "Tu",
      wenesdayInitial: "We",
      thursdayInitial: "Th",
      fridayInitial: "Fr",
      saturdayInitial: "Sa",
      sundayInitial: "Su",


      clickToUpload: "Click to upload",
      orDnd: "or drag and drop",
      repeatOnce: "Once",
      repeatDaily: "Daily",
      repeatWeekly: "Weekly",
      repeatMonthly: "Monthly",
      repeatYearly: "Yearly",

      repeat: "Repeat",
      chooseMediaSequence: "Choose media sequence",
      newEntry: "New entry",
      month: "Month",
      week: "Week",
      day: "Day",
      agenda: "Agenda",

      calendar: "Calendar",
      selectTemplate: "Select Template",
      blankLayout: "Blank layout",
      blankWidget: "Blank widget",

      design: "Design",
      json: "Json",
      html: "Html",

      pairScreen: "Pair Screen",
      pairingCode: 'Pairing code',
      hide: "Hide",
      display: "Display",
      viewMore: "View more",
      showExplanations: "Show explanations",
      hideExplanations: "Hide explanations",
      backUrl: "Back to {0}",
      noMediaList: "No media yet",
      emptyList: "Empty list",
      copyExt: "-copy",
      duplicate: "Duplicate",
      splitScreenHorizontally: "Split screen horizontally",
      splitScreenVertically: "Split screen vertically",

      splitScreenAdjust: "Adjust Split percentage",

      splitUndo: "Undo splitting",

      noName: "No name",
      changeBackgroundColor: "Change background color",
      htmlEditor: "Html editor",
      jsEditor: "JavaScript editor",
      cssEditor: "Css editor",
      jsDependencies: "Javascript dependencies",
      cssDependencies: "Css dependencies",

      seeAllLayouts: "See all layouts",
      seeAllWidgets: "See all widgets",
      seeAllSchedules: "See all schedules",
      seeAllScreens: "See all screens",
      seeAllMediaSequences: "See all media sequences",

      backgroundColor: "Background color",
      selectSequenceLayout: "Select sequence layout",
      selectSequenceBoard: "Select sequence board",
      selectSequenceWidget: "Select sequence widget",
      selectMedia: "Select media",
      overview: "Overview",
      billing: "Billing",
      send: "Send",
      updateLayoutData: "Update layout data",

      updateWidgetData: "Update widget data",

      curl: "Curl",
      nodeJs: "NodeJs",

      developper: "Developper",
      pendingConfirmationUser: "Pending confirmation",
      confirmedUser: "Confirmed",

      copyImgUrl: "Copy image url",
      mediasUpload: "Upload medias",
      mediasList: "Medias list",
      media: "Media",

      getStarded: "Get started",
      webPage: 'Web page',

      fullDurationOfMedia: "Full duration of media",
      perSlide: '(Per slide)',
      deleteSequenceToolTip: "Delete sequence",
      editSequenceToolTip: "Edit sequence",
      displayScreenLayout: "Display Screen layout",
      hideScreenLayout: "Hide Screen layout",

      editSequenceDetail: "Edit sequence detail",
      copyToClipboard: "Copy to clipboard",
      iUnderstand: "I Understand",
      managment: "Management",
      fullDurationVideo: "Full duration of the video",
      ok: "Ok",
      provideWebLink: "Provide web link",
      webLinkUrl: "Web link url",
      preview: "Preview",
      apply: "Apply",
      urlAddress: "Url address",
      unknownMedia: "Unknown media",
      urlWebLink: 'Google web link url',
      screenUrl: "Screen url",
      itemMediaSequence: '{0} (media sequence)',
      item: "Item",
      mediaSetting: "Media settings",
      duration: "Duration",
      hoursDuration: "Hours Duration",
      minutesDuration: "Minutes Duration",
      secondsDuration: "Seconds Duration",

      durationForEachSlide: "Duration for each slide (seconds)",
      reorderList: "Reorder list",
      backToNormalList: "Back to classic List",
      select: "Select",
      selectGoogleFile: "Select google file",
      selectItem: "Select item to add",
      addGoogleDocument: "Add Google Document",
      addItem: "Add item",
      addContent: "Add content",
      changeContent: "Change",
      editContent: "Edit",
      addNewSequence: "Add new sequence",
      sequences: "Sequences",
      googleDocId: "google document id",
      thumbnailImage: "thumbnail image",
      forgotPassword: "I forgot my password ?",
      passwordChanged: "Your password have been changed, go back to login and sign in with your new password",
      linkSent: "An email have been sent to {0} with the information to reset your password",
      validationEmailDone: "Your email have been validated",
      orLoginEmail: "Or login with email address",
      signInNow: "Sign In Now",
      resetPassword: "Reset password",
      sendResetPassword: "Send me a linnk to reset my password",
      visitWebSite: "Visit our web site",
      dontHaveAccount: "D'ont have account ?",
      signIn: "Sign In",
      haveAnAccount: "Have an account?",
      signUpNow: "Sign Up Now",
      password: "Password",
      passwordConfirmation: "Password confirmation",
      newPassword: "New password",
      newPasswordConfirmation: "New password confirmation",
      useEmailTocreateNewAccount: "Use your email to create a new account",
      createNewAccount: "Create a new account",
      backToLogin: "Back to login",
      loginWithGoogle: "Login with Google",
      allowUserToAccess: "Allow user to access to my account",
      email: "e mail",
      roles: "Roles",
      pendingUser: "Pending user",
      pendingUsers: "Pending users",
      usersInBrand: "User in brand",
      pending: "Pending",
      existing: "Existing",
      allowOtherUsers: "Allow other users",
      brandUser: "Brand users",
      inviteUser: "Invite other user",
      youHaveBeenInvitedGoogle: "You have been invited to connect with google (account: {0})",
      youHaveBeenInvitedNoGoogle: "You have been invited to connect (account: {0})",
      noValue: "No value",
      stripeLocationId: "Stripe location",
      stripeTerminalId: "Stripe terminal",
      name: "Name",
      brandId: "Brand Id",
      titleHeaderBoard: "Board header title",
      urlLogoBoard: "Logo image url",
      enableHeaderBoard: "Enable header in the board",
      enableDisplayMessage: "Enable message display at the bottom of the board",
      enableDisplayEventPopup: "Enable popup display when events ar created or modified",
      displayMessageDelay: "Time in milliseconds for each character to display info messages",
      changePageDelay: "Time in milliseconds to change page when events not fit in a single page",
      yourCodeHere: "Your code here",
      yourJsonDataHere: "Your json data here",
      yourCssCodeHere: "Your css code here",
      yourHtmlCodeHere: "Your html code here",
      yourJsCodeHere: "Your javascript code here",
      screenSize: "screen size (width, height)",
      browseWsLogs: "Browse to ws logs",
      browseCommandLogs: "Browse to command logs",
      wsLogUrl: "Google shared url for ws logs",
      commandLogUrl: "Google shared url for command logs",
      notFound: "Not Found",
      sendingCommand: "Sending Command to screen",
      noCredit: "No Credit",
      noCreditMessageOne: "You are running out of credits, please buy some credits to continue using Cast reactor",
      noCreditMessageTwo: "You are running out of credits, you can continue to use the plateform but your sreens won't work, please buy some credits to continue using Cast reactor",
      buyCredit: "Buy credits",
      notFoundMessageOne: "404: The page you are looking for isn’t here",
      notFoundMessageTwo: "You either tried some shady route or you came here by mistake.\n" +
        "Whichever it is, try using the navigation",
      loggingProjectId: "Google id project for logging",
      issueWithCode: "Issue wit your code !!",
      noIssueWithCode: "No Issue wit your code !!",
      mouseSystemDevPath: "Mouse system dev path",
      fullScreen: "Edit in full screen",
      exitFullScreen: "exit full screen",
      checkMyCode: "Check my code",
      blockWhenInsufficientCredit: "Block the screens when no sufficient credits",
      useChargeOnline: "Use Charge online",
      minimalCreditToLock: "Mininum credit amount before locking screen",
      adminSection: "admin Section",
      switchBrand: "Switch company",
      searchBrand: "Search company by name",

      detail: "Detail",
      credits: "Credits",
      chargeHistory: "Charge history",
      amount: "Amount",
      totalAmount: "Total amount",
      creationDate: "Creation date",
      date: "Date",
      paymentHistory: "Payment history",
      contactEmail: "Contact email",
      paymentSuccess: "Payment processed successfully",
      paymentDone: "Payment done",
      goToPayment: "Go to payment",
      selectAmount: "Select amount to pay",
      payAmount: "Pay {0} €",
      back: "Back",
      backToAmountSelection: "Back to amount selection",
      amountEuros: "Amount in euros",
      amountCredit: "Amount in kiosk credit",
      pay: "Pay",
      chargeMyAccountWithPrice: "Charge my account (1 Kiosk credit={0}€)",
      chargeMyAccount: "Charge my account",
      payment: "Payment",
      status: "Status",
      refresh: "Refresh",
      commandlogs: "Command logs",
      devicelogs: "Device logs",
      liteDevice: "lite device",
      wslogs: "Web socket logs",
      logs: "Logs",
      logout: "logout",
      download: "Download",
      uploadDeviceWebSite: "Upload device web site",
      files: "Files:",
      uploadFiles: "Upload files",
      uploadMedias: "Upload medias",
      dragAndDropZipFile: "Drag'n'drop single zip file containing you web site, or click to select file",
      useInactivityTouchScreen: "Use Inactivity event when touch screen is not used",
      durationInactivityTouchScreen: "Duration for inactivity time out (in seconds)",
      inactivityTouchScreenAction: "Action to do after inactivity",
      gotoHomePageAction: "Navigate To Home page",
      gotoPreviousPageAction: "Navigate To previous page",
      triggerInactivityWebhook: "Trigger inactivity event on my webhook",
      triggerCancelCameraWebhook: "Trigger cancel camera event on my webhook",
      sendEvent: "SendEvent",

      camera: "Camera",
      useCamera: "Use camera to take pictures",
      cameraCancelPictureAction: "Action to do when user cancel the camera picture page",

      cloudTextToSpeech: "Google cloud text to speech",
      cloudTextToSpeechVoiceName: "Cloud text To speech voice name",

      uploadLocalSile: "Upload project web site",

      stripePaymentTerminal: "Stripe payment terminal",
      stripePrivateKey: "Stripe private key",
      stripeTerminalPairCode: "Stripe terminal pair code",

      config: "Config",
      webhook: "webhook",
      dashboard: "Dashboard",
      general: "General",
      actions: "actions",
      commands: "Commands",
      events: "Events",
      editor: "Editor",

      error: "Error",
      add: "Add",
      edit: "Edit",
      delete: "Delete",
      cancel: "Cancel",
      save: "Save",
      search: "Search",
      confirm: "Confirm",
      submit: "Submit",

      update: "Update",
      create: "Create",
      brandDefinition: "Company definition",
      brand: "Company",
      brandName: "Company name",
      login: "Log in",
      emailVerification: "Email verification",

      authChange: "Auth change",
      account: "Account",
      addAccount: "Add account",

      authKey: "Authorization key",
      projectName: "Project name",
      boardName: "Board name",
      projects: "Projects",
      project: "Project",
      projectList: "Project list",
      createProject: "Create project",
      editProject: "Edit Project",
      management: "Management",
      backToProjectList: "Back to projects list",

      boards: "Boards",
      board: "Board",
      boardList: "Board list",
      createBoard: "Create board",
      editBoard: "Edit board",
      backToBoardList: "Back to boards list",
      boardUrl: "Board url",
      boardId: "Board id",

      stripePayAccounts: "Stripe accounts",
      stripePayAccountName: "Account name",
      stripePayAccount: "Stripe account",
      stripePayAccountList: "Stripe account list",
      createStripePayAccount: "Create stripe account",
      editStripePayAccount: "Edit stripe account",
      backToStripePayAccountList: "Back to Stripe accounts list",
      noStripePayAccountAssociated: "No stripe pay account associated",
      noStripeLocation: "No stripe location",
      noStripeTerminal: "No stripe terminal",

      columnsDefinition: "Columns definition",
      columnKey: "column key",
      columnDisplay: "text to display in the column",

      customCssStyle: "Custom css definition",

      createBrand: "Create brand",
      updateBrand: "Update brand",

      sendDisplayUrlToDevice: "Send url",
      sendSourceToPrinter: "Send to printer",
      sendSpeakToDevice: "Send speak text to device",

      addMediaSequence: "Add media sequence",
      mediaSequences: "Media sequences",
      mediaList: "Media list",
      mediaSequence: "Media sequence",
      editMediaSequence: "Edit media sequence",
      mediaSequenceList: "Media sequence list",
      backToMediaSequenceList: "Back to media sequence list",
      mediaSequenceId: 'Media sequence id',

      addSchedule: "Add schedule",
      schedules: "schedules",
      schedule: "schedule",
      editSchedule: "Edit schedule",
      scheduleList: "Schedule list",
      backToScheduleList: "Back to schedule list",

      addWidget: "Add widget",
      widgets: "Widgets",
      widget: "Widget",
      editWidget: "Edit widget",
      widgetList: "Widget list",
      backToWidgetList: "Back to widget list",

      addLayout: "Add layout",
      layouts: "Layouts",
      layout: "Layout",
      editLayout: "Edit layout",
      layoutList: "Layout list",
      backToLayoutList: "Back to layout list",

      deviceName: "Screen name",
      devices: "Screens",
      device: "Screen",
      editDevice: "Edit Screen",
      deviceList: "Screen list",
      addDevice: "Add screen",
      backToDeviceList: "Back to screen list",
      deviceId: "Screen Id",
      Id: "Id",
      noProjectAssociated: "No project associated",
      noItemAssociated: "No item associated",
      displayUrlOnDevice: "Send Url to device for chrome display",
      commandsToDevice: "Sends commands to device",
      simulateEventsOnDevice: "Simulate events on device",

      simulateQrCodeScan: "Simulate QR code scan",
      qrCoreValue: "QR code value",

      webHooksEvent: "Your webHook url for events coming from device",
      webHookEventUrl: "WebHook for events coming from device",

      settings: "Settings",
      homePageUrl: "Home page to display on the device",

      driveUrls: "Urls list to drive your device",
      changeChromeAddressUrl: "Url to use to change displayed page on device",
      printTicketUrl: "Url to use to print tickets on device",
      rebootUrl: "Url to reboot your device",
      speakUrl: "Url for speak",

      cameraUrl: "Device url to display the camera page",
      contentString: "Content",

      getStarted: {
        createLayout: "Create your first layout",
        addMedia: "create your first media",
        createMediaSequence: "create your first media sequence",
        createWidget: "create your first widget",
        connectScreen: "Connect your first screen",
        addSchedule: "create your first schedule",
      },

      content: {
        welcome: "Welcome to cast reactor",
        welcomeHeader: "Cast dynamics contents on your screens",
        welcomeSubHeader: "Build your digital signage using an online SAAS application and bring you google documents, video to your screens",
      },
      itemType: {
        youTube: "You Tube video",
        googleDriveDocument: "Google drive document",
        googleSlidesDriveDocument: "Google drive slides document",
        googleDriveSlideDocument: "Google drive slide",
        webPage: "Web page",
      },

      sequencesInput: {
        googleSlideDocUrl: "Use the publish on the web url from your google drive",
        googleDocUrl: "Use the shared url from your google drive",
        youTubeUrl: "Use the shared url from the you tube page",
        webPageUrl: "Web page url",
      },

      errorMessage: {
        presShouldHaveWebLink: "Web link not defined for presentation {0}",
        userAlreadyDefined: "This user already have an account",
        issueFileReading: "Issue with file reading",
        issueFileAbort: "File rading aborted",
        invalidJsonFile: "invalid json file",
        unableToFetchData: "Unable to fetch data",
        noSuchDevice: "No device found with this id, please use the Id displayed on your device",
        deviceAlreadyAssociated: "This device is already associated to your company"
      },
      check: {
        wrongHoursValue: 'Value must be between 0 and 24 Hours',
        wrongMinutesValue: 'Value must be between 0 and 60 Minutes',
        wrongSecondsValue: 'Value must be between 0 and 60 Seconds',
        wrongUrlValue: 'Invalid Url format',

        required: 'Required',
        fieldRequired: "The field is required",
        badEmailFormat: "The email have a wrong format",
        minuteFormat: "Bad format for time duration in minutes",
        msFormat: "Bad format for time duration in milliseconds",
        mustBeGreaterThan: "Must be greater than {0}",
        amountFormat: "The amount should be a decimal with maximum two digits after comma",
        minAmount: "The minimal amount is 50 Credits",
        passwordsMustMatch: "Passwords must match",
        passwordNotValid: 'Password must have Minimum eight characters, at least one letter, one number and one special character among: @$!%*#?&.+',

      },
      confirmMessage: {
        deleteWidget: "Are you sure you want to delete selected widget(s) ?",
        deleteStripePayAccount: "Are you sure you want to delete selected account(s) ?",
        deleteBoard: "Are you sure you want to delete selected board(s) ?",
        deleteProject: "Are you sure you want to delete selected project(s) ?",
        deleteMediaSequence: "Are you sure you want to delete selected media sequences ?",
        deleteItem: "Are you sure you want to delete selected item(s) ?",
        deleteSchedule: "Are you sure you want to delete selected layout(s) ?",
        deleteLayout: "Are you sure you want to delete selected layout(s) ?",
        removeDevice: "Are you sure you want to remove selected screen(s) ?",
        reboot: "Are you sure you want to reboot this device ?",
        update: "Are you sure you want to update and reboot this screen ?",
        deleteMedia: "Are you sure you want to delete this media ?",
        deleteUser: "Are you sure you want to remove this user ?"
      },
      notif : {
        userPendingCreated: "Invitation sent",
        userPendingDeleted: "Pending user deleted",

        userDeleted: "User deleted",
        userCreated: "User created",
        userUpdated: "User updated",

        boardDeleted: "Board deleted",
        boardUpdated: "Board updated",
        boardCreated: "Board created",

        stripePayAccountDeleted: "Account deleted",
        stripePayAccountUpdated: "Account updated",
        stripePayAccountCreated: "Account created",

        boardColumnDeleted: "Board column deleted",
        boardColumnUpdated: "Board column updated",
        boardColumnCreated: "Board column created",

        projectDeleted: "Project deleted",
        projectUpdated: "Project updated",
        projectCreated: "Project created",


        scheduleDeleted: "Schedule deleted",
        scheduleUpdated: "Schedule updated",
        scheduleCreated: "Schedule created",

        scheduleItemDeleted: "Entry deleted",
        scheduleItemUpdated: "Entry updated",
        scheduleItemCreated: "Entry created",

        widgetDeleted: "Widget deleted",
        widgetUpdated: "Widget updated",
        widgetCreated: "Widget created",

        brandUpdated: "Brand updated",
        brandCreated: "Brand created",

        deviceDeleted: "Screen deleted",
        deviceRemoved: "Screen removed",
        deviceUpdated: "Screen updated",
        deviceAdded: "Screen added",

        mediaSequenceDeleted: "Media sequence deleted",
        mediaSequenceRemoved: "Media sequence removed",
        mediaSequenceUpdated: "Media sequence updated",
        mediaSequenceAdded: "Media sequence added",



        layoutDeleted: "Layout deleted",
        layoutRemoved: "Layout removed",
        layoutUpdated: "Layout updated",
        layoutAdded: "Layout added",
        cssStyleUpdated: "CSS Style updated",

        rebootSent: "Reboot request sent !!",
        takePicture: "Take picture request sent !!",
        updateSent: "Update request sent !!",
        changeUrlSent: "Change Url request sent !!",
        speakSent: "Speak request sent !!",
        requestTicketSent: "Ticket request sent !!",

        siteUploaded: "Web site zip file uploaded",

        QrCodeScanSent: "Qr code scan sent !!",
        switchBrand: "Current company is now {0}",

        itemDeleted: "Item deleted",

        screenReloaded: "Screen reloaded",
        screensReloaded: "All selected screens reloaded",

        fileUploaded: "File {0} uploaded",
        fileDeleted: "File {0} deleted",

        layoutDataSent: "Layout data updated",
        widgetDataSent: "Widget data updated",

        screenPaired: "Screen successfully paired",
      },

      infoMessage: {
        addScreenCastReactorApp: "How to ad the screen in Cast reactor app",
      },

      warningMessage: {
        fileTooLarge: "File {0} is too large",
        fileNotShared: "Selected file is not shared in google drive !!",
        defineWebLinkUrl: "Please define your WebLinkUrl for your presentation",
        needSuperUser: "Need to be a super user",
        noPayerUser: "No payer user found",
        moreThanOnePayerUser: "More than one payer user found",

        invalidInvitationKey: "Invalid Invitation Key",
        message404: "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.",
        userAlreadyDefined: "This user is already defined in the system",
        atLeastoneEstabishmentShouldRemains: "At least one establishment should remains in the list",
        notAuthorizedPage: "Access to this page is not authorized",
        notInContextPage: "This page page is not accessible in this context",
        fileReadingAborted: "file reading was aborted",
        fileReadingFailed: "file reading has failed",
        fileSameName: "file with same name already exist",
        integrityError: "One or several references already exists on this object",
        noBrandCreated: "To finish subscription, please fill you company info",

        wrongUserCredentials: "Wrong credentials, please verify user and password",
        invalidActionCode: "Invalid action code",
        userAlreadyExists: "This user already exists",
        unknownUser: "Unknown user",
        pleaseValidateYourAccount: "Your account have not been verified, please check your mailbox and activate your account, then try to sign in again",
        NoTempScreenId: "Unable to find matching screen",
        ScreenAlreadyAssociated: "The code you did provide is already in use by another device",

        cannotCrossEntries: "Cannot set two entries at the same time",

        selectAtLeastOneDay: "Select at least one day",
        cannotCrossEntriesDetail: "Cannot set two entries an item is already scheduled for: {0}",

        widgetReferencedInMediaSequence: "This/those widgets are referenced in {0} media sequences, delete first the media sequence",
        layoutReferencedInMediaSequence: "This/those layouts are referenced in {0} media sequences, delete first the media sequence",
        mediaSequenceReferencedInSchedule: "This/those media sequences are referenced in {0} schedule, delete first the schedule",

        badGoogleDocLink: 'This link seems incorrect !!',
        invalidMimeType: 'Format is not supported',
      },

      message: {
        paymentDoneConfirm: "Thank for your payment, your account have been credited",
        // paymentNotPaid: "your payment of {0}€ is in incomplete status please contact our support",
      },

      roleList: {
        user: "User",
        admin: "Administrator",
      },

      materialTable: {
        actions: "actions",
        noRecords: "No records to display",
        filterRow: "Filter",
        add: "Add",
        edit: "Edit",
        delete: "Delete",
        cancel: "Cancel",
        save: "Save",
        search: "Search",
        labelRowsSelect: "Rows",
        labelRowsPerPage: "Rows per page:",
        firstAriaLabel: "First page",
        firstTooltip: "First page",
        previousAriaLabel: "Previous page",
        previousTooltip: "Previous page",
        nextAriaLabel: "Next page",
        nextTooltip: "Next page",
        lastAriaLabel: "Last Page",
        lastTooltip: "Last Page",
        deleteText: "Are you sure delete this row ?",
      },
      actionReloadScreen: "Reload screen",
      actionReloadSelectedScreen: "Reload selected screens",
      actionReloadScreenDesc: "Reload the content defined for this screen",


      actionReboot: "Reboot Screen",
      actionRebootDesc: "Reboot the remote Screen to take into account changes",
      actionTakePicture: "Take picture",
      actionTakePictureDesc: "Display camera web page to take pictures and manage it with your web hook",
      actionUpdateReboot: "Update and reboot Screen",
      actionUpdateRebootDesc: "Update the Screen with the latest version of the firmware current version:{0} latest version available:{1}",
      actionUpdateWebSite: "Update web site",
      actionUpdateWebSiteDesc: "Update the web site associated to the selected project",
      actionToHomeSiteDeviceWebSite: "Screen Web site",

      actionToHomeSiteDeviceWebSiteDesc: "Navigate to your home web site installed on your Screen",
      actionToHomePage: "Home page",
      actionToHomePageDesc: "Navigate to home page defined in the project",
    }
  ,
  fr:
    {
      totalFilePartExplain: "{0} x 100Mo de fichiers",
      chargeFile: "Fichier",
      chargeableSizeFiles: "Vous avez atteint la taille maximale de 100mo, les fichiers sont payants lorsque la taille totale est supérieure à 100Mo",
      mute: "Muet",
      scanThisQRCOde: "Scannez ce code QR pour utiliser l'application et connecter votre écran",
      googleSlide: "Google Slide",
      termsOfService: "Conditions d'utilisation",
      privacyPolicy: "Politique de confidentialité",
      view: "Voir",
      maxItemPerColumn: "Nombre maximum d'éléments par colonne",
      createLayoutNow: "Créer une mise en page maintenant",
      addMediaNow: "Ajouter des médias maintenant",
      addMediaSequenceNow: "Ajouter une séquence de médias maintenant",
      addWidgetNow: "Ajouter un widget maintenant",
      connectScreenNow: "Connectez votre écran maintenant",
      addScheduleNow: "Ajouter un planning maintenant",
      online: "En ligne",
      offline: "Hors ligne",
      noContentScreenDefined: "Aucun contenu associé à votre écran",
      yourCode: "Votre code d'écran Cast Reactor : {0}",
      editEntry: "Modifier l'entrée",
      addEntry: "Ajouter une entrée",
      repeatTillEndOfTime: "Répéter jusqu'à la fin des temps",
      endRepeatDate: "Date de fin de répétition",
      allDay: "Toute la journée",
      mondayInitial: "Lu",
      tuesdayInitial: "Ma",
      wenesdayInitial: "Me",
      thursdayInitial: "Je",
      fridayInitial: "Ve",
      saturdayInitial: "Sa",
      sundayInitial: "Di",
      clickToUpload: "Cliquez pour télécharger",
      orDnd: "ou glissez-déposez",
      repeatOnce: "Une fois",
      repeatDaily: "Quotidien",
      repeatWeekly: "Hebdomadaire",
      repeatMonthly: "Mensuel",
      repeatYearly: "Annuel",
      repeat: "Répéter",
      chooseMediaSequence: "Choisir la séquence de médias",
      newEntry: "Nouvelle entrée",
      month: "Mois",
      week: "Semaine",
      day: "Jour",
      agenda: "Agenda",
      calendar: "Calendrier",
      selectTemplate: "Sélectionner un modèle",
      blankLayout: "Mise en page vierge",
      blankWidget: "Widget vierge",
      design: "Conception",
      json: "Json",
      html: "Html",
      pairScreen: "Apparier l'écran",
      pairingCode: "Code d'appariement",
      hide: "Cacher",
      display: "Afficher",
      viewMore: "Voir plus",
      showExplanations: "Montrer les explications",
      hideExplanations: "Cacher les explications",
      backUrl: "Retour à {0}",
      noMediaList: "Pas encore de médias",
      emptyList: "Liste vide",
      copyExt: "-copie",
      duplicate: "Dupliquer",
      splitScreenHorizontally: "Diviser l'écran horizontalement",
      splitScreenVertically: "Diviser l'écran verticalement",
      splitScreenAdjust: "Ajuster le pourcentage de division",
      splitUndo: "Annuler la division",
      noName: "Sans nom",
      changeBackgroundColor: "Changer la couleur de fond",
      htmlEditor: "Éditeur HTML",
      jsEditor: "Éditeur JavaScript",
      cssEditor: "Éditeur CSS",
      jsDependencies: "Dépendances JavaScript",
      cssDependencies: "Dépendances CSS",
      seeAllLayouts: "Voir toutes les mises en page",
      seeAllWidgets: "Voir tous les widgets",
      seeAllSchedules: "Voir tous les plannings",
      seeAllScreens: "Voir tous les écrans",
      seeAllMediaSequences: "Voir toutes les séquences de médias",
      backgroundColor: "Couleur de fond",
      selectSequenceLayout: "Sélectionner la mise en page de la séquence",
      selectSequenceBoard: "Sélectionner le tableau de la séquence",
      selectSequenceWidget: "Sélectionner le widget de la séquence",
      selectMedia: "Sélectionner les médias",
      overview: "Aperçu",
      billing: "Facturation",
      send: "Envoyer",
      updateLayoutData: "Mettre à jour les données de la mise en page",
      updateWidgetData: "Mettre à jour les données du widget",
      curl: "Curl",
      nodeJs: "NodeJs",
      developper: "Développeur",
      pendingConfirmationUser: "En attente de confirmation",
      confirmedUser: "Confirmé",
      copyImgUrl: "Copier l'URL de l'image",
      mediasUpload: "Télécharger des médias",
      mediasList: "Liste des médias",
      media: "Médias",
      getStarded: "Commencer",
      webPage: "Page web",
      fullDurationOfMedia: "Durée totale du média",
      perSlide: "(Par diapositive)",
      deleteSequenceToolTip: "Supprimer la séquence",
      editSequenceToolTip: "Modifier la séquence",
      displayScreenLayout: "Afficher la mise en page de l'écran",
      hideScreenLayout: "Masquer la mise en page de l'écran",
      editSequenceDetail: "Modifier les détails de la séquence",
      copyToClipboard: "Copier dans le presse-papiers",
      iUnderstand: "Je comprends",
      managment: "Gestion",
      fullDurationVideo: "Durée totale de la vidéo",
      ok: "Ok",
      provideWebLink: "Fournir un lien web",
      webLinkUrl: "URL du lien web",
      preview: "Aperçu",
      apply: "Appliquer",
      urlAddress: "Adresse URL",
      unknownMedia: "Média inconnu",
      urlWebLink: 'URL du lien web Google',
      screenUrl: "URL de l'écran",
      itemMediaSequence: '{0} (séquence de médias)',
      item: "Élément",
      mediaSetting: "Paramètres des médias",
      duration: "Durée",
      hoursDuration: "Durée en heures",
      minutesDuration: "Durée en minutes",
      secondsDuration: "Durée en secondes",
      durationForEachSlide: "Durée de chaque diapositive (secondes)",
      reorderList: "Réorganiser la liste",
      backToNormalList: "Retour à la liste classique",
      select: "Sélectionner",
      selectGoogleFile: "Sélectionner un fichier Google",
      selectItem: "Sélectionner un élément à ajouter",
      addGoogleDocument: "Ajouter un document Google",
      addItem: "Ajouter un élément",
      addContent: "Ajouter du contenu",
      changeContent: "Changer",
      editContent: "Modifier",
      addNewSequence: "Ajouter une nouvelle séquence",
      sequences: "Séquences",
      googleDocId: "ID du document Google",
      thumbnailImage: "Image miniature",
      forgotPassword: "J'ai oublié mon mot de passe ?",
      passwordChanged: "Votre mot de passe a été changé, retournez à la connexion et connectez-vous avec votre nouveau mot de passe",
      linkSent: "Un e-mail a été envoyé à {0} avec les informations pour réinitialiser votre mot de passe",
      validationEmailDone: "Votre e-mail a été validé",
      orLoginEmail: "Ou connectez-vous avec une adresse e-mail",
      signInNow: "Connectez-vous maintenant",
      resetPassword: "Réinitialiser le mot de passe",
      sendResetPassword: "Envoyez-moi un lien pour réinitialiser mon mot de passe",
      visitWebSite: "Visitez notre site web",
      dontHaveAccount: "Vous n'avez pas de compte ?",
      signIn: "Se connecter",
      haveAnAccount: "Vous avez un compte ?",
      signUpNow: "Inscrivez-vous maintenant",
      password: "Mot de passe",
      passwordConfirmation: "Confirmation du mot de passe",
      newPassword: "Nouveau mot de passe",
      newPasswordConfirmation: "Confirmation du nouveau mot de passe",
      useEmailTocreateNewAccount: "Utilisez votre e-mail pour créer un nouveau compte",
      createNewAccount: "Créer un nouveau compte",
      backToLogin: "Retour à la connexion",
      loginWithGoogle: "Se connecter avec Google",
      allowUserToAccess: "Autoriser l'utilisateur à accéder à mon compte",
      email: "E-mail",
      roles: "Rôles",
      pendingUser: "Utilisateur en attente",
      pendingUsers: "Utilisateurs en attente",
      usersInBrand: "Utilisateurs dans l'entreprise",
      pending: "En attente",
      existing: "Existant",
      allowOtherUsers: "Autoriser d'autres utilisateurs",
      brandUser: "Utilisateurs de l'entreprise",
      inviteUser: "Inviter un autre utilisateur",
      youHaveBeenInvitedGoogle: "Vous avez été invité à vous connecter avec Google (compte : {0})",
      youHaveBeenInvitedNoGoogle: "Vous avez été invité à vous connecter (compte : {0})",
      noValue: "Aucune valeur",
      stripeLocationId: "Lieu Stripe",
      stripeTerminalId: "Terminal Stripe",
      name: "Nom",
      brandId: "ID de l'entreprise",
      titleHeaderBoard: "Titre de l'en-tête du tableau",
      urlLogoBoard: "URL de l'image du logo",
      enableHeaderBoard: "Activer l'en-tête dans le tableau",
      enableDisplayMessage: "Activer l'affichage du message en bas du tableau",
      enableDisplayEventPopup: "Activer l'affichage des popups lors de la création ou modification des événements",
      displayMessageDelay: "Temps en millisecondes pour afficher les messages d'info par caractère",
      changePageDelay: "Temps en millisecondes pour changer de page lorsque les événements ne tiennent pas sur une seule page",
      yourCodeHere: "Votre code ici",
      yourJsonDataHere: "Vos données JSON ici",
      yourCssCodeHere: "Votre code CSS ici",
      yourHtmlCodeHere: "Votre code HTML ici",
      yourJsCodeHere: "Votre code JavaScript ici",
      screenSize: "Taille de l'écran (largeur, hauteur)",
      browseWsLogs: "Parcourir les journaux ws",
      browseCommandLogs: "Parcourir les journaux de commandes",
      wsLogUrl: "URL partagée Google pour les journaux ws",
      commandLogUrl: "URL partagée Google pour les journaux de commandes",
      notFound: "Non trouvé",
      sendingCommand: "Envoi de la commande à l'écran",
      noCredit: "Aucun crédit",
      noCreditMessageOne: "Vous manquez de crédits, veuillez acheter des crédits pour continuer à utiliser Cast Reactor",
      noCreditMessageTwo: "Vous manquez de crédits, vous pouvez continuer à utiliser la plateforme mais vos écrans ne fonctionneront pas, veuillez acheter des crédits pour continuer à utiliser Cast Reactor",
      buyCredit: "Acheter des crédits",
      notFoundMessageOne: "404 : La page que vous cherchez n'est pas ici",
      notFoundMessageTwo: "Vous avez soit essayé une route douteuse soit vous êtes arrivé ici par erreur.\n" +
        "Quoi qu'il en soit, essayez d'utiliser la navigation",
      loggingProjectId: "ID du projet Google pour la journalisation",
      issueWithCode: "Problème avec votre code !!",
      noIssueWithCode: "Pas de problème avec votre code !!",
      mouseSystemDevPath: "Chemin de développement du système de souris",
      fullScreen: "Modifier en plein écran",
      exitFullScreen: "Quitter le plein écran",
      checkMyCode: "Vérifier mon code",
      blockWhenInsufficientCredit: "Bloquer les écrans en cas de crédits insuffisants",
      useChargeOnline: "Utiliser la charge en ligne",
      minimalCreditToLock: "Montant minimum de crédit avant de bloquer l'écran",
      adminSection: "Section admin",
      switchBrand: "Changer d'entreprise",
      searchBrand: "Rechercher une entreprise par nom",
      detail: "Détail",
      credits: "Crédits",
      chargeHistory: "Historique des charges",
      amount: "Montant",
      totalAmount: "Montant total",
      creationDate: "Date de création",
      date: "Date",
      paymentHistory: "Historique des paiements",
      contactEmail: "E-mail de contact",
      paymentSuccess: "Paiement traité avec succès",
      paymentDone: "Paiement effectué",
      goToPayment: "Aller au paiement",
      selectAmount: "Sélectionner le montant à payer",
      payAmount: "Payer {0} €",
      back: "Retour",
      backToAmountSelection: "Retour à la sélection du montant",
      amountEuros: "Montant en euros",
      amountCredit: "Montant en crédit kiosque",
      pay: "Payer",
      chargeMyAccountWithPrice: "Charger mon compte (1 crédit kiosque={0}€)",
      chargeMyAccount: "Charger mon compte",
      payment: "Paiement",
      status: "Statut",
      refresh: "Rafraîchir",
      commandlogs: "Journaux de commandes",
      devicelogs: "Journaux de dispositifs",
      liteDevice: "Appareil léger",
      wslogs: "Journaux de WebSocket",
      logs: "Journaux",
      logout: "Se déconnecter",
      download: "Télécharger",
      uploadDeviceWebSite: "Télécharger le site web de l'appareil",
      files: "Fichiers :",
      uploadFiles: "Télécharger des fichiers",
      uploadMedias: "Télécharger des médias",
      dragAndDropZipFile: "Glissez-déposez un fichier zip contenant votre site web, ou cliquez pour sélectionner un fichier",
      useInactivityTouchScreen: "Utiliser l'événement d'inactivité lorsque l'écran tactile n'est pas utilisé",
      durationInactivityTouchScreen: "Durée du délai d'inactivité (en secondes)",
      inactivityTouchScreenAction: "Action à effectuer après l'inactivité",
      gotoHomePageAction: "Naviguer vers la page d'accueil",
      gotoPreviousPageAction: "Naviguer vers la page précédente",
      triggerInactivityWebhook: "Déclencher un événement d'inactivité sur mon webhook",
      triggerCancelCameraWebhook: "Déclencher un événement d'annulation de la caméra sur mon webhook",
      sendEvent: "Envoyer un événement",
      camera: "Caméra",
      useCamera: "Utiliser la caméra pour prendre des photos",
      cameraCancelPictureAction: "Action à effectuer lorsque l'utilisateur annule la page de prise de photo de la caméra",
      cloudTextToSpeech: "Texte à parole Google Cloud",
      cloudTextToSpeechVoiceName: "Nom de la voix du texte à parole Cloud",
      uploadLocalSile: "Télécharger le site web du projet local",
      stripePaymentTerminal: "Terminal de paiement Stripe",
      stripePrivateKey: "Clé privée Stripe",
      stripeTerminalPairCode: "Code d'appariement du terminal Stripe",
      config: "Configuration",
      webhook: "Webhook",
      dashboard: "Tableau de bord",
      general: "Général",
      actions: "Actions",
      commands: "Commandes",
      events: "Événements",
      editor: "Éditeur",
      error: "Erreur",
      add: "Ajouter",
      edit: "Modifier",
      delete: "Supprimer",
      cancel: "Annuler",
      save: "Enregistrer",
      search: "Rechercher",
      confirm: "Confirmer",
      submit: "Soumettre",
      update: "Mettre à jour",
      create: "Créer",
      brandDefinition: "Définition de l'entreprise",
      brand: "Entreprise",
      brandName: "Nom de l'entreprise",
      login: "Connexion",
      emailVerification: "Vérification de l'e-mail",
      authChange: "Changement d'autorisation",
      account: "Compte",
      addAccount: "Ajouter un compte",
      authKey: "Clé d'autorisation",
      projectName: "Nom du projet",
      boardName: "Nom du tableau",
      projects: "Projets",
      project: "Projet",
      projectList: "Liste des projets",
      createProject: "Créer un projet",
      editProject: "Modifier le projet",
      management: "Gestion",
      backToProjectList: "Retour à la liste des projets",
      boards: "Tableaux",
      board: "Tableau",
      boardList: "Liste des tableaux",
      createBoard: "Créer un tableau",
      editBoard: "Modifier le tableau",
      backToBoardList: "Retour à la liste des tableaux",
      boardUrl: "URL du tableau",
      boardId: "ID du tableau",
      stripePayAccounts: "Comptes Stripe",
      stripePayAccountName: "Nom du compte",
      stripePayAccount: "Compte Stripe",
      stripePayAccountList: "Liste des comptes Stripe",
      createStripePayAccount: "Créer un compte Stripe",
      editStripePayAccount: "Modifier le compte Stripe",
      backToStripePayAccountList: "Retour à la liste des comptes Stripe",
      noStripePayAccountAssociated: "Aucun compte Stripe associé",
      noStripeLocation: "Aucun emplacement Stripe",
      noStripeTerminal: "Aucun terminal Stripe",
      columnsDefinition: "Définition des colonnes",
      columnKey: "Clé de colonne",
      columnDisplay: "Texte à afficher dans la colonne",
      customCssStyle: "Définition CSS personnalisée",
      createBrand: "Créer une entreprise",
      updateBrand: "Mettre à jour l'entreprise",
      sendDisplayUrlToDevice: "Envoyer l'URL",
      sendSourceToPrinter: "Envoyer à l'imprimante",
      sendSpeakToDevice: "Envoyer le texte à l'appareil",
      addMediaSequence: "Ajouter une séquence de médias",
      mediaSequences: "Séquences de médias",
      mediaList: "Liste des médias",
      mediaSequence: "Séquence de médias",
      editMediaSequence: "Modifier la séquence de médias",
      mediaSequenceList: "Liste des séquences de médias",
      backToMediaSequenceList: "Retour à la liste des séquences de médias",
      mediaSequenceId: "ID de la séquence de médias",
      addSchedule: "Ajouter un planning",
      schedules: "Plannings",
      schedule: "Planning",
      editSchedule: "Modifier le planning",
      scheduleList: "Liste des plannings",
      backToScheduleList: "Retour à la liste des plannings",
      addWidget: "Ajouter un widget",
      widgets: "Widgets",
      widget: "Widget",
      editWidget: "Modifier le widget",
      widgetList: "Liste des widgets",
      backToWidgetList: "Retour à la liste des widgets",
      addLayout: "Ajouter une mise en page",
      layouts: "Mises en page",
      layout: "Mise en page",
      editLayout: "Modifier la mise en page",
      layoutList: "Liste des mises en page",
      backToLayoutList: "Retour à la liste des mises en page",
      deviceName: "Nom de l'écran",
      devices: "Écrans",
      device: "Écran",
      editDevice: "Modifier l'écran",
      deviceList: "Liste des écrans",
      addDevice: "Ajouter un écran",
      backToDeviceList: "Retour à la liste des écrans",
      deviceId: "ID de l'écran",
      Id: "ID",
      noProjectAssociated: "Aucun projet associé",
      noItemAssociated: "Aucun élément associé",
      displayUrlOnDevice: "Envoyer l'URL à l'appareil pour l'affichage Chrome",
      commandsToDevice: "Envoyer des commandes à l'appareil",
      simulateEventsOnDevice: "Simuler des événements sur l'appareil",
      simulateQrCodeScan: "Simuler le scan du code QR",
      qrCoreValue: "Valeur du code QR",
      webHooksEvent: "Votre URL WebHook pour les événements venant de l'appareil",
      webHookEventUrl: "WebHook pour les événements venant de l'appareil",
      settings: "Paramètres",
      homePageUrl: "Page d'accueil à afficher sur l'appareil",
      driveUrls: "Liste des URLs pour piloter votre appareil",
      changeChromeAddressUrl: "URL à utiliser pour changer la page affichée sur l'appareil",
      printTicketUrl: "URL à utiliser pour imprimer des tickets sur l'appareil",
      rebootUrl: "URL pour redémarrer votre appareil",
      speakUrl: "URL pour parler",
      cameraUrl: "URL de l'appareil pour afficher la page de la caméra",
      contentString: "Contenu",
      getStarted:
        {
          createLayout: "Créez votre première mise en page",
          addMedia: "Créez votre premier média",
          createMediaSequence: "Créez votre première séquence de médias",
          createWidget: "Créez votre premier widget",
          connectScreen: "Connectez votre premier écran",
          addSchedule: "Créez votre premier planning",
        },
      content:
        {
          welcome: "Bienvenue sur Cast Reactor",
          welcomeHeader: "Diffusez du contenu dynamique sur vos écrans",
          welcomeSubHeader: "Construisez votre signalisation numérique en utilisant une application SAAS en ligne et diffusez vos documents Google, vidéos sur vos écrans",
        },
      itemType:
        {
          youTube: "Vidéo YouTube",
          googleDriveDocument: "Document Google Drive",
          googleSlidesDriveDocument: "Document Google Slides Drive",
          googleDriveSlideDocument: "Diapositive Google Drive",
          webPage: "Page web",
        },
      sequencesInput:
        {
          googleSlideDocUrl: "Utilisez l'URL publiée sur le web depuis votre Google Drive",
          googleDocUrl: "Utilisez l'URL partagée depuis votre Google Drive",
          youTubeUrl: "Utilisez l'URL partagée depuis la page YouTube",
          webPageUrl: "URL de la page web",
        },
      errorMessage:
        {
          presShouldHaveWebLink: "Lien web non défini pour la présentation {0}",
          userAlreadyDefined: "Cet utilisateur a déjà un compte",
          issueFileReading: "Problème de lecture de fichier",
          issueFileAbort: "Lecture du fichier avortée",
          invalidJsonFile: "Fichier JSON invalide",
          unableToFetchData: "Impossible de récupérer les données",
          noSuchDevice: "Aucun appareil trouvé avec cet ID, veuillez utiliser l'ID affiché sur votre appareil",
          deviceAlreadyAssociated: "Cet appareil est déjà associé à votre entreprise",
        },
      check:
        {
          wrongHoursValue: 'La valeur doit être comprise entre 0 et 24 heures',
          wrongMinutesValue: 'La valeur doit être comprise entre 0 et 60 minutes',
          wrongSecondsValue: 'La valeur doit être comprise entre 0 et 60 secondes',
          wrongUrlValue: 'Format d\'URL invalide',
          required: 'Requis',
          fieldRequired: "Le champ est requis",
          badEmailFormat: "L'e-mail a un format incorrect",
          minuteFormat: "Mauvais format pour la durée en minutes",
          msFormat: "Mauvais format pour la durée en millisecondes",
          mustBeGreaterThan: "Doit être supérieur à {0}",
          amountFormat: "Le montant doit être un nombre décimal avec au maximum deux chiffres après la virgule",
          minAmount: "Le montant minimal est de 50 crédits",
          passwordsMustMatch: "Les mots de passe doivent correspondre",
          passwordNotValid: 'Le mot de passe doit comporter au minimum huit caractères, au moins une lettre, un chiffre et un caractère spécial parmi: @$!%*#?&.+',
        },
      confirmMessage:
        {
          deleteWidget: "Êtes-vous sûr de vouloir supprimer le(s) widget(s) sélectionné(s) ?",
          deleteStripePayAccount: "Êtes-vous sûr de vouloir supprimer le(s) compte(s) sélectionné(s) ?",
          deleteBoard: "Êtes-vous sûr de vouloir supprimer le(s) tableau(x) sélectionné(s) ?",
          deleteProject: "Êtes-vous sûr de vouloir supprimer le(s) projet(s) sélectionné(s) ?",
          deleteMediaSequence: "Êtes-vous sûr de vouloir supprimer les séquences de médias sélectionnées ?",
          deleteItem: "Êtes-vous sûr de vouloir supprimer le(s) élément(s) sélectionné(s) ?",
          deleteSchedule: "Êtes-vous sûr de vouloir supprimer le(s) planning(s) sélectionné(s) ?",
          deleteLayout: "Êtes-vous sûr de vouloir supprimer la/les mise(s) en page sélectionnée(s) ?",
          removeDevice: "Êtes-vous sûr de vouloir supprimer le(s) écran(s) sélectionné(s) ?",
          reboot: "Êtes-vous sûr de vouloir redémarrer cet appareil ?",
          update: "Êtes-vous sûr de vouloir mettre à jour et redémarrer cet écran ?",
          deleteMedia: "Êtes-vous sûr de vouloir supprimer ce média ?",
          deleteUser: "Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
        },
      notif:
        {
          userPendingCreated: "Invitation envoyée",
          userPendingDeleted: "Utilisateur en attente supprimé",
          userDeleted: "Utilisateur supprimé",
          userCreated: "Utilisateur créé",
          userUpdated: "Utilisateur mis à jour",
          boardDeleted: "Tableau supprimé",
          boardUpdated: "Tableau mis à jour",
          boardCreated: "Tableau créé",
          stripePayAccountDeleted: "Compte supprimé",
          stripePayAccountUpdated: "Compte mis à jour",
          stripePayAccountCreated: "Compte créé",
          boardColumnDeleted: "Colonne du tableau supprimée",
          boardColumnUpdated: "Colonne du tableau mise à jour",
          boardColumnCreated: "Colonne du tableau créée",
          projectDeleted: "Projet supprimé",
          projectUpdated: "Projet mis à jour",
          projectCreated: "Projet créé",
          scheduleDeleted: "Planning supprimé",
          scheduleUpdated: "Planning mis à jour",
          scheduleCreated: "Planning créé",
          scheduleItemDeleted: "Entrée supprimée",
          scheduleItemUpdated: "Entrée mise à jour",
          scheduleItemCreated: "Entrée créée",
          widgetDeleted: "Widget supprimé",
          widgetUpdated: "Widget mis à jour",
          widgetCreated: "Widget créé",
          brandUpdated: "Entreprise mise à jour",
          brandCreated: "Entreprise créée",
          deviceDeleted: "Écran supprimé",
          deviceRemoved: "Écran retiré",
          deviceUpdated: "Écran mis à jour",
          deviceAdded: "Écran ajouté",
          mediaSequenceDeleted: "Séquence de médias supprimée",
          mediaSequenceRemoved: "Séquence de médias retirée",
          mediaSequenceUpdated: "Séquence de médias mise à jour",
          mediaSequenceAdded: "Séquence de médias ajoutée",
          layoutDeleted: "Mise en page supprimée",
          layoutRemoved: "Mise en page retirée",
          layoutUpdated: "Mise en page mise à jour",
          layoutAdded: "Mise en page ajoutée",
          cssStyleUpdated: "Style CSS mis à jour",
          rebootSent: "Demande de redémarrage envoyée !!",
          takePicture: "Demande de prise de photo envoyée !!",
          updateSent: "Demande de mise à jour envoyée !!",
          changeUrlSent: "Demande de changement d'URL envoyée !!",
          speakSent: "Demande de parole envoyée !!",
          requestTicketSent: "Demande de ticket envoyée !!",
          siteUploaded: "Fichier zip du site web téléchargé",
          QrCodeScanSent: "Scan de code QR envoyé !!",
          switchBrand: "L'entreprise actuelle est maintenant {0}",
          itemDeleted: "Élément supprimé",
          screenReloaded: "Écran rechargé",
          screensReloaded: "Tous les écrans sélectionnés rechargés",
          fileUploaded: "Fichier {0} téléchargé",
          fileDeleted: "Fichier {0} supprimé",
          layoutDataSent: "Données de mise en page mises à jour",
          widgetDataSent: "Données du widget mises à jour",
          screenPaired: "Écran appairé avec succès",
        },
      infoMessage:
        {
          addScreenCastReactorApp: "Comment ajouter l'écran dans l'application Cast Reactor",
        },
      warningMessage:
        {
          fileTooLarge: "Le fichier {0} est trop volumineux",
          fileNotShared: "Le fichier sélectionné n'est pas partagé dans Google Drive !!",
          defineWebLinkUrl: "Veuillez définir votre URL de lien web pour votre présentation",
          needSuperUser: "Nécessite d'être un super utilisateur",
          noPayerUser: "Aucun utilisateur payeur trouvé",
          moreThanOnePayerUser: "Plus d'un utilisateur payeur trouvé",
          invalidInvitationKey: "Clé d'invitation invalide",
          message404: "Vous avez soit essayé une route douteuse soit vous êtes arrivé ici par erreur. Quoi qu'il en soit, essayez d'utiliser la navigation.",
          userAlreadyDefined: "Cet utilisateur est déjà défini dans le système",
          atLeastoneEstabishmentShouldRemains: "Au moins un établissement doit rester dans la liste",
          notAuthorizedPage: "Accès non autorisé à cette page",
          notInContextPage: "Cette page n'est pas accessible dans ce contexte",
          fileReadingAborted: "La lecture du fichier a été interrompue",
          fileReadingFailed: "La lecture du fichier a échoué",
          fileSameName: "Un fichier portant le même nom existe déjà",
          integrityError: "Une ou plusieurs références existent déjà sur cet objet",
          noBrandCreated: "Pour terminer l'inscription, veuillez remplir les informations de votre entreprise",
          wrongUserCredentials: "Identifiants incorrects, veuillez vérifier l'utilisateur et le mot de passe",
          invalidActionCode: "Code d'action invalide",
          userAlreadyExists: "Cet utilisateur existe déjà",
          unknownUser: "Utilisateur inconnu",
          pleaseValidateYourAccount: "Votre compte n'a pas été vérifié, veuillez vérifier votre boîte mail et activer votre compte, puis essayez de vous connecter à nouveau",
          NoTempScreenId: "Impossible de trouver un écran correspondant",
          ScreenAlreadyAssociated: "Le code que vous avez fourni est déjà utilisé par un autre appareil",
          cannotCrossEntries: "Impossible de définir deux entrées en même temps",
          selectAtLeastOneDay: "Sélectionnez au moins un jour",
          cannotCrossEntriesDetail: "Impossible de définir deux entrées, un élément est déjà programmé pour : {0}",
          widgetReferencedInMediaSequence: "Ce(s) widget(s) sont référencés dans {0} séquences de médias, supprimez d'abord la séquence de médias",
          layoutReferencedInMediaSequence: "Ce(s) mise(s) en page sont référencées dans {0} séquences de médias, supprimez d'abord la séquence de médias",
          mediaSequenceReferencedInSchedule: "Cette(s) séquence(s) de médias sont référencées dans {0} planning(s), supprimez d'abord le planning",
          badGoogleDocLink: "Ce lien semble incorrect !!",
          invalidMimeType: "Format non pris en charge"
        },
      message:
        {
          paymentDoneConfirm: "Merci pour votre paiement, votre compte a été crédité",
        },
      roleList:
        {
          user: "Utilisateur",
          admin: "Administrateur"
        },
      materialTable:
        {
          actions: "Actions",
          noRecords: "Aucun enregistrement à afficher",
          filterRow: "Filtrer",
          add: "Ajouter",
          edit: "Modifier",
          delete: "Supprimer",
          cancel: "Annuler",
          save: "Enregistrer",
          search: "Rechercher",
          labelRowsSelect: "Lignes",
          labelRowsPerPage: "Lignes par page :",
          firstAriaLabel: "Première page",
          firstTooltip: "Première page",
          previousAriaLabel: "Page précédente",
          previousTooltip: "Page précédente",
          nextAriaLabel: "Page suivante",
          nextTooltip: "Page suivante",
          lastAriaLabel: "Dernière page",
          lastTooltip: "Dernière page",
          deleteText: "Êtes-vous sûr de vouloir supprimer cette ligne ?",
        },
      actionReloadScreen: "Recharger l'écran",
      actionReloadSelectedScreen: "Recharger les écrans sélectionnés",
      actionReloadScreenDesc: "Recharger le contenu défini pour cet écran",
      actionReboot: "Redémarrer l'écran",
      actionRebootDesc: "Redémarrer l'écran à distance pour prendre en compte les modifications",
      actionTakePicture: "Prendre une photo",
      actionTakePictureDesc: "Afficher la page web de la caméra pour prendre des photos et la gérer avec votre webhook",
      actionUpdateReboot: "Mettre à jour et redémarrer l'écran",
      actionUpdateRebootDesc: "Mettre à jour l'écran avec la dernière version du firmware version actuelle:{0} dernière version disponible:{1}",
      actionUpdateWebSite: "Mettre à jour le site web",
      actionUpdateWebSiteDesc: "Mettre à jour le site web associé au projet sélectionné",
      actionToHomeSiteDeviceWebSite: "Site web de l'écran",
      actionToHomeSiteDeviceWebSiteDesc: "Naviguer vers votre site web installé sur votre écran",
      actionToHomePage: "Page d'accueil",
      actionToHomePageDesc: "Naviguer vers la page d'accueil définie dans le projet",
    }
});

export default localStrings;
