import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  brandName
  contactEmail
  loggingProjectId
  credit
  authKey
  useChargeOnline
  totalFileSizeMb
  totalFilePart
`

export const getAllBrandsQuery = () => {
  var debug = `
    query {
      getBrands {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getBrands {
        ${common}
      }
    }
  `;
}


export const getBrandQuery = (brandId) => {
  var debug = `
    query {
      getBrand(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getBrand(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}

export const createBrandMutation = (data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"]);

  var debug = `
    mutation {
      addBrand(data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    addBrand(data: ${dataString}) {
        ${common}
      }
  }
  `;
}


export const updateHealthCheckMutation = (brandId) => {
  var debug = `
    mutation {
      updateHealthCheck(brandId: "${brandId}"){
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      updateHealthCheck(brandId: "${brandId}"){
        ids
      }
    }
  `;
}

export const updateBrandMutation = (data) => {
  delete data.updateDate;
  delete data.credit;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"]);

  var debug = `
    mutation {
      updateBrand(data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateBrand(data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const deleteBrandCache = async (ids) => {
  const query = getAllBrandsQuery();
  const res = await executeQueryUtil(query);
  const newData = res.data.getBrands.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getBrands: [...newData]
      },
    });
  }
}


export const addDataBrandToCache = async (datas) => {
  const query = getAllBrandsQuery();
  const data = await executeQueryUtil(query);
  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getBrands: [...data.data.getBrands, ...datas]
      },
    });
  }
}

export const addDataProductUsersToCache = async (datas) => {
  const query = getAllBrandsQuery();
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getBrands: [...data.data.getBrands, ...datas]
      },
    });
  }
}

