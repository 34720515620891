import {useRoutes} from 'react-router-dom';
import {Helmet} from 'react-helmet-async';
import {ThemeProvider} from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {RTL} from './components/rtl';
import {SettingsButton} from './components/settings/settings-button';
import {SettingsDrawer} from './components/settings/settings-drawer';
import {Toaster} from './components/toaster';
import {SettingsConsumer, SettingsProvider} from './contexts/settings-context';
import {AuthProvider} from "./contexts/FirebaseAuthContext";
import {gtmConfig} from './config';
import {useNprogress} from './hooks/use-nprogress';
import {useAnalytics} from './hooks/use-analytics';
import {routes} from './routes';
import {createTheme} from './theme';
// Remove if react-quill is not used
import 'react-quill/dist/quill.snow.css';
// Remove if react-draft-wysiwyg is not used
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
// Remove if simplebar is not used
import 'simplebar-react/dist/simplebar.min.css';
// Remove if mapbox is not used
import 'mapbox-gl/dist/mapbox-gl.css';
// Remove if locales are not used
import apolloClient from "../src/apolloClient/ApolloClient";
import APIErrorProvider from "./contexts/APPUtilProvider";
import {ApolloProvider} from "@apollo/client";
import APPUtilNotification from "../src/contexts/APPUtilProvider/APPUtilNotification";

export const App = () => {
  useAnalytics(gtmConfig);
  useNprogress();

  const element = useRoutes(routes);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <AuthProvider>
        <APIErrorProvider>
          <ApolloProvider client={apolloClient}>
            <SettingsProvider>
              <SettingsConsumer>
                {(settings) => {
                  // Prevent theme flicker when restoring custom settings from browser storage
                  if (!settings.isInitialized) {
                    // return null;
                  }

                  const theme = createTheme({
                    colorPreset: settings.colorPreset,
                    contrast: settings.contrast,
                    direction: settings.direction,
                    paletteMode: settings.paletteMode,
                    responsiveFontSizes: settings.responsiveFontSizes
                  });

                  // Prevent guards from redirecting
                  //const showSlashScreen = !auth.isInitialized;

                  return (
                    <ThemeProvider theme={theme}>
                      <Helmet>
                        <meta
                          name="color-scheme"
                          content={settings.paletteMode}
                        />
                        <meta
                          name="theme-color"
                          content={theme.palette.neutral[900]}
                        />
                      </Helmet>
                      <RTL direction={settings.direction}>
                        <CssBaseline />
                        <>
                          {element}
                          <SettingsButton onClick={settings.handleDrawerOpen} />
                          <SettingsDrawer
                            canReset={settings.isCustom}
                            onClose={settings.handleDrawerClose}
                            onReset={settings.handleReset}
                            onUpdate={settings.handleUpdate}
                            open={settings.openDrawer}
                            values={{
                              colorPreset: settings.colorPreset,
                              contrast: settings.contrast,
                              direction: settings.direction,
                              paletteMode: settings.paletteMode,
                              responsiveFontSizes: settings.responsiveFontSizes,
                              stretch: settings.stretch,
                              layout: settings.layout,
                              navColor: settings.navColor
                            }}
                          />
                        </>
                        <Toaster />
                        <APPUtilNotification/>
                      </RTL>
                    </ThemeProvider>
                  );
                }}
              </SettingsConsumer>
            </SettingsProvider>
          </ApolloProvider>
        </APIErrorProvider>
      </AuthProvider>
    </LocalizationProvider>
  );
};
