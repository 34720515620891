import {gql} from "@apollo/client";
import filterDataGql, { executeQueryUtil } from '../utils/gqlUtil';
import apolloClient from "../apolloClient/ApolloClient";

const common = `
  id
  email
  displayName
  brandId
  mainUser
  allowedBrandIds
  roles
`

export const getAllProductUsersQuery = () => {
  var debug = `
    query {
      getProductUsers {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getProductUsers {
        ${common}
      }
    }
  `;
}

export const getProductUsersByBrandIdQuery = (brandId) => {
  var debug = `
    query {
      getProductUsersByBrandId(brandId: "${brandId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getProductUsersByBrandId(brandId: "${brandId}") {
        ${common}
      }
    }
  `;
}


export const getProductUserQuery = (productUserId) => {
  var debug = `
    query {
      getProductUser(productUserId: "${productUserId}") {
        ${common}
      }
    }
  `
  console.log(debug);

  return gql`
    query {
      getProductUser(productUserId: "${productUserId}") {
        ${common}
      }
    }
  `;
}

export const createProductUserMutation = (userId, data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"], ["status"]);

  var debug = `
    mutation {
      addProductUser(userId: "${userId}", data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    addProductUser(userId: "${userId}", data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const updateProductUserMutation = (data) => {
  delete data.updateDate;
  var dataString = filterDataGql(data, ["createdAt", "creationDate", "updateDate"], ["status", "roles"]);

  var debug = `
    mutation {
      updateProductUser(data: ${dataString}) {
        ${common}
      }
    }
  `

  console.log(debug);

  return gql`mutation {
    updateProductUser(data: ${dataString}) {
        ${common}
    }
  }
  `;
}

export const bulkDeleteProductUserMutation = (ids) => {
  var idsString =  JSON.stringify(ids);

  var debug = `
    mutation {
      bulkDeleteProductUser(usersIds: ${idsString}) {
        ids
      }
    }
  `
  console.log(debug);

  return gql`
    mutation {
      bulkDeleteProductUser(usersIds: ${idsString}) {
        ids
      }
    }
  `;
}

export const deleteProductUserCache = async (brandId, ids) => {
  const query = getProductUsersByBrandIdQuery(brandId);
  const res = await executeQueryUtil(query);
  const newData = res.data.getProductUsersByBrandId.filter(item =>
    !ids.includes(item.id)
  )

  if (res) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getProductUsersByBrandId: [...newData]
      },
    });
  }
}

export const addDataProductUsersToCache = async (datas) => {
  const query = getAllProductUsersQuery();
  const data = await executeQueryUtil(query);

  if (data) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getProductUsers: [...data.data.getProductUsers, ...datas]
      },
    });
  }

  //const query = getProductUserQuery ()
}

export const addDataProductUserToCache = async (data) => {
  const query = getProductUserQuery(data.id);
  const dataq = await executeQueryUtil(query);

  if (dataq) {
    await apolloClient.writeQuery({
      query: query,
      data: {
        getProductUser: data
      },
    });
  }
}

