const getStartedAddMediaSequenceContent = `
<p>Create a sequence of components for a display on your screen</p>
<br/>

<strong>1. Click on "media list" tab</strong>
<br/>
<strong>2. Click on "Add new sequence" button</strong>
<br/>
<strong>3. Click on "Add content" button inside the tv</strong>
<br/>
<strong>3. Select a media</strong>
<strong>2. Click on "Add new sequence" button</strong>
<br/>
<strong>3. Click on "Add content" button inside the tv</strong>
<br/>
<strong>3. Select a widget</strong>
<br/>
<br/>
<p>You can click on preview button to see how the media sequence will be displayed on the screen</p>
<br/>
<p>You can click edit button to associate a name to any sequence and define the duration of each media item</p>
<br/>
`
module.exports = {
  getStartedAddMediaSequenceContent: getStartedAddMediaSequenceContent,
}
