import React, {useEffect, useState} from 'react';
import {Button, TableCell} from "@mui/material";
import localStrings from "../../../localStrings";
import useAuth from "../../../hooks/useAuth";
import {executeMutationUtil, executeQueryUtil} from "../../../utils/gqlUtil";
import ClipLoaderComponent from "../../../components/ClipLoaderComponent";
import GeneralResult from "../../../components/CrudFrom/GeneralResult";
import {useNavigate} from "react-router";
import {updateHealthCheckMutation} from "../../../gql/BrandGql";
import {BRAND_COLLECTION, DEVICE_HEALTH_COLLECTION} from "../../../utils/constants";
import {collection, getFirestore} from "firebase/firestore";
import {useCollectionData} from "react-firebase-hooks/firestore";
import moment from "moment";
import {
  bulkDeleteMediaSequenceMutation,
  deleteMediaSequenceByBrandIdCache, getAllMediaSequencesByBrandIdQuery,
} from "../../../gql/mediaSequenceGql";
import useAppUtil from "../../../hooks/useAppUtil";
import {getAllSchedulesByBrandIdQuery} from "../../../gql/scheduleGql";
import {getAllScheduleItemsByBrandIdQuery} from "../../../gql/scheduleItemGql";

export const getMediaSequences = async (currentBrand) => {
  return await executeQueryUtil(getAllMediaSequencesByBrandIdQuery(currentBrand().id));
}

const Results = ({className}) => {

  const {currentBrand} = useAuth();
  const db = getFirestore();
  const {addError} = useAppUtil();

  const refCollection =  collection(db, BRAND_COLLECTION
    + "/" + currentBrand().id + "/" + DEVICE_HEALTH_COLLECTION);

  const [devicesHealth, devicesHealthLoading, devicesHealthError] = useCollectionData(
    refCollection
    , {
      snapshotListenOptions: { includeMetadataChanges: true },
    });

  const [itemSource, setItemSource] = useState({});
  //const [projects, setProjects] = useState([]);
  //const navigate = useNavigate()
  useEffect(() => {
    async function setItemSourceAsync() {
      let result = await getMediaSequences(currentBrand);
      setItemSource(result);
    }
    setItemSourceAsync();
  }, [currentBrand]);


  const getTableCellColumn = () => {
    return(
      <React.Fragment>
        <TableCell>
          {localStrings.Id}
        </TableCell>
        <TableCell>
          {localStrings.name}
        </TableCell>
        <TableCell>
          {localStrings.actions}
        </TableCell>
      </React.Fragment>
    )
  }

  function getStatusColor(item) {
    var alive = item.alive
    const nowMs = moment().valueOf()
    //let deviceHealthMatching = devicesHealth && devicesHealth.find(h => h.id === item.id && nowMs - h.updateDate > 2000);
    let deviceHealthMatching = devicesHealth && devicesHealth.find(h => h.id === item.id);
    if (deviceHealthMatching) {
      alive = deviceHealthMatching.alive;
    }
    if (alive) {
      return 'green';
    }
    return 'red';
  }

  const getTableCell = (item) => {

    return(
      <React.Fragment>
        <TableCell>
          {item.id}
        </TableCell>
        <TableCell>
          {item.name}
        </TableCell>
      </React.Fragment>
    )
  }

  const deleteSequences = async (ids, size) => {
    const resSchedules = await executeQueryUtil(getAllSchedulesByBrandIdQuery(currentBrand().id))
    const schedules = resSchedules?.data?.getSchedulesByBrandId || [];
    let referencedSheduleIds = [];
    let scheduleNames = [];

    for (let i = 0; i < schedules.length; i++) {
      const schedule = schedules[i];
      const resScheduleItems = await executeQueryUtil(
        getAllScheduleItemsByBrandIdQuery(currentBrand().id, schedule.id))
      const scheduleItems = resScheduleItems?.data?.getScheduleItemsByBrandId;

      for (let j = 0; j < scheduleItems.length; j++) {
        const scheduleItem = scheduleItems[j];
        if (ids.includes(scheduleItem.itemId)) {
          referencedSheduleIds.push(schedule.itemId)
          scheduleNames.push(schedule.name)
        }
      }
    }


    if (referencedSheduleIds.length > 0) {
      addError(localStrings
        .formatString(localStrings.warningMessage.mediaSequenceReferencedInSchedule, [...new Set(scheduleNames)].toString()));
      return;
    }

    await executeMutationUtil(bulkDeleteMediaSequenceMutation(currentBrand().id, ids));
    await deleteMediaSequenceByBrandIdCache(currentBrand().id, ids);
    let itemUpdate = await getMediaSequences(currentBrand);
    setItemSource(itemUpdate)
    return true;
  }


  const sortOptions = [
    {
      value: 'name|asc',
      label: localStrings.name
    },
  ];

  return (
    <>
      { Object.keys(itemSource).length > 0 ?
        // <h1>MEDIA SEQU></h1>
        <GeneralResult
          className={className}
          getPathToData={(result) => result.data.getMediaSequencesByBrandId}
          getTableCell={getTableCell}
          getTableCellColumn={getTableCellColumn}
          getEditLink={(item) => "/dashboard/mediaSequences/detail/" + item.id}
          deleteDialogText={localStrings.confirmMessage.deleteMediaSequence}
          deleteItemsFunction={deleteSequences}
          deleteItemNotifMessage={localStrings.notif.mediaSequenceDeleted}
          sortOptions={sortOptions}
          searchProperties={['name']}
          itemsSource={itemSource}
        >

        </GeneralResult>
        :
        <ClipLoaderComponent/>
      }
    </>
  )

};


export default Results;
